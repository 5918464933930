import AjaxComp from "../../data/AjaxComp";

import "./details-createur.css";
class DetailsCreateur extends AjaxComp {

  default_conditions = {active:true}
  attrs_query = ["id", "designation","nbr_produits","nb_favori_clients",'notes_avis{note,avis,created_at,client{id,nom_complet,email}}', "categories{id,designation,produits{id,designation,nom_langue,image,prix,pourcentage_promo,stock_global,is_nouveaute}}", "photo","couverture"];
  
  constructor(props) {
    super(props);
    this.state = { ...props };
    //console.log('crea... ',this.state)
  }
  componentDidMount() {
    this.setState({ mounted: true });
  }
  
  render() {
    const site_path      =  document.location.origin+"/"+document.location.pathname.split('/')[1] 
    //const photo_createur = ((this.state.createur.photo) && this.state.createur.photo.match('upload.jpg')?(this.state.createur.photo.replace(this.apiUri,'').replace('//','/')):this.state.createur.photo).replace(site_path,"").replace(site_path+"/","")
    // const photo_createur = this.state.createur.photo;
    // console.log('photo_createur',photo_createur)
    return (
      this.state && this.state.createur ?
      <div
        className={`details-createur ${
          this.props.className ? this.props.className : ""
        }`}
      >
        <img loading="lazy"
          src={(this.state.createur.photo)??"/assets/pictures/logo_createur.png"}
          alt=""
          className="logo-createur" 
        />
        <div className="infos">
          <div className="title">{this.state.createur.designation}</div>
          <div className="stats">
            <div className="stat">
              <a href={this.suffixe+"/home-createur/"+this.state.createur.id}>{this.state.createur.nbr_produits} {this.app.getCurrentLangText("site_text_produits")} </a>
            </div>
            {
            this.state.createur.nb_favori_clients ? 
                <div className="like_number">
                <img loading="lazy"
                  src="/assets/pictures/heart-black.svg"
                  alt=""
                  className="icon"
                />
                <span>{this.state.createur.nb_favori_clients}</span>
              </div> : ''
            }
            
          </div>
          <p className="slogant">
            {this.state.createur.texte ? this.state.createur.texte : ""}
          </p>
        </div>
      </div> : ''
    );
  }
}
export default DetailsCreateur;
