import AjaxComp from "../data/AjaxComp";
import LabelZone from "../layout/extra/labelZone";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';


iziToast.settings({
  transitionIn: 'flipInX',
  transitionOut: 'flipOutX',
  onOpening: function(){
      console.log('callback abriu!');
  },
  onClosing: function(){
      console.log("callback fechou!");
  }
});

class LoginForm extends AjaxComp {
  credentials = {
    email: null,
    password: null,
  };
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.state.showPassword = false;
    this.state.display_error = null;


    this.state = {
      display_error: null,
      display_message: null,
      displayingError: false,
      displayingMessage: false,
  };
  }
  openFacebookPopup() {
    var width = 600; // Popup window width
    var height = 400; // Popup window height
    var left = window.innerWidth / 2 - width / 2;
    var top = window.innerHeight / 2 - height / 2;
    document.location.href = this.apiUri + "/login/facebook";
  }
  openApplePopup() {
    var width = 600; // Popup window width
    var height = 400; // Popup window height
    var left = window.innerWidth / 2 - width / 2;
    var top = window.innerHeight / 2 - height / 2;
    document.location.href = this.apiUri + "/login/apple";
  }
  openGooglePopup() {
    var width = 600; // Popup window width
    var height = 400; // Popup window height
    var left = window.innerWidth / 2 - width / 2;
    var top = window.innerHeight / 2 - height / 2;
    document.location.href = this.apiUri + "/login/google";
  }
  // showDisplayError(title = "Erreur") {
  //   let erreur = this.state.display_error;
  //   let retour = this.toaster.create(erreur, "error", title, null, () => {
  //     this.setState({ display_error: null });
  //   });
  //   return retour;
  // }

  showDisplayError = (title = "Erreur", disappear = null) => {
    if (!this.state.displayingError) {
        const erreur = this.state.display_error;
        this.setState({ displayingError: true });
        iziToast.error({
            title: title,
            message: erreur,
            position: 'topRight',
            timeout: disappear ||  5000,
            close: false,
            onClosing: () => {
                this.setState({ display_error: null, displayingError: false });
            }
        });
    }
  }

  showDisplayMessage = (title = "Success", disappear = null) => {
    if (!this.state.displayingMessage) {
        const message = this.state.display_message;
        this.setState({ displayingMessage: true });
        iziToast.success({
            title: title,
            message: message,
            position: 'topRight',
            timeout: disappear || 5000,
            close: false,
            onClosing: () => {
                this.setState({ display_message: null, displayingMessage: false });
            }
        });
    }
}

  toggleShowPassWord() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  setEmail(value) {
    this.credentials.email = value;
  }
  setPassword(value) {
    this.credentials.password = value;
  }
  login(e) {
    e.preventDefault();

    this.setState({ display_error: null });
    this.credentials.langue_id = this.app.getCurrentLang().id;
    this.ajaxPost("client/login", this.credentials, (response) => {
      try {
        if (response.token) {
          localStorage.setItem("apiToken", response.token);
          this.goTo(this.suffixe + "/compte");
        }
        if (response.errors) {
          //alert(response.errors)
          // this.setState({ display_error: response.errors.toString() });
          this.showDisplayError(response.errors.toString() );
          // this.setState({ display_error: response.errors.toString() });
        }
      } catch (e) {}
    });
  }
  render() {
    return this.isLogged() && !(this.app.client && this.app.client.visitor) ? (
      ""
    ) : (
      <div id="loginform">
        <div class="w-100 d-flex justify-content-end"  onClick={e=>this.switchPopupElement(e,'#section-login')}>
          <svg
            class="svg-inline--fa fa-xmark text-danger"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="xmark"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            ></path>
          </svg>
        </div>
        {this.state.display_error ? this.showDisplayError("Login") : ""}
        {this.state.display_message ? this.showDisplayMessage("Login") : ""}
        <form action="" className="zone">
          <LabelZone
            label={this.app.getCurrentLangText("site_text_connectez_vous")}
            whithlogo={true}
          />
          <div className="formfield">
            <img
              loading="lazy"
              src="/assets/pictures/user-client-color.svg"
              alt=""
              className="icon"
            />

            <div className="input">
              <input
                type="text"
                placeholder={this.app.getCurrentLangText("site_text_adresse_email")}
                name="email"
                onChange={(e) => this.setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="formfield">
            <img
              loading="lazy"
              src="/assets/pictures/lock.svg"
              alt=""
              className="icon"
            />
            <div className="input withicon">
              <input
                type={this.state.showPassword ? "text" : "password"}
                placeholder={this.app.getCurrentLangText("site_text_mot_de_passe")}
                name="password"
                onChange={(e) => this.setPassword(e.target.value)}
              />
              <img
                loading="lazy"
                className="cursor-pointer icon"
                src="/assets/pictures/view.svg"
                alt=""
                onClick={(e) => this.toggleShowPassWord()}
              />
            </div>
          </div>
          <div className="text-right forgotpassword">
            <a href={this.suffixe + "/lost-password"}>
              {this.app.getCurrentLangText("site_text_mot_de_passe_oublie")}?
            </a>
          </div>
          <button onClick={(e) => this.login(e)}>
            {this.app.getCurrentLangText("site_text_se_connecter")}
          </button>
        </form>
        <form action="" className="zone">
          <div className="label_classic">
            {this.app.getCurrentLangText("site_text_connecter_directement_avec")} :
          </div>
          <div className="liste-options-connexion">
            <div
              className="option-connexion"
              onClick={(e) => this.openGooglePopup()}
            >
              <img
                loading="lazy"
                src="/assets/pictures/icon-google-color.svg"
                alt=""
              />
            </div>
            <div
              className="option-connexion"
              onClick={(e) => this.openFacebookPopup()}
            >
              <img
                loading="lazy"
                src="/assets/pictures/icon-facebook-color.svg"
                alt=""
              />
            </div>
            <div
              className="option-connexion d-none"
              onClick={(e) => this.openApplePopup()}
            >
              <img
                loading="lazy"
                src="/assets/pictures/icon-apple-color.svg"
                alt=""
              />
            </div>
          </div>
        </form>
        <div className="sepa faded"></div>
        <div className="zone">
          <div className="label_classic">
            {this.app.getCurrentLangText("site_text_je_suis_nouveau")} !
          </div>
          <button
            onClick={(e) => this.switchPopupElement(e, "#section-register")}
            className="lined useraction-toggle"
            id="toggle-section-register"
          >
            {this.app.getCurrentLangText("site_text_sinscrire")}
          </button>
        </div>
      </div>
    );
  }
}

export default LoginForm;
