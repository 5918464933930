


import AjaxComp from "../../../data/AjaxComp";
import CategorieCreateur from "../../zones/categorie-createur";

class ListeCategoriesCreateur extends AjaxComp {

    queryname   = 'categories'
    path_params = this.suffixe+'/shop/:id'
    attrs_query = ["id","designation","designation_en","website","categorie_id","parent{id,designation}","produits{id}", "enfants{id}","nb_sous_produit"]
    // default_conditions = {createur:1}
    default_conditions = {website:true,langue_id:this.app.getCurrentLang().id}
    
    constructor(props){
        super(props)
        this.state = {...props}
        // this._update()
    }
    render(){
        return (


            <div id="liste-categories-createur">
                {
                    this.state.categories && this.state.categories.length ? 
                        this.state.categories.map(
                            categorie=>{
                                return <CategorieCreateur {...categorie} createur={this.state.createur}/>
                            }
                        )
                    : ''
                }
            </div>


        )
    }


}

export default ListeCategoriesCreateur