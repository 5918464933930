import AjaxComp from "../../data/AjaxComp";
import './whatsappchat.css'
class WhatsappChat extends AjaxComp {

    
    constructor(props){
        super(props)
    }

    render(){
        return (
            <a target='_blank' href={this.app.getPreferencesData("whatsappnumber") ?("https://api.whatsapp.com/send?phone="+ this.app.getPreferencesData("whatsappnumber")):"#"} className="whatsappchat">
                <div className="beat whatsappchat-icon">
                    <img loading="lazy" src="/assets/pictures/whatsappchat.svg" alt=""/>
                </div>
            </a>
        )
    }


}

export default WhatsappChat