import SectionCategories from "../layout/sections/section_categories";
import SectionLogin from "../layout/sections/section_login";
import Top from "../layout/top/top";
import Stats from "../layout/zones/stats";
import FeedBacks from "../layout/zones/feedbacks";
import BaseMinimale from "./base_minimale";
import AjaxComp from "../data/AjaxComp";

class Base extends AjaxComp {
  constructor(props) {
    super(props);
    this.state = { children: [], ...props };
    if (props.children) {
      this.state = { children: props.children };
    }
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    const { children } = this.state;
    return (
      <>
        <BaseMinimale
          {...this.state}
          noHeader={this.props.noHeader}
          title={this.props.title}
          subtitle={this.props.subtitle}
        >
          {typeof children == "string" || typeof children == "object"
            ? children
            : children.map((Child, key) => {
                return Child;
              })}
          <Stats />
          <FeedBacks />
        </BaseMinimale>
      </>
    );
  }
}

export default Base;
