import PartenaireForm from "../../forms/formulaire_partenaire";
import AjaxComp from "../../data/AjaxComp";

class SectionPartenaire extends AjaxComp {



    render(){
        return(
            <div id="section-partenaire">
                <PartenaireForm></PartenaireForm>
            </div>
        )
    }
}
export default SectionPartenaire