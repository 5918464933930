import AjaxComp from "../../data/AjaxComp";
import ListeProduitsAccroche from "../containers/listes/liste-produits-accroche";
import LabelZone from "../extra/labelZone";

class Recommendations extends AjaxComp {
    produit_id  = null
    queryname   = 'produits'
    attrs_query = ['id','image','sur_commande','description','description_langue','nom_langue','is_nouveaute','prix','prix_promo','is_promo','pourcentage_promo','stock_global','prix','prix_devises{prix}','designation','details{designation,valeur}','produit_complementaires{id,produit_id,produit_complementaire{id,createur{id,designation,photo},prix,prix_devises{prix},designation,image,prix,declinaisons{id,taille_id,couleur_id,couleur{id,nom},declinaisons{id,taille{id,nom},taille_id,couleur_id}}}}','notes_avis{note,avis,created_at,client{id,nom_complet,email}}','createur{id,designation,photo,couverture,nbr_produits},images{id,src},declinaisons{id,produit_id,nom_declinaison,display_text,quantity,couleur_id,couleur{id,nom,description,code_couleur},declinaison_id,declinaisons{id,produit_id,nom_declinaison,display_text,quantity,declinaison_id,taille_id,taille{id,nom,description},produit{id,designation,prix}}}']
    default_conditions = {
        website:true,for_website:true
    }

    constructor(props){
        super(props)
        this.app.recommendations = this
        const {id} = props
        this.produit_id = id
        this.dataAction()
    }
    dataAction()
    {

        // this.produit_id = this.state.id
        if(this.produit_id && !this.state.produit)
        {
          this.customGraphql
          (
              'produits',
              {
                website:true,for_website:true,id:this.produit_id,langue_id:this.app.getCurrentLang().id
              },
              this.attrs_query,
              (produits)=>{
                  if(produits.produits && produits.produits.length>0){
                      console.info(produits,'as')
                      this.updateSafelyState({produit:produits.produits[0]})
                      this.forceUpdate()
                  }
                  else
                  {
                      console.info('probleme detail produit')
                  }
              }
          )
        }
    }
    render(){
        
        return (

                <div className="zone"  id="recommandations">
                    <LabelZone label={this.app.getCurrentLangText("site_text_nous_vous_recommandons")}></LabelZone>
                    <ListeProduitsAccroche isfor={'recommendations'} produits={this.state.produit?this.state.produit.produit_complementaires.map(
                        e=>{
                            return e.produit_complementaires
                        }
                    ):[]} >
                    </ListeProduitsAccroche>
                </div>


        )
    }


}

export default Recommendations