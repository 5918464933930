import {BrowserRouter as Router,Route, Routes } from 'react-router-dom'
import AccrocheProduits from './layout/zones/accroche-produits'
import './master.css'
import { accroche_produits ,categories_createur,home_createur,pictures,produit} from './myseed';
import Base from './pages/base';
import DetailsProduit from './pages/details_produit';
import './App.css'
import './main.js'
import CategorieCreateur from './layout/zones/categorie-createur';
import ListeCategoriesCreateur from './layout/containers/listes/liste-categories-createur';
import BaseMinimale from './pages/base_minimale';
import HomeCreateur from './pages/homecreateur';
import Blog from './pages/Blog.jsx';
import Article from './pages/article';
import React from 'react';
// import FocusSlider from './layout/containers/sliders/FocusSlider';
// import SlideBox from './layout/containers/sliders/SlideBox';
import Home from './pages/home';
import Voirplus from './pages/voirplus';
import master from './master';
import NotFound from './pages/notfound';
import Commande from './pages/commande';
import ValiderCommande from './pages/validercommande';
import Compte from './pages/compte';
import AjaxComp from './data/AjaxComp';
import Gestion_Panier from './data/control/gestion_panier';
import LostPassword from './pages/lostpassword';
import Gestion_Commande from './data/control/gestion_commande';
import Categories from './pages/categories.js';
import Waiter from './layout/sections/waiter.js';
import PetitPrix from './pages/petitprix.js';
import GoHome from './pages/gohome.js';
import StatutPaiement from './pages/statutpaiement.js';
import Shop from './pages/shop.js';
import Livraison from './pages/livraison.js';
import ModalChangeLangue from './forms/form_change_lang.jsx';



var masterLoaded = null

let renderPage = {
  home_createur: (props) => {
    const { id } = props.match.params;
    return <HomeCreateur categories={categories_createur} createur={home_createur} />;
  },
  home: (props) => {
    return <Home />;
  },
  commande: (props) => {
    return <Commande />;
  },
  article: (props) => {
    return <Article />;
  },
  details_produit: (props) => {
    const { id } = props.match.params;
    return <DetailsProduit produit={produit} />;
  },
};
class App extends AjaxComp {
  default_multiple = true
  //conditions par default pour la fonction _update
  state = {loading : true}
  default_conditions=[
    {is_on_website:true},
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    {etat:true,is_on_website:true}
  ]
  //pour la recuperation multiple
  querynames = [
    'categories',
    'activites',
    'civilites',
    'pays',
    'type_partenariats',
    'pays',
    'langues',
    'preferences',
    'modepaiements'
  ]
  //les attributs pour chaque query
  attrs_queries = [
    ['id','designation','image','nom_langue'],
    ['id','designation'],
    ['id','designation'],
    ['id','name','zone_livraison_id,zone_livraison{designation,id},display_commande'],
    ['id','designation'],
    ['id','name','zone_livraison_id,zone_livraison{designation,id},display_commande'],
    ['nom','diminutif','image','status'],
    ["id,nom,display_name,value,description,type,possible_values,status"],
    ["id,designation,etat,online,image"]
  ]

  constructor(props) {
    super(props);
    window.App = this;
    AjaxComp.prototype.app = this
    this.state = { ...props ,...this.state};
    this.panier = new Gestion_Panier(props);
    this.commandes = new Gestion_Commande(props);


    this.getCurrentLangText("ok");
  }
  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }
  scrollToTop() {
    window.scrollTo(0, 0);
  }
  getPreference(preferencename){
      let preference = null
      let match = (this.app.localSearch('preferences','nom',preferencename))
      if(match){
          console.error(match)
        preference = match[0]
      }
      return preference
  }
  
getCurrentLangText(designation, typemessagefields) {
  let messageField = "";
  if (this.state.data && this.state.data.typemessagefields)
  {
    this.state.data.typemessagefields.forEach(
      typemessagefield=>{
        if(typemessagefield.field == designation){
          messageField = typemessagefield.display_text;
        }
        return messageField;
      }
    )
  }
  return messageField ? messageField : '';
};

getPreferencesData(designation) {
  let retour = "";
  if (this.state.data && this.state.data.preferences)
  {
    this.state.data.preferences.forEach(
      preference=>{
        if(preference.nom == designation){
          retour = preference.value;
        }
      }
    )
  }
  return retour ? retour : ' ';
};

  getProduitsFavoris(cb=()=>{}){
    this.customGraphql('produits',{website:true,for_website:true,is_favori:true},["id","nom_langue","designation,sur_commande,image"],favoris=>{
      if(favoris.errors){
        console.log('erreur produits favoris ,',favoris.errors)
      }else{
        this.data.produits_favoris = favoris.produits
        this.updateSafelyState({data:{...this.state.data,...this.data}})
      }
      cb(favoris)
    })
  }
  calculerDifferenceTemps(dateFournie) {
    var dateActuelle = new Date();
    var dateFournieObj = new Date(dateFournie);
    var difference = dateActuelle - dateFournieObj;
    var jours = Math.floor(difference / (1000 * 60 * 60 * 24));
    var heures = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    var secondes = Math.floor((difference % (1000 * 60)) / 1000);
    var resultat = "";
    if (jours > 0) {
        resultat += jours + this.app.getCurrentLangText("site_text_jours");
    }
    if (heures > 0) {
        resultat += heures + this.app.getCurrentLangText("site_text_heures");
    }
    if (minutes > 0) {
        resultat += minutes + this.app.getCurrentLangText("site_text_minutes");
    }
    if (secondes > 0) {
        resultat += secondes + this.app.getCurrentLangText("site_text_secondes"); 
    }
    return  this.app.getCurrentLangText("site_text_il_y_a") + " " + resultat;
  }
  getNahyelDefaultLang(){
    let defaultLang = null
    if(this.state.data && this.state.data.langues){
      this.state.data.langues.forEach(
        langue=>{
          if(langue.status == true){
            defaultLang = langue
          }
        }
      )
    }
    return defaultLang
  }
  hasSetToken(){
    return this.hasUrlProp('settoken')
  }
  hasResetToken(){
    return this.hasUrlProp('resettoken')
  }
  hasMoveTo(){
    return this.hasUrlProp('moveto')
  }
  processToken(){
    if(this.hasSetToken()){
      localStorage.setItem('apiToken', this.hasSetToken());
      
      document.location.href = this.hasMoveTo() ? this.suffixe+this.hasMoveTo() : (this.app.client && !this.app.client.visitor ? (this.suffixe+"/compte") : this.suffixe)
      
    }
  }
  doMoveToReset(){
    if(this.hasMoveTo()){
      if(this.hasResetToken()){
        localStorage.setItem('resetToken', this.hasResetToken());
        document.location.href = this.suffixe+"/"+this.hasMoveTo()
      }
    }
  }
  getCurrentLang(){
    if (localStorage.getItem('currentlang')) {
      return JSON.parse(localStorage.getItem('currentlang'));
    } 
    else {
      return this.getNahyelDefaultLang();
    }
  }
  setCurrentLang(lang){
    const retour = localStorage.setItem('currentlang',JSON.stringify(lang))
    this.updateSafelyState({currentlang:this.getCurrentLang()})
    document.location.reload()
    return retour
  }
  getCurrentDevise(){
    return JSON.parse(localStorage.getItem('currentdevise'))
  }
  setCurrentDevise(devise){
    const retour = localStorage.setItem('currentdevise',JSON.stringify(devise))
    this.updateSafelyState({currentlang:this.getCurrentLang()})
    document.location.reload()
    return retour
  }
  componentDidMount() {
    if(this.client_id)
    {
      let client = this.client
      let data = client 
      this.panier.updateSafelyState({client})
      this.panier.getIt()
      this.commandes.updateSafelyState({client})
      this.commandes.setCommandes()
      if(this.section_compte_client){
        this.section_compte_client.updateSafelyState({data})
      }
    }
    else
    {
      this.setupClientData(data=>{
        if(data && data.id){
          let client = this.client =  data
          this.client_id = data.id
          this.updateSafelyState({client_id:this.client_id})
          this.default_conditions.id=this.client_id
          this.getProduitsFavoris()
          this.updateSafelyState({client})
          this.panier.updateSafelyState({client})
          this.panier.getIt()
          this.commandes.updateSafelyState({client})
          this.commandes.setCommandes()
          if(this.section_compte_client){
            this.section_compte_client.updateSafelyState({data})
          }
        }
        else
        {
          this.logoutUser()
        }
      })
    }
    if (!masterLoaded) {
      master();
      masterLoaded = true;
      this.processToken()
    }

    this.setState({...this.state,mounted:true})

    this.doMoveToReset()
    
  if(!this.state.data || (this.state.data && !this.state.data.typemessagefields))
  { 
    // if (localStorage.getItem('typemessagefields')) {
    //   let Data =  JSON.parse(localStorage.getItem('typemessagefields'));
    //   Data.typemessagefields = Data
    //   this.updateSafelyState({data:Data})
    // } 
    // else
    // {
      const filtre = this.getCurrentLang() ? { type: 1, langue_id: this.getCurrentLang().id } : { type: 1 };
      this.customGraphql(
        'typemessagefields',filtre,['id,designation,field,display_text'],data=>{
          let Data = this.state.data ?? {}
          Data.typemessagefields = data.typemessagefields;
          this.updateSafelyState({data:Data});
          localStorage.setItem('typemessagefields',JSON.stringify(data.typemessagefields))
          if(this.waiter)
          {
            this.waiter.processShow()
          }
        }
      )
    // }

    
  };

  if(!this.state.data || (this.state.data && !this.state.data.preferences))
  {
    this.customGraphql(
      'preferences',null,["id,nom,display_name,value,description"],data=>{
        if(data.errors){
          console.log('erreur data ,',data.errors)
        }else{
          console.log('data , papa ',data)
          this.updateSafelyState({data})
        }
      }
    )
  }

    if(!this.state.data || (this.state.data && !this.state.data.langues))
    {
      this.customGraphql(
        'langues',{website:true},['id,nom,diminutif,image'],data=>{
            this.updateSafelyState(data)
            if(this.waiter)
            {
              this.waiter.processShow()
            }
        }
      )
    }
    if(!this.state.data || (this.state.data && !this.state.data.devises))
    {
      this.customGraphql(
          'devises',{website:true},['id,designation,taux_change,signe'],data=>{
              this.updateSafelyState(data)
              if(this.waiter)
              {
                this.waiter.processShow()
              }
          }
      )
    }
    if(!this.state || (this.state && !this.state.history))
    {
      this.ajaxGet(
        'searchhistory',data=>{
          if(!data.errors){
            this.updateSafelyState({history:data?data:[]})
          }
        }
      )
    }
  }


  addFavoriClient(id){
    if(this.app.state.client_id)
    {
        this.ajaxPost(
            'favorisclient',{produit_id:id,is_visite:false,client_id:this.app.state.client_id,langue_id:this.getCurrentLang().id},data=>{
                this.setupClientData(client=>{
                  this.saveClientModel(client)
                  this.app.client=client
                  this.app.updateSafelyState({client:client})
                },true)
            }
        )
    }
  }



  getCurrentPrice(produit,is_promo=false)
  {
    const devise = this.getCurrentDevise();
    const prixDevise = (produit.prix_devises && produit.prix_devises.length) ? (produit.prix_devises[0].prix ?? []) : []
    let prix = produit.prix
    prixDevise.forEach(
      (p)=>{
        if(p[0] === devise.designation)
        {
          prix  = p[is_promo?2:1]
        }
      }
    )
    return prix
  }
  


  removeFavoriClient(id){
    if(this.app.state.client_id)
    {
        this.ajaxPost(
            'deletefavorisclient/'+id,{langue_id:this.getCurrentLang().id, client_id: this.app.state.client_id},data=>{
                this.setupClientData(data=>{
                 this.saveClientModel(data)
                 this.app.client=data
                  this.app.updateSafelyState({client:data})
                },true)
            }
        )
    }
  }
  setUrlParam(url,param,value)
  {
    var url = new URL(url);
    var search_params = url.searchParams;

    // new value of "id" is set to "101"
    search_params.set(param, value);

    // change the search property of the main url
    url.search = search_params.toString();

    // the new url string
    var new_url = url.toString();

    console.log(new_url);
    return new_url
  }
  isFavoriClient(produitId){
    let yes = false
    if(this.app.client && this.app.client.favoris){
      this.app.client.favoris.map(
        ({id})=>{
          if(id == produitId){
            yes = true
          }
        }
      )
    }
    return yes
  }
  render() {

    const devise = this.getCurrentDevise()
    const lang   = this.getCurrentLang()
    if(! (devise && lang)){
      if(!this.state.loading){
        this.updateSafelyState({loading:true});
      }
    }
    return (
      <>
         {
          (this.state.loading || this.state.changelangordevise) ?
              <Waiter/> 
            : <>
              {this.state.display_error ? this.showDisplayError("Erreur",null) : ""}
              {this.state.display_message ? this.showDisplayMessage("Success",null) : ""}
              <Router>
                <Routes>
                  <Route path="/" element={<GoHome />} />
                  <Route path={this.suffixe} element={<Home />} />
                  <Route path={this.suffixe+"/commande"} element={<Commande />} />
                  {/* <Route path={this.suffixe+"/commande"} element={<GoHome />} /> */}
                  <Route path={this.suffixe+"/validercommande"} element={<ValiderCommande />} />
                  <Route path={this.suffixe+"/home-createur/:id"} element={<HomeCreateur />} />
                  <Route path={this.suffixe+"/blog"} element={<Blog />} />
                  <Route path={this.suffixe+"/categorie/:id"} element={<Voirplus/>} />
                  <Route path={this.suffixe+"/statutpaiement/:id"} element={<StatutPaiement/>} />
                  <Route path={this.suffixe+"/livraison/:id"} element={<Livraison/>} />
                  <Route path={this.suffixe+"/categories"} element={<Categories/>} />
                  <Route path={this.suffixe+"/shop/:id"} element={<Shop/>} />
                  <Route path={this.suffixe+"/details-produit/:id"} element={<DetailsProduit />} />
                  <Route path={this.suffixe+"/article/:id"} element={<Article />} />
                  <Route path={this.suffixe+"/lost-password"} element={<LostPassword />} />
                  <Route path={this.suffixe+"/petitprix"} element={<PetitPrix/>} />
                  <Route path={this.suffixe+"/compte"} element={<Compte user={this.state.client} />} />
                  {/* <Route path="*" element={<NotFound />} /> */}
                </Routes>
              </Router>
       
            </>
        } 
      </>
    );
  }
}

export default App;
