import AjaxComp from "../../data/AjaxComp";
import RegisterForm from "../../forms/register";

class SectionRegister extends AjaxComp {


    render(){

        return (

                <div id="section-register">
                    <RegisterForm></RegisterForm>
                </div>
    
        )
    }
}
export default SectionRegister