import AjaxComp from "../../data/AjaxComp";
import './stats.css'

class Stats extends AjaxComp {

    render(){
        return (

            <div className="" id="stats">
                <div className="statlist">
                    <div className="stat">
                        <div className="statpic">
                            <img loading="lazy" src="/assets/pictures/client-success.svg" alt=""/>
                        </div>
                        <h3 className="statinfo">
                            20+
                        </h3>
                        <div className="statlabel">
                            {this.app.getCurrentLangText("site_text_partenaires") ? this.app.getCurrentLangText("site_text_partenaires") : "Partenaires"}
                        </div>
                    </div>
                    <div className="stat">
                        <div className="statpic">
                            <img loading="lazy" src="/assets/pictures/produit.svg" alt=""/>
                        </div>
                        <h3 className="statinfo">
                            3000+
                        </h3>
                        <div className="statlabel">
                            {this.app.getCurrentLangText("site_text_produits") ? this.app.getCurrentLangText("site_text_produits") : "Produits"}
                        </div>
                    </div>
                    <div className="stat">
                        <div className="statpic">
                            <img loading="lazy" src="/assets/pictures/calendar.svg" alt=""/>
                        </div>
                        <h3 className="statinfo">
                            24h/24 - 7j/7
                        </h3>
                        <div className="statlabel">
                            {this.app.getCurrentLangText("site_text_horaires") ? this.app.getCurrentLangText("site_text_horaires") : "Horaires"}
                        </div>
                    </div>
                    <div className="stat">
                        <div className="statpic">
                            <img loading="lazy" src="/assets/pictures/quality.svg" alt=""/>
                        </div>
                        <h3 className="statinfo">
                            100%
                        </h3>
                        <div className="statlabel">
                            {this.app.getCurrentLangText("site_text_qualite") ? this.app.getCurrentLangText("site_text_qualite") : "Qualité"}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default Stats