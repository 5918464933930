import { Link } from "react-router-dom";
import AjaxComp from "../../data/AjaxComp";
import './scroll-categories.css'

// import { categories } from "../../myseed";
class ScrollCategories extends AjaxComp {
    queryname = 'categories'
    default_conditions = {is_on_website:true,website:true,langue_id:this.app.getCurrentLang().id}
    attrs_query = ['id','designation','image','nom_langue']
    constructor(props){
        super(props)
        this.state.data.categories = []
        this._update()
    }
    render(){
        const {categories} = this.state.data
        return (
            <div id="scroll-categories">
                {
                    categories?.map(
                        (categorie,key)=>{
                            return <a href={this.suffixe+'/categories?search='+categorie.id +"&stepp=1"} className="scroll-categorie" key={key}>
                                <img loading="lazy" src={categorie.image} alt=""/>
                                <span>
                                    {categorie.nom_langue}
                                </span>
                            </a>
                        }
                    )
                }
                {
                    // this.state.data.categories? '' : 
                    //     <>

                    //         <div className="scroll-categorie">
                    //             <img loading="lazy" style={{background:'#7776'}} src="" alt=""/>
                    //             <span style={{filter:"brightness(30%)"}}>
                    //                 <span style={{width:'30%',border:"2px solid #7776"}}></span>
                    //             </span>
                    //         </div>
                    //         <div className="scroll-categorie">
                    //             <img loading="lazy" style={{background:'#7776'}} src="" alt=""/>
                    //             <span style={{filter:"brightness(30%)"}}>
                    //                 <span style={{width:'30%',border:"2px solid #7776"}}></span>
                    //             </span>
                    //         </div>
                    //         <div className="scroll-categorie">
                    //             <img loading="lazy" style={{background:'#7776'}} src="" alt=""/>
                    //             <span style={{filter:"brightness(30%)"}}>
                    //                 <span style={{width:'30%',border:"2px solid #7776"}}></span>
                    //             </span>
                    //         </div>
                    //         <div className="scroll-categorie">
                    //             <img loading="lazy" style={{background:'#7776'}} src="" alt=""/>
                    //             <span style={{filter:"brightness(30%)"}}>
                    //                 <span style={{width:'30%',border:"2px solid #7776"}}></span>
                    //             </span>
                    //         </div>
                    //         <div className="scroll-categorie">
                    //             <img loading="lazy" style={{background:'#7776'}} src="" alt=""/>
                    //             <span style={{filter:"brightness(30%)"}}>
                    //                 <span style={{width:'30%',border:"2px solid #7776"}}></span>
                    //             </span>
                    //         </div>
                    //     </>



                    
                }
            </div>
        )
    }


}


export default ScrollCategories