import AjaxComp from "../data/AjaxComp";
import BaseMinimale from "./base_minimale";
import ZoneDetailsCreateur from "../layout/zones/details-createur";
import HorizontalScrollCategories from "../layout/zones/scroll-categories";
import ListeCategoriesCreateur from "../layout/containers/listes/liste-categories-createur";
import Stats from "../layout/zones/stats";
import FeedBacks from "../layout/zones/feedbacks";
import ListeProduitsAccroche from "../layout/containers/listes/liste-produits-accroche";
// import { categories_createur } from "../myseed";
import SectionNote from "../layout/sections/section_note";
import StarList from "../layout/extra/starlist";
import Galerie from "../layout/sections/galerie";
import Loader from "../Loader";
class HomeCreateur extends AjaxComp {
  queryname = "createurs";
  path_params = this.suffixe+'/home-createur/:id'
  default_conditions = {active:true}
  attrs_query = ["id", "designation","nbr_produits","nb_favori_clients",'notes_avis{note,avis,created_at,client{id,nom_complet,email}}', "categories{id,designation,produits{id,designation,nom_langue,image,prix,pourcentage_promo,stock_global,is_nouveaute}}", "photo","couverture"];
  
  constructor(props) {
    super(props);
   // this.state = { ...props };

    this.state = {...props,loading:true}
    this._update(
      data=>{
        this.setState({loading:false})
      }
    )
  }

  componentDidMount() {
    document.title = "Accueil créateur";
    this.app.getCurrentLangText("site_text_voir_plus"); 
    this.setState({mounted:true})
    this.setRouteParams();
    if(this.getRouteParams()){
      this.createur_id = this.getRouteParams().params.id;
      console.log(this.createur_id, "papa this.getRouteParams().params data data", this.getRouteParams().params)
      this.customGraphql('categories',{createur_id:this.createur_id,website:true,langue_id:this.app.getCurrentLang().id},['designation','nom_langue','image','produits{id,designation,nom_langue,image,prix,prix_devises{prix},createur_id,createur{id,designation,photo}}'],categories=>{
        if(categories.errors){
          
        }else{
          this.data.categories = categories.categories
          this.updateSafelyState({data:{...this.state.data,...this.data},categories:categories.categories})
        }
        
      })
      this.default_conditions = {id:this.createur_id}
      this.customGraphql('createurs',this.default_conditions,this.attrs_query,createurs=>{
        if(createurs.errors){
      
        }else{
          this.data.createurs = createurs.createurs
          this.updateSafelyState({data:{...this.state.data,...this.data},createur:createurs.createurs})
        }
      })
      this.customGraphql(

        'produits',{is_suggestion:true,createur_id:this.createur_id,website:true,for_website:true,langue_id:this.app.getCurrentLang().id},["id,image,designation,nom_langue,sur_commande,prix,prix_devises{prix},images{id,src},createur_id,createur{id,designation,photo}"],data=>{
          if(data.produits)
          {
            data.produits = data.produits.filter(
              produit=>{
                return produit.createur_id === this.createur_id
              }
            )
            this.updateSafelyState({data:{...this.state.data,suggestions:data.produits}})
          }
        }
      )
      this.customGraphql(

        'produits',{is_nouveaute:true,createur_id:this.createur_id,website:true,for_website:true,langue_id:this.app.getCurrentLang().id},["id,image,designation,nom_langue,sur_commande,prix,prix_devises{prix},images{id,src},createur_id,createur{id,designation,photo}"],data=>{
          if(data.produits)
          {
            data.produits = data.produits.filter(
              produit=>{
                return produit.createur_id === this.createur_id
              }
            )
            this.updateSafelyState({data:{...this.state.data,nouveautes:data.produits}})
          }
        }
      )
    }
  }

  render() {
    //let createur = {}
    let createur = (this.state.data && this.state.data.createurs && this.state.data.createurs.length ) ? this.state.data.createurs[0] : null;
    let {categories} = this.state;

    if(categories && this.state.loading){
      this.setState({loading:false})
    }

    if(this.state.data && this.state.data.createurs && this.state.data.createurs.length)
    {
        this.state.data.createurs.forEach(
          (dataCat)=>{
            if(dataCat.id == this.createur_id)
            {
              //alert("ok");
              createur = dataCat;
            }
          }
        )
    }

    console.log("createur papa data data",createur, categories, this.state.data);
    
    return (
      (createur && categories) ? 
      // (createur) ? 
      // (this.state.data && this.state.data.createurs && this.state.data.createurs.length && this.state.data.categories && this.state.data.categories.length) ? 
        <BaseMinimale customHeader={ (createur&& createur.couverture)?createur.couverture:"/assets/pictures/createur.png"} >
          <ZoneDetailsCreateur createur={createur??{}} />
          <HorizontalScrollCategories />
          {
            this.state.data.categories && this.state.data.categories && this.state.data.categories.length
            ?
              <ListeCategoriesCreateur categories={categories} createur={createur}/>
            : ''
          }
          {
            this.state.data.categories && this.state.data.categories && this.state.data.categories.length
            ?
              <div
                className="btn_classic_container mt-2"
              >
                <a href={this.suffixe+"/categories?createur_id="+createur.id} className="btn_classic">{this.app.getCurrentLangText("site_text_voir_plus") ? this.app.getCurrentLangText("site_text_voir_plus") : "Voir plus"}...</a>
              </div>
            :
              ''
          }
          
          {
            <div className="zone">
              <ListeProduitsAccroche  isTriple={true} isfor={'nouveautes'} produits={this.state.data.nouveautes} ></ListeProduitsAccroche>
            </div>
          }
          {
            <div className="zone">
              <ListeProduitsAccroche labelZone={true} isfor={'suggestions'} produits={this.state.data.suggestions} ></ListeProduitsAccroche>
            </div>
          }
          
          <div className="notes_section zone">
            <div className="addnote_section mb-2">
              <SectionNote createur_id={createur.id}></SectionNote>
            </div>
           {createur.notes_avis.length == 0 ? '' : <div className="fist_element">{this.app.getCurrentLangText("site_text_avis_client")} ({createur.notes_avis.length})</div>}
            <div className="com_container">
              {
                createur.notes_avis.map(
                  ({note,created_at,avis,client})=>{
                    return <div className="com_item">
                      <div className="date">
                        <p>{created_at} {this.app.getCurrentLangText("site_text_par")} {client.nom_complet?client.nom_complet:client.email}</p>
                        <p>{this.app.calculerDifferenceTemps(created_at)}</p>
                      </div>
                      <div class="stars">
                        <StarList note={note}/>
                      </div>
                      <div className="com_com">
                        {avis}
                      </div>
                    </div>
                  }
                )
              }
            </div>
          </div>

          <Galerie createur_id={createur.id}/>
          <Stats />
          <FeedBacks />
        </BaseMinimale>
      : <div style={{width:'100vw',height:"100vh"}}><Loader style={{margin:"auto"}} loading={true}/></div>
    )
  }
}

export default HomeCreateur;
