import ContactForm from "../../forms/formulaire_contact";
import AjaxComp from "../../data/AjaxComp";

class SectionContact extends AjaxComp {



    render(){
        return(

            <div id="section-contact">
                <ContactForm></ContactForm>
            </div>
        )
    }
}
export default SectionContact