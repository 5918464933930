import React from "react"

import { MToaster } from "./custom/MToast";

import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

iziToast.settings({
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX',
    onOpening: function(){
        console.log('callback abriu!');
    },
    onClosing: function(){
        console.log("callback fechou!");
    }
});

class NahyelComponent extends React.Component{
    edit_data = {}

    constructor(props){
        super(props)
        this.toaster = new MToaster();
        this.updateSafelyState({edit_data:this.edit_data,...props});

        this.state = {
            display_error: null,
            display_message: null,
            displayingError: false,
            displayingMessage: false,
        };
    }   

    componentDidMount() {
       // this.setState({ mounted: true });
    }

    checkImage(urlTocheck="", defaultValue=false){
        var image = new Image();
        image.src = urlTocheck;
        if (image.width == 0) {
           return defaultValue;
        } else {
           return urlTocheck;
        }
     }
    className() { 
        return this.constructor.name; 
    } 
    editDataState(prop,value){
        this.edit_data[prop] = value
        if(this.state.mounted){
            this.setState({...this.state,...{edit_data:this.edit_data}})
        }else{
            this.state = ({...this.state,...{edit_data:this.edit_data}})
        }
    }
    setDisplayError(error){    
        if(error){
            this.updateSafelyState({display_error:error.toString()}) ;
        }
    }
    setDisplayMessage(message){
        if(message){
            this.updateSafelyState({display_message:message.toString()});
        }
    }

    // showDisplayError(title="Erreur",disappear=null){
    //     let erreur = this.state.display_error
    //     let retour = this.toaster.create(erreur,"error",title,disappear,()=>{
    //         this.setState({display_error:null}) 
    //     })
    //     return retour
    // }
    // showDisplayMessage(title="Info",disappear=null){
    //     let message = this.state.display_message
    //     let retour = this.toaster.create(message,"nahyel",title,disappear,()=>{
    //         this.setState({display_message:null}) 
    //     })
    //     return retour
    // }

    showDisplayError = (title = "Erreur", disappear = null) => {
        if (!this.state.displayingError) {
            const erreur = this.state.display_error;
            this.setState({ displayingError: true });
            iziToast.error({
                title: title,
                message: erreur,
                position: 'topRight',
                timeout: disappear ||  5000,
                close: false,
                onClosing: () => {
                    this.setState({ display_error: null, displayingError: false });
                }
            });
        }
    }

    showDisplayMessage = (title = "Success", disappear = null) => {
        if (!this.state.displayingMessage) {
            const message = this.state.display_message;
            this.setState({ displayingMessage: true });
            iziToast.success({
                title: title,
                message: message,
                position: 'topRight',
                timeout: disappear || 5000,
                close: false,
                onClosing: () => {
                    this.setState({ display_message: null, displayingMessage: false });
                }
            });
        }
    }

    updateSafelyState(state){
        if(this.state && this.state.mounted == true ){
            this.setState({...this.state,...state,gotData:true,App:this.app})
        }else{
             this.state = {...this.state,...state,gotData:true,App:this.app}
        }
        // if(this.state.mounted){
        //     this.forceUpdate()
        // }
    }
    componentDidMount() {
        this.setState({ mounted: true });
    }
    localRelationSearch(query,field,targetquery,targetfield,targetvalue){
        if(this.state.data.hasOwnProperty(query) && this.state.data.hasOwnProperty(targetquery)){
            const dataset = this.state.data[query]
            const targetdataset = this.state.data[targetquery]
            console.info(targetdataset,field,query,targetfield,targetvalue)
            const selectedTarget = targetdataset.find(target => target.hasOwnProperty(targetfield) && target[targetfield] === targetvalue);
            if (selectedTarget && Array.isArray(dataset)) {
                
                const match = dataset.filter(data => data.hasOwnProperty(field) && data[field] == selectedTarget[targetfield]);
                
                return match
            }
        }
        return false
    }
    hasUrlProp(prop){
        return (new URLSearchParams(window.location.search)).get(prop)
    }
    localSearch(query,field,value,DataSet=null){
        // alert(query)
        // alert(JSON.stringify(DataSet))
        if(DataSet && DataSet.hasOwnProperty(query) || (this.state.hasOwnProperty('data') && this.state.data?.hasOwnProperty(query)) || (this.state.hasOwnProperty(query))){
            const dataset =  DataSet ? DataSet[query]  :   (this.state.data[query] ?? this.state[query]) 
            // alert(JSON.stringify(dataset))
            if(dataset && Array.isArray(dataset)) {
                const match = dataset.filter(data => { 
                    return data.hasOwnProperty(field) && data[field] == value
                });
                return match
            }
        }
        return false
    }
}
export default NahyelComponent