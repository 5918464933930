export default (()=>{
let viewPortWidth = window.innerWidth;
let viewPortHeight = window.innerHeight;
let viewPortWidthINPX = viewPortWidth + "px";
let viewPortHeightINPX = viewPortHeight + "px";
const hideable = []
const section_categories = document.querySelector('#section-categories')
const section_login = document.querySelector('#section-login')
const section_contact = document.querySelector('#section-contact')
const section_register = document.querySelector('#section-register')
const section_partenaire = document.querySelector('#section-partenaire')
const choicelists = document.querySelectorAll('.choicelist')
const navbar = document.querySelector('.heading-navband')
const section_categories_toggle = navbar ? navbar.querySelector('.togglemenu') : null
const section_categories_close = document.querySelector('.section-categories-close')
const user_actions_toggle =  document ? document.querySelectorAll('.useraction-toggle') : null

const observeElementVisibility = (selector, enterClassName, exitClassName) => {
    const targetElements = document.querySelectorAll(selector);
    targetElements.forEach(
        targetElement=>{

                if (!targetElement) {
                    console.error(`Element with selector '${selector}' not found.`);
                    return;
                }
              const callback = (entries, observer) => {
                entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                    targetElement.classList.add(enterClassName);
                    targetElement.classList.remove(exitClassName);
                  } else {
                    targetElement.classList.remove(enterClassName);
                    targetElement.classList.add(exitClassName);
                  }
                });
              };
            
              const options = {};
            
              const elementObserver = new IntersectionObserver(callback, options);
              elementObserver.observe(targetElement);
        }
    )
  };
window.observeElementVisibility = observeElementVisibility
  // Example usage:
window.hideable = hideable
window.popups = {}
window.clearPopups = (e)=> {
    console.warn('clearing popups')
    Object.values(window.popups).map(
        (pop,idx)=>{
            // pop.classList.remove('visible')
            if(pop){
                console.warn(pop,e,window.popups.hasOwnProperty('popup_'+pop.id))
                
                // console.warn('popup_'+pop.id)
                try{
                    if(window.popups.hasOwnProperty('popup_'+pop.id)){
    
                        const shouldntHide = (pop.contains(e.target) || e.currentTarget && pop.contains(e.currentTarget) ) && !( pop.isEqualNode(e.target) || pop.isEqualNode(e.currentTarget))
                        if(!shouldntHide){
                            clearPopup(pop.id)
                        }
                    }else{
                        console.warn('ignore popup')
                    }
                }catch(e){
                    console.warn(e)
                }
            }
        }
    )
}
window.clearPopup = (id)=> {
    let pops   = {}
    Object.values(window.popups).map((popup,idx)=>{
        if(popup && popup.id !== id){
            pops[Object.keys(window.popups)[idx]] = popup
        }else{
            const noaction = popup ? popup.classList.remove('visible') : ''
        }
    })
    window.popups = pops
}
const {clearPopup,clearPopups} = window
choicelists.forEach(
    choicelist=>{
        const choices = choicelist.querySelectorAll('.choice')
        const switchChosen = (elem=null)=>{
            choices.forEach(
                choice=>{
                    choice.classList.remove('chosen')
                }
            )
            if(!elem){
                if(!choices.length){
                    return
                }
                elem = choices[0]
            }
            elem.classList.add('chosen')
        }
        choices.forEach(
            choice=>{
                choice.removeEventListener(
                    'click',e=>switchChosen(e.currentTarget)
                )
                choice.addEventListener(
                    'click',e=>switchChosen(e.currentTarget)
                )
            }
        )
        switchChosen()
    }
)




const dropdowns = document.querySelectorAll('.wrap-dropdown')
dropdowns.forEach(
    dropdown=>{
        const icos = dropdown.querySelectorAll('.dropdown-icon')
        icos.forEach(
            ico=>{
                ico.removeEventListener(
                    'click',e=>{
                        const isExpanded = dropdown.classList.contains('expanded')
                        dropdown.classList[isExpanded?'remove':'add']('expanded')
                    },true
                )
                ico.addEventListener(
                    'click',e=>{
                        const isExpanded = dropdown.classList.contains('expanded')
                        dropdown.classList[isExpanded?'remove':'add']('expanded')
                    },true
                )
            }
        )
    }
)





const hideSectionCategories = ()=>{
    if(section_categories){
        section_categories.classList.remove('visible')
    }else{
        
    }
}

const showSectionCategories = ()=>{
    if(section_categories){
        section_categories.classList.add('visible')
    }
}

const sectionCategoriesisVisible = ()=>{
    return section_categories && (section_categories.classList.contains('visible'))
}

const toggleSectionCategories = ()=>{
    sectionCategoriesisVisible() ? hideSectionCategories() : showSectionCategories()
    if(sectionCategoriesisVisible() ){
        hideable.push(
            [
                section_categories.querySelector('#section-categories'),
                ()=>{
                    return  hideSectionCategories()
                }
            ]
        )
    }
}



const hideSectionLogin = ()=>{
    if(section_login){
        section_login.classList.remove('visible')
    }else{
        
    }
}

const showSectionLogin = ()=>{
    if(section_login){
        section_login.classList.add('visible')
    }
}

const sectionLoginisVisible = ()=>{
    return section_login && (section_login.classList.contains('visible'))
}

const toggleSectionLogin = ()=>{
    sectionLoginisVisible() ? hideSectionLogin() : showSectionLogin()
    if(sectionLoginisVisible() ){
        hideable.push(
            [
                section_login.querySelector('#loginform'),
                ()=>{
                    return  hideSectionLogin()
                }
            ]
        )
    }
}

const hideSectionRegister = ()=>{
    if(section_register){
        section_register.classList.remove('visible')
    }else{
        
    }
}

const showSectionRegister = ()=>{
    if(section_register){
        section_register.classList.add('visible')
    }
}

const sectionRegisterisVisible = ()=>{
    return section_register && (section_register.classList.contains('visible'))
}

const toggleSectionRegister = ()=>{
    sectionRegisterisVisible() ? hideSectionRegister() : showSectionRegister()
    if(sectionRegisterisVisible() ){
        hideable.push(
            [
                section_register.querySelector('#registerform'),
                ()=>{
                    return  hideSectionRegister()
                }
            ]
        )
    }
}

const hideSectionContact = ()=>{
    if(section_contact){
        section_contact.classList.remove('visible')
    }else{
        
    }
}

const showSectionContact = ()=>{
    if(section_contact){
        section_contact.classList.add('visible')
    }
}
const hideSectionPartenaire = ()=>{
    if(section_partenaire){
        section_partenaire.classList.remove('visible')
    }else{
        
    }
}


const showSectionPartenaire = ()=>{
    if(section_partenaire){
        section_partenaire.classList.add('visible')
    }
}

const sectionPartenaireisVisible = ()=>{
    return section_partenaire && (section_partenaire.classList.contains('visible'))
}

const sectionContactisVisible = ()=>{
    return section_contact && (section_contact.classList.contains('visible'))
}

const toggleSectionPartenaire = ()=>{
    sectionPartenaireisVisible() ? hideSectionPartenaire() : showSectionPartenaire()
    if(sectionPartenaireisVisible() ){
        hideable.push(
            [
                section_partenaire.querySelector('#partenaireform'),
                ()=>{
                    return  hideSectionPartenaire()
                }
            ]
        )
    }
}


const toggleSectionContact = ()=>{
    sectionContactisVisible() ? hideSectionContact() : showSectionContact()
    if(sectionContactisVisible() ){
        hideable.push(
            [
                section_partenaire.querySelector('#contactform'),
                ()=>{
                    return  hideSectionContact()
                }
            ]
        )
    }
}

const hideSectionCommandes = ()=>{
    const options_section_commande = document.querySelectorAll('.option-section-commande')
    if(options_section_commande){
        options_section_commande.forEach(
            option_section_commande=>{
                const section = document.querySelector('#'+option_section_commande.id.replace('option-',''))
                section.classList.remove('current')
                option_section_commande.classList.remove('current')
            }
        )
    }
}
const showSectionCommande = option_section_commande=>{
    hideSectionCommandes()
    const section = document.querySelector('#'+option_section_commande.id.replace('option-',''))
    section.classList.add('current')
    option_section_commande.classList.add('current')
}


const getUserActionView = (id)=>{
    const item = document.querySelector(`#${id}`)
    return item ? item : null
}

const hideUserActionView = (view)=>{
    if(view){
        view.classList.remove('visible')
    }else{
        
    }
}

const showUserActionView = (view)=>{
    if(view){
        view.classList.add('visible')
    }
}

const UserActionViewisVisible = (view)=>{
    return view && view.classList.contains('visible')
}

const toggleUserActionView = (id)=>{
    const view = getUserActionView(id)
    UserActionViewisVisible(view) ? hideUserActionView(view) : showUserActionView(view)
    if(UserActionViewisVisible(view)){
        hideable.push(
            [
                id=='section-contact'?section_contact.querySelector('#contactform'):(id=='section-login'?section_login.querySelector('#loginform'):(id=='section-partenaire'?section_partenaire.querySelector('#partenaireform') :(id=='section-register'?section_register.querySelector('#registerform') : view))),
                ()=>{
                    hideUserActionView(view)
                }
            ]
        )
    }
}


const hideHideable = (e)=>{
    const id = e.currentTarget.id
    hideable.forEach(
        ([view,hideFunc])=>{
            if(view.id !== id && !view.contains(e.target)){
                hideFunc()
            }
        }
    )
    clearPopups(e)
}

if(section_categories_toggle){
    section_categories_toggle.removeEventListener(
        'click',e=>{
            toggleSectionCategories()
        }
    )
    section_categories_toggle.addEventListener(
        'click',e=>{
            toggleSectionCategories()
        }
    )
}

if(section_categories_close){
    section_categories_close.addEventListener(
        'click',e=>{
            hideSectionCategories()
        }
    )
    section_categories_close.removeEventListener(
        'click',e=>{
            hideSectionCategories()
        }
    )
}

if(user_actions_toggle){
    user_actions_toggle.forEach(
        useractiontoggle=>{
            let id = useractiontoggle.id.match('toggle-') ? useractiontoggle.id.replace('toggle-','') : null
            if(!id){
                useractiontoggle.classList.filter(
                    className=>{
                        
                        return className.match('toggle-')
                    }
                ).forEach(
                    className=>{    
                        id = className.replace('toggle-','')
                    }
                )
            }
            if(id){
                useractiontoggle.removeEventListener(
                    'click',e=>{
                        toggleUserActionView(id)
                    }
                )
                useractiontoggle.addEventListener(
                    'click',e=>{
                        toggleUserActionView(id)
                    }
                )
            }
        }
    )
}
window.setupOptionsSectionCommande=()=>{
    const options_section_commande = document.querySelectorAll('.option-section-commande')
    if(options_section_commande){
        options_section_commande.forEach(
            option_section_commande=>{
                option_section_commande.addEventListener(
                    'click',e=>showSectionCommande(option_section_commande)
                )
                option_section_commande.removeEventListener(
                    'click',e=>showSectionCommande(option_section_commande)
                )
            }
        )
    }
}
window.addEventListener(
    'click',e=>{
        hideHideable(e)
    },false
)})
window.addEventListener(
    'load',ev=>{
        let lastScroll = 0
        document.addEventListener(
            'scroll',e=>{
                // window.headingNavband = window.navheight = document.querySelector('.heading-navband_container')
                if(window.headingNavband)
                {
                    const promoband = document.querySelector('.heading-promoband')
                    const socialband_container = document.querySelector('.heading-socialband_container')
                    if((window.scrollY >= 100)){
                        
                        window.headingNavband.classList.add('material')
                        promoband.classList.add('oheight')
                        socialband_container.classList.add('oheight')
                    }
                    else
                    {
                        window.headingNavband.classList.remove('material')
                    }
                    if(lastScroll > window.scrollY && (parseInt(window.scrollY * 100 / (document.body).getBoundingClientRect().height ) < 15)){
                        promoband.classList.remove('oheight')
                        socialband_container.classList.remove('oheight')
                    }
                    lastScroll = window.scrollY
                }
            }
        )
    }
)
window.addEventListener(
    'load',e=>{
        // window.observeElementVisibility("*:not(.fa):not(#close-section-categories):not(header):not(header *)", "fadeIn", "fadeOut");
        // window.observeElementVisibility("#selection", "fromLeft", "toLeft");
        
    }
)