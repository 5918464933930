import AjaxComp from "../../data/AjaxComp";
import DetailsCreateur from "../extra/details-createur";
class ZoneDetailsCreateur extends AjaxComp {

    constructor(props){
        super(props)
        this.state = {...props}
    }
    render(){
        return (
            <DetailsCreateur className={'zone'} {...this.state}/>
        )
    }


}

export default ZoneDetailsCreateur