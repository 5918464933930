import AjaxComp from "../data/AjaxComp";

class RecapProduitsCommande extends AjaxComp{
    constructor(props){
        super(props) 
        this.app.recap_produits_commande = this
    }
    render(){
        let produits = this.app.gestion_panier.state.panier?.articles
        return (produits && this.app.gestion_panier.state.panier && this.app.gestion_panier.state.panier.panier && this.app.gestion_panier.state.panier.panier.length) 
            ?
                this.app.gestion_panier.state.panier?.panier.map(
                    commande=>{
                        const articles = commande.vente_produits
                        return ( articles.length == 0 
                            ?   this.app.getCurrentLangText("site_text_panier_panier_vide")
                            :   <>
                                    <h3>
                                        {this.app.getCurrentLangText("site_text_votre_commande")} {commande.createur.designation}
                                    </h3>
                                    {articles.map(
                                        article=>{
                                            return <div className="produit-commande">
                                                <div className="illustration">
                                                    <img loading="lazy" src={this.app.gestion_panier.article_image(article)} alt=""/>
                                                </div>
                                                <div className="details">
                                                    <div className="recap-createur info-createur">
                                                        <span className="icon">
                                                            <img loading="lazy" src={article.produit.createur.photo} alt=""/>
                                                        </span>
                                                        <span>
                                                            {article.produit.createur.designation}
                                                        </span>
                                                    </div>
                                                    <div className="titre-article-commande">
                                                        {this.app.gestion_panier.article_name(article)}
                                                    </div>
                                                    <div className="prix-article-commande">
                                                        <span style={article.is_promo?{textDecoration:'line-through',color:'red'}:{}}>
                                                            {this.formatNumber(this.app.gestion_panier.article_price(article))+" "}
                                                            {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''}
                                                        </span> 
                                                        {
                                                            article.is_promo ?
                                                            " "+this.formatNumber(this.app.gestion_panier.article_price(article,true)) + " " + (this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :'')
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                    <div className="quantite-article-commande">
                                                    {this.app.getCurrentLangText("site_text_qte")} : {this.app.gestion_panier.article_quantity(article)}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    )}

                                    <div className="total-commande">
                                        <div className="sepa"></div>
                                        <div className="sous-total info-total-commande">
                                            <div className="label-total">
                                            <span className="text-capitalize">{this.app.getCurrentLangText("site_text_panier_sous_total")}</span> TTC
                                            </div>
                                            {
                                                commande ?
                                                    <div className="valeur">
                                                        {this.formatNumber(this.app.gestion_panier.total_commande(articles))}  {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''} 
                                                    </div>
                                                :   ""
                                            }
                                        </div>
                                        <div className="sepa"></div>
                                        <div className="sous-total info-total-commande">
                                            <div className="label-total">
                                            {this.app.getCurrentLangText("site_text_livraison")}
                                            </div>
                                            <div className="valeur">
                                            {/* {commande && commande.zone_livraison ? <><span className="info">{commande.zone_livraison.montant}</span>  F CFA</>  : "Hors frais de livraison "} */}
                                                <span className="info">{this.formatNumber(commande.total_livraison) ?? 0}</span> {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''}
                                            </div>
                                        </div>
                                        <div className="ligne-total">
                                        {this.app.getCurrentLangText("site_text_panier_total")} : <span className="valeur-total">
                                                {
                                                    commande ?
                                                        <>
                                                            {/* { commande.total + (commande.zone_livraison ? commande.zone_livraison.montant:0)} Xof */}
                                                            {this.formatNumber(this.app.gestion_panier.total_commande(articles) + (commande.total_livraison ?? 0))}  {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''} 
                                                        </>
                                                    :   0
                                                }
                                            </span>
                                        </div>
                                        <div className="finaliser-commande">
                                            <button className="btn" onClick={e=>this.app.gestion_panier.validate(commande)}>
                                            {this.app.getCurrentLangText("site_text_finaliser_ma_commande")}
                                            </button>
                                        </div>
                                    </div>
                                </>
                        ) 
                    }
                )
            : this.app.getCurrentLangText("site_text_recuperation_panier")
        
    }
}
export default RecapProduitsCommande