import AjaxComp from "../../data/AjaxComp";
import Heading from "./heading";
import Heading_mobile from "./heading_mobile";
import Header from "./header";
import SectionCategories from "../sections/section_categories";


class Top extends AjaxComp {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  render() {
    return (
      <>
        <div className="top">

          <div>
            <SectionCategories></SectionCategories>
          </div>
          <div className="Heading_web"><Heading></Heading></div>
          <div className="Heading_mobile"><Heading_mobile></Heading_mobile></div>

        </div>
        {!this.props.noHeader && (
          <Header
            customHeader={
              this.state.customHeader ? this.state.customHeader : null
            }
            title={this.props.title}
            subtitle={this.props.subtitle}
          ></Header>
        )}
      </>
    );
  }
}
export default Top;
