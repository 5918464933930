import AjaxComp from "../data/AjaxComp";
import BaseMinimale from "./base_minimale";
import SearchResults from "../layout/sections/search_results";

class Categories extends AjaxComp {
  constructor(props){
    super(props);
    this.state = {...props};  
  }
  componentDidMount(){
    const params                = new URLSearchParams(window.location.search)
    const page                  = params.get('page')
    const search                = params.get('search')
    const id                    = params.get('id') 
    const createur_id           = params.get('createur_id') 
    const is_promo              = params.get('is_promo') 
    const is_nouveaute          = params.get('is_nouveaute') 
    const is_suggestion         = params.get('is_suggestion') 
    const point_vente_id        = params.get('point_vente_id') 
    const client_id             = params.get('client_id') 
    const stepp                 = params.get('stepp') ?? 0 
    const seachCat              = params.get('seachCat') ?? 0 
    const seachSousCat          = params.get('seachSousCat') ?? 0 

    const currentlang           = this.app.getCurrentLang()
    console.log("ici params", params.get('is_promo') );
    const searchdata            = {createur_id,client_id,id,is_promo,point_vente_id,is_nouveaute,is_suggestion}
    if(currentlang){
      searchdata.lang           = currentlang.diminutif
    }
    this.app.search_results?.search(searchdata,page?page:1,stepp?stepp:0,search,seachCat,seachSousCat)
  }
  render() {
    return <>
          <BaseMinimale
            title={this.app.getCurrentLangText("site_text_le_blog_nahyel")}
            subtitle= {this.app.getCurrentLangText("site_text_opinions_analyses_et_conseils")}
            customHeader="/assets/pictures/bg-blog.jpg"
            noHeader={true}
          >
          <SearchResults/> 
      </BaseMinimale>
    </>
  }
}

export default Categories;
