import axios from 'axios';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';


const {REACT_APP_apiUri} = process.env



function DeviseLangueChange() {

  const [currencies, setCurrencies] = useState([]);
  const [devise, setDevise] = useState();
  const [loading, setLoading] = useState(true);

const apiToken = localStorage.getItem('apiToken')
  
  const apiUri = REACT_APP_apiUri;
  useEffect(() => {
    fetchCurrencies();
  }, []);



  const fetchCurrencies = async () => {
    setLoading(true);
    try {
      const response = await axios.get(apiUri+"/api/devise/all");
      setCurrencies(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching currencies:', error);
    }
  };

  return (
    <>
      <Dropdown>
      <Dropdown.Toggle className='bg-primary mr-2' id="dropdown-basic">
        <span className='text-uppercase'>{this.app.getCurrentLangText('site_text_devise')}</span>
      </Dropdown.Toggle>
      {loading ? (
        <p>{this.app.getCurrentLangText('site_text_chargement_en_cours')} ...</p>
      ) :(
        <Dropdown.Menu>
        {currencies.map((currency) => (
               <Dropdown.Item
                // onClick={() => handleCurrencyClick(currency.id)}
                onClick={() => window.App.setCurrentDevise(currency)}
                key={currency.id}>{currency.designation}
                </Dropdown.Item>
          ))}
      </Dropdown.Menu>
      )}
    </Dropdown>
    </>
  );
}

export default DeviseLangueChange;