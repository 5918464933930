
import AjaxComp from "../data/AjaxComp";
import './commenter_article.css'
class CommentArticle extends AjaxComp {
  commentaire = {
    client_id:null,
    article_id:null,
    commentaire:null
  };
  constructor(props) {
    super(props);
    console.error(props,' my props')
    this.state = { ...props ,commentaire:this.commentaire};
  }
  saveCommentaire(e) {
    e.preventDefault();
    this.setupClientData(
        client=>{
            this.setState({ display_error: null });
            this.commentaire = this.state.commentaire
            this.commentaire.article_id = this.state.article_id ? this.state.article_id : null ;
            this.commentaire.client_id = client.id ? client.id : null ;
            this.commentaire.langue_id = this.app.getCurrentLang().id ;
            
            this.ajaxPost("commentaire_article", this.commentaire, (response) => {
              try {
                if (response.errors) {
                  this.app.showDisplayError(response.errors.toString());
                  // this.setState({ display_error: response.errors.toString() });
                }
              } catch (e) {}
            });
        }
    )
  }
  update(tag,value)
  {
    this.commentaire[tag] = value
    this.updateSafelyState({commentaire:this.commentaire})
    console.log("new state ",this.state)
  }
  render() {
    return !this.isLogged() ? (
      ""
    ) : (
      <div className="commentaireform">
        {this.state.display_error ? this.showDisplayError(this.app.getCurrentLangText("site_text_commentaire")) : ""}
        <form action="" onSubmit={e=>this.saveCommentaire(e)}>
          <div className="bloc-zone bloc_commentaire">
              <div className="commentaire " >
                <textarea name='commentaire' className="formfield input" onChange={e=>{this.update('commentaire',e.target.value)}} placeholder={this.app.getCurrentLangText("site_text_ajouter_un_commentaire_required")}></textarea>
              </div>
              <div className="boutons">
                <button className="button"  onClick={(e) => this.saveCommentaire(e)}>{this.app.getCurrentLangText("site_text_ajouter_un_commentaire")} </button>
              </div>
            </div>
        </form>
      </div>
    );
  }
}

export default CommentArticle;
