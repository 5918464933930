import AjaxComp from "../AjaxComp";

class Gestion_Commande extends AjaxComp {
    
    name = 'gestion_commande'
    getdatatries = 0
    queryname = 'ventes'
    default_conditions = {
        is_panier:false
    }
    attrs_query = [
        "id,commande_etat,mode_paiement{id,designation},color_commande_etat,date_fr,codification,montant_tva,total_ttc,id,created_at,created_at_fr,total_paiement,total,net_a_payer,total_amount,reliquat,total_remise,remise,remisepourcent,left_to_pay,client_id,client{id,nom_complet,email,telephone,adresse{id,designation}},vente_produits{id,vente_id,montant,remise_fournisseur,quantite,total,remise_total,total_sans_remise,valeur_tva,montant_ttc,declinaison_id,declinaison{produit_id,nom_declinaison,produit{code,id,image,designation,declinaison_produits{id}},couleur_id,taille_id,display_text},offert,produit_id,produit{id,createur{designation,photo,id},code,image,designation,prix,prix_devises{prix},prix_vente_final,famille_produit{designation}}},etat,etat_paiement,color_etat_paiement",
        "collisage{id,track_number,date_livraison,vente_id,codification,code,data,commande{id,total,codification,client_id,client{id,nom_complet,telephone}},vente{id,total,codification,client_id,client{id,nom_complet,telephone}}}"
    ]
    data = {
        produits:[],
        total:0
    }

    commandes = []; 

    constructor(props) {
        super(props);
        this.state.gotData = false;
        this.app.gestion_commande = this;
    }

    
    Commandes(){
        return this.state.commandes ? this.state.commandes : []
    }

    setCommandes(cb = () => {}) {
        if (this.state.client) {
            this.default_conditions.client_id = this.state.client.id;
            this._update((data) => {
                if (data.hasOwnProperty('ventes') && Array.isArray(data.ventes)) {
                    this.commandes = data.ventes;
                    this.updateSafelyState({ data: data.ventes, commandes: data.ventes,commande: data.ventes[0], gotData: true });
                    cb(data.ventes);
                } else {
                    this.updateSafelyState({ data: [], gotData: true });
                }
                this.dispatchData();
            });
        }
    }

    
    articles(commande) {
        let articles = [];
        if (this.state.gotData) {
            if (commande) {
                articles = commande.vente_produits;
            }
        }
        return articles;
    }

    total(commande) {
        let total = 0;
        if (this.state.gotData) {
            if (commande) {
                total = commande.total;
            }
        }
        return total;
    }

    remisepourcent(){
        let total_ttc = 0
        if(this.state.gotData){
            if(this.state.commande){
                total_ttc = this.state.commande.total_ttc
            }
        }
        return total_ttc
    }
    remise(){
        let remise = 0
        if(this.state.gotData){
            if(this.state.commande){
                remise = this.state.commande.remise
            }
        }
        return remise
    }
    total_remise(){
        let total_remise = 0
        if(this.state.gotData){
            if(this.state.commande){
                total_remise = this.state.commande.total_remise
            }
        }
        return total_remise
    }
    total_ttc(){
        let total_ttc = 0
        if(this.state.gotData){
            if(this.state.commande){
                total_ttc = this.state.commande.total_ttc
            }
        }
        return total_ttc
    }
    tva(){
        let tva = 0
        if(this.state.gotData){
            if(this.state.commande){
                tva = this.state.commande.montant_tva
            }
        }
        return tva
    }


    dispatchData(){

        
    }
    getCart(cb=()=>{}){

        if(this.state.client){
            this.default_conditions.client_id = this.state.client.id
            this._update(data=>{
                if(data.hasOwnProperty('ventes')){
                    cb(data.ventes[0])
                    this.data = data.ventes
                    this.updateSafelyState({data:data.ventes,commande:data.ventes[0],gotData:true})
                }else{
                    this.updateSafelyState({data:[],gotData:true})
                }
                this.dispatchData()
            })
        }
    }
    
    selectCommande(commande) {
        this.updateSafelyState({ commande });
        this.dispatchData();
    }
    
}

export default Gestion_Commande;