import AjaxComp from "../../data/AjaxComp";
import Panier from "../../pages/panier";
import AdressePanier from "../../pages/adresse_panier";
import Loader from "../../Loader";

class ZoneStatutPaiement extends AjaxComp {

    path_params         = this.suffixe+'/statutpaiement/:transaction_id'
    transaction_id      = null

    constructor(props){
        super(props)
        this.state = {...props}
        this.state.statut_paiement = null
        this.setRouteParams()
        if(this.getRouteParams()){
            this.transaction_id = this.getRouteParams().params.transaction_id
            this.state={...this.state,transaction_id:this.transaction_id}
            this.getDetails()
        }
    }
    getDetails(){
        const {transaction_id} = this.state;
        console.log("ici la transaction_id",transaction_id);
        // alert(transaction_id)
        if(transaction_id){
            this.ajaxPost('panier/statutpaiement',{transaction_id,langue_id: this.app.getCurrentLang().id},statut_paiement=>{
                console.warn("got statut_paiement ",statut_paiement)
                this.updateSafelyState({statut_paiement})
            })
        }
    }

    
    render(){
        const   {statut_paiement}   = this.state
        let     [errors,success,retour]    = [null,null,null]
        let     message             = this.app.getCurrentLangText('site_text_le_paiement_renseigne_nexiste_pas_ou_a_ete_annule') ? this.app.getCurrentLangText('site_text_le_paiement_renseigne_nexiste_pas_ou_a_ete_annule') : "Le paiement renseigné n'existe pas ou a été annulé. Si vous croyez que c'est une erreur, veuillez contacter le service technique ou informer les entités adéquates."
        if(statut_paiement)
        {
            console.warn("got statut_paiement ici",statut_paiement)

            errors              =   statut_paiement.errors;
            success             =   statut_paiement.success;
            retour              =   statut_paiement.message;
            message             =   success ? (success ? success : retour) : message ;
        }
        if(errors)
        {
            console.warn(errors)
        }

        console.log("message papa",message)
        return <div style={{display:'grid',alignItems:'center',justifyItems:'center',padding:"var(--block-spacing)"}}>
                {/* {statut_paiement  */}
                {statut_paiement 
                    ? 
                        <div style={{textAlign:'center'}}>
                            { message }
                        </div>
                    : 
                        <Loader loading={true} /> 
                }
                        
        </div>
    }


}

export default ZoneStatutPaiement