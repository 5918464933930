import AjaxComp from "../../data/AjaxComp";
import Base from "../../pages/base";
import ProduitAccroche from "../containers/listes/produit-accroche";
import "./banniere.css"

class Banniere extends AjaxComp{
    constructor(props){
        super(props)
        this.state = {...props};
    }
    componentDidMount()
    {
        this.setState({ mounted: true });
        if(!this.state.banniere)
        {
            this.customGraphql('bannieres',{activer:1,langue_id:this.app.getCurrentLang().id},['id,designation,description,lien,image'],data=>{
                if(data.errors){
                    console.log('erreur bannieres data ,',data.errors)
                }
                else
                {
                this.updateSafelyState({data})
                }
                 // console.log("ici le this.state apres", this.state.banniere, data);
            })
        }
    }
    render(){
        //const {banniere}   = this.state;
        let banniere = this.state.data?.bannieres[0]
        banniere     = banniere ? banniere : []
        return <>
            
            {
                banniere 
                    ?  
                        <a id="banniere" href={((banniere.lien) ? ((banniere.lien.startsWith("http")?"":"http://")+banniere.lien) : "#")} target={banniere.lien?"_blank":""}>
                            <img
                                loading="lazy"
                                // src={this.apiUri+"/"+banniere.image}
                                src={banniere.image}
                                alt={banniere.designation ? banniere.nom_langue : ""}
                                className="image"
                            />
                            {/* <div className="texte">
                                <h3>
                                    {banniere.nom_langue}
                                </h3>
                                <p>
                                    {banniere.description}
                                </p>
                            </div> */}
                        </a>
                    : ""
            }
            
        </>
    }
}

export default Banniere
