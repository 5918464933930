import AjaxComp from "../data/AjaxComp";
import Recommendations from "../layout/zones/recommendations";
import BlocDescriptionProduit from "../layout/zones/bloc-description-produit";
import ZoneDetailsProduit from "../layout/zones/details-produit";

import Base from "./base";
import Loader from "../Loader";
import { toHaveClass } from "@testing-library/jest-dom/matchers";

class DetailsProduit extends AjaxComp {
    path_params = this.suffixe+'/details-produit/:id'
    produit_id = null
    queryname   = 'produits'
    attrs_query = ['id','image','sur_commande','description','description_langue','prix','prix_devises{prix}','designation','nom_langue','details{designation,valeur}','produit_complementaires{id,produit_id,produit_complementaite{id,designation,image,prix,declinaisons{id,taille_id,couleur_id,couleur{id,nom},declinaisons{id,taille{id,nom},taille_id,couleur_id}}}}','notes_avis{note,avis,created_at,client{id,nom_complet,email}}','createur{id,designation,photo,couverture,nbr_produits},images{id,src},declinaisons{id,produit_id,nom_declinaison,display_text,quantity,couleur_id,couleur{id,nom,description,code_couleur},declinaison_id,declinaisons{id,produit_id,nom_declinaison,display_text,quantity,declinaison_id,taille_id,taille{id,nom,description},produit{id,designation,prix}}}']
    default_conditions = {
        website:true,
        for_website:true,langue_id:this.app.getCurrentLang().id
    }
    state = {setrecent:true}
    setRecent(){
        if(this.app.state.client_id)
        {
            this.ajaxPost(
                'favorisclient',{produit_id:this.produit_id,is_visite:true,client_id:this.app.state.client_id,langue_id: this.app.getCurrentLang().id},data=>{
                    this.setState({setrecent:false})
                }
            )
        }
    }
    componentDidMount(){
        this.setState({mounted:true})
        console.log("this.state papa",this.state.produit_id)
    }
    constructor(props){
        super(props)
        this.state = {...props,load:true}
        this.setRouteParams()
        if(this.getRouteParams())
        {
            this.updateSafelyState({produit_id:parseInt(this.getRouteParams().params.id)})
        }
    }
    getProduit()
    {
        return this.state.produit
    }
    render(){   
        return  <>
                    <Base noHeader={true}>
                        {
                            // (this.state.produit) 
                            // ?  
                                <>
                                    <ZoneDetailsProduit id={this.state.produit_id}/>
                                    <BlocDescriptionProduit id={this.state.produit_id}/>
                                    <Recommendations id={this.state.produit_id}/>
                                </>
                            // :
                            // <Loader loading={!this.state.produit} />     
                        }   
                    </Base>
                </>
    }


}

export default DetailsProduit