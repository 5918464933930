
import AjaxComp from "../data/AjaxComp";

class Panier extends AjaxComp {
  constructor(props) {
    super(props);

    this.state = { ...props };
    this.app.details_panier = this;
  }
  onValidatePanier(response) {
    if (response.errors) {
      this.updateSafelyState({ display_error: response.errors.toString() });
    }
    if (response.message) {
      this.updateSafelyState({ display_message: response.message.toString() });
    }
    this.app.gestion_panier.getIt();
  }
  render() {
    return (
      <div className="section-commande current" id="panier">
        {this.state.display_message
          ? this.showDisplayMessage(this.app.getCurrentLangText("site_text_valider_commande"))
          : ""}
        {this.state.display_error
          ? this.showDisplayError(this.app.getCurrentLangText("site_text_erreur_lors_de_la_validation"))
          : ""}
        <div className="articles-panier-container">
        <table className="articles-panier">
          
            {this.app.gestion_panier.state.panier ? (
              this.app.gestion_panier.state.panier.vente_produits &&
              this.app.gestion_panier.state.panier.vente_produits.length ? (
                this.app.gestion_panier.state.panier?.panier.map(
                  commande=>{
                    return (commande.vente_produits && commande.vente_produits.length) ? <>
                      <thead>
                        <tr style={{background:'#f5f5f5'}}>
                          <td colSpan="4">
                            <div className="text-center">
                              <h3>
                                 {this.app.getCurrentLangText("site_text_commande_chez")} 
                                 {commande.createur.designation}
                              </h3>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center text-capitalize">{this.app.getCurrentLangText("site_text_description")}</td>
                          <td className="text-center">{this.app.getCurrentLangText("site_text_prix_unitaire")}</td>
                          <td className="text-center">{this.app.getCurrentLangText("site_text_quantite")}</td>
                          <td className="text-center">{this.app.getCurrentLangText("site_text_panier_total")} </td>
                        </tr>
                      </thead>
                      <tbody>

                        
                        {
                          commande.vente_produits.map((article, idx) => {
                            return <>
                                      
                              <tr key={idx}>
                                <td className="description-article-panier text-center">
                                  <div className="illustration">
                                    <img loading="lazy"
                                      src={article.produit.image}
                                      alt=""
                                    />
                                  </div>
                                  <div className="details">
                                    <div className="createur">
                                      <div className="icon">
                                        <img loading="lazy"
                                          src={this.apiUri+"/"+article.produit.createur.photo}
                                          alt=""
                                        />
                                      </div>
                                      <h4>{article.produit.createur.designation}</h4>
                                    </div>
                                    <div className="designation">
                                      {this.app.gestion_panier.article_name(article)}
                                    </div>
                                  </div>
                                </td>
                                <td className='text-center'>
                                  <span style={article.is_promo?{textDecoration:'line-through',color:'red'}:{}}>
                                    {this.formatNumber(this.app.gestion_panier.article_price(article))+" "}
                                    {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''}
                                  </span> 
                                  {
                                    article.is_promo ?
                                    " "+this.formatNumber(this.app.gestion_panier.article_price(article,true)) + " " + (this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :'')
                                    :
                                      ''
                                  }
                                </td>
                                <td className='text-center'>
                                  <div className="number-input">
                                    <button
                                      onClick={(e) => {
                                        let input =
                                          e.target.parentNode.querySelector(
                                            "input[type=number]"
                                          );
                                        if (input) {
                                          input.stepDown();
                                          this.app.gestion_panier.addToCart(
                                            article.declinaison.id,
                                            input.value
                                          );
                                        }
                                      }}
                                    >
                                      -
                                    </button>
                                    <input
                                      className="quantity"
                                      min="1"
                                      name="quantity"
                                      value={this.app.gestion_panier.article_quantity(
                                        article
                                      )}
                                      type="number"
                                      placeholder="quantité"
                                    />
                                    <button
                                      onClick={(e) => {
                                        let input =
                                          e.target.parentNode.querySelector(
                                            "input[type=number]"
                                          );
                                        if (input) {
                                          input.stepUp();
                                          this.app.gestion_panier.addToCart(
                                            article.declinaison.id,
                                            input.value
                                          );
                                        }
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                </td>
                                <td className='text-center'>
                                  <div className="remove_product_container">
                                    <div>
                                      {this.formatNumber(this.app.gestion_panier.article_price(article,article.is_promo) * this.app.gestion_panier.article_quantity(article))} {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''}
                                    </div>
                                    <div className="remove">
                                      <img loading="lazy"
                                        onClick={(e) =>
                                          this.app.gestion_panier.delFromCart(
                                            article.declinaison.id
                                          )
                                        }
                                        src="/assets/pictures/croix.svg"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            
                            </>
                          })
                        }

                      </tbody>
                    </> : ""
                  }
                )
              ) : (
                <tr>
                  <td>{this.app.getCurrentLangText("site_text_panier_panier_vide")} </td>
                </tr>
              )
            ) : (
              <tr>
                <td>{this.app.getCurrentLangText("site_text_recuperation_panier_en_cours")} </td>
              </tr>
            )}
          
        </table>
        </div>
        <div className="recap-panier">
          <button className="btn-gradient" onClick={e=>this.goTo(this.suffixe+"/categories")}>
            {this.app.getCurrentLangText("site_text_continuer_mes_achats")}
          </button>
          {
          this.app.gestion_panier.state.panier ? (
            this.app.gestion_panier.state.panier?.panier?.map(
            commande=>{
                const id  = "adresse-commande-active"+commande.id
                const label = "adresse-commande-active-tab"+commande.id
                return commande.vente_produits &&
                commande.vente_produits.length ? (
                  <div className="recap">
                    <h3>
                    {this.app.getCurrentLangText("site_text_recapitulatif_de_commande_chez")}  
                    <strong> {commande.createur.designation}</strong>
                    </h3>
                    <div className="sepa"></div>
                    <div className="info-recap">
                      <div className="label-info"> <span className="text-capitalize">{this.app.getCurrentLangText("site_text_panier_sous_total")} </span>  TTC</div>
                      <div className="valeur-info">

                      {this.formatNumber(this.app.gestion_panier.total_commande(commande.vente_produits)) }  {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''} 
                      </div>
                    </div>
                    <div className="sepa"></div>
                    <div className="info-recap">
                      <div className="label-info">{this.app.getCurrentLangText("site_text_livraison")}</div>
                      {/* <div className="valeur-info"> {this.app.gestion_panier.state.montant_current_zone ? (this.app.gestion_panier.state.montant_current_zone + " Xof") : "Hors frais de livraison"}</div> */}
                      <div className="valeur-info"> {(commande.total_livraison ?? 0)} {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''}</div>
                      
                    </div>
                    <div className="total-recap">
                      <h3>
                      {this.formatNumber(this.app.gestion_panier.total_commande(commande.vente_produits) + (commande.total_livraison ?? 0))}  {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''} 
                        {/* { commande.total + this.app.gestion_panier.state.montant_current_zone} Xof */}
                      </h3>
                    </div>
                    <button className="btn" onClick={e=>this.app.gestion_panier.validate(commande)}>
                    {this.app.getCurrentLangText("site_text_finaliser_ma_commande")} 
                    </button>
                  </div>
                ) : (
                  <p>{this.app.getCurrentLangText("site_text_panier_panier_vide")}</p>
                )
            })
          ) : (
            <p>{this.app.getCurrentLangText("site_text_recuperation_panier_en_cours")}</p>
          )}
        </div>
      </div>
    );
  }
}

export default Panier;
