import AjaxComp from "../data/AjaxComp";
import SectionCategories from "../layout/sections/section_categories";
import SectionLogin from "../layout/sections/section_login";
import SectionRegister from "../layout/sections/section_register";
import Top from "../layout/top/top";
import HorizontalScrollCategories from "../layout/zones/scroll-categories";
import Stats from "../layout/zones/stats";
import FeedBacks from "../layout/zones/feedbacks";
import WhatsappChat from "../layout/extra/whatsappchat";
import Bottom from "../layout/bottom/bottom";
import SectionPartenaire from "../layout/sections/section_partenaire";
import SectionContact from "../layout/sections/section_contact";
import SectionRemboursement from "../layout/sections/section_remboursement";
import SectionAPropos from "../layout/sections/section_apropos";
import SectionCpl from "../layout/sections/section_infoscpl";
import Banniere from "../layout/sections/banniere";



class BaseMinimale extends AjaxComp {
  constructor(props) {
    super(props);
    this.state = { children: [], ...props };
    if (props.children) {
      this.state = { children: Array.from(props.children), ...this.state };
    }
  }

  render() {
    const { children } = this.state;
    return (
      <>
        <section className="wrap">
          <Banniere/>
          <SectionContact/>
          <SectionPartenaire />
          <SectionRemboursement/>
          <SectionCpl></SectionCpl>
          <SectionAPropos/>
          <SectionLogin />
          <SectionRegister />
          <Top
            noHeader={this.props.noHeader}
            title={this.props.title}
            subtitle={this.props.subtitle}
            customHeader={
              this.state.customHeader ? this.state.customHeader : null
            }
          />
          <div className={"page"+(this.props.noHeader?' noheader':'')}>
            {typeof children == "string" || typeof children == "object"
              ? children
              : children.map((Child, key) => {
                  return Child;
                })}
          </div>
          <WhatsappChat />
          <Bottom />
        </section>
      </>
    );
  }
}

export default BaseMinimale;
