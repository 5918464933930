import { Link } from "react-router-dom";
import AjaxComp from "../../data/AjaxComp";
import FocusSlider from "../containers/sliders/FocusSlider";
import LabelZone from "../extra/labelZone";
import SliderMiseAvant from "../containers/sliders/sliderMise";
class PromoCreateurs extends AjaxComp {
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.ajaxGet(
      'promoscreateur',data=>{
        const slides = []
        for(let i=1;i<6;i++){
          slides.push(
            {key:i,content:<img loading="lazy" onClick={e=>this.goTo(this.suffixe+"/categories?createur_id="+3)} src={"/assets/design_by/design_by_"+i+'.jpeg'}/>}
          )
        }
        this.updateSafelyState({slides})
      }
    )
  }
  render() {
    const {slides} = this.state
    return (slides && slides.length) ? (
      <div className="zone" id="promo-createurs">
        <LabelZone label={this.app.getCurrentLangText("site_text_design_by_nahyel") ? this.app.getCurrentLangText("site_text_design_by_nahyel") : "Design by Nahyel"} ></LabelZone>
        <FocusSlider autoplay={true} slides={this.state.slides} />
      </div>
    ) : "";
  }
}
export default PromoCreateurs;
