import AjaxComp from "../../data/AjaxComp";

import { Eye, Copy, Truck, CreditCard } from 'react-feather';

class ResumeCommande extends AjaxComp {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            selectedCommande: null,
        };
    }

    openModal = (commande) => {
        this.setState({
            isModalOpen: true,
            selectedCommande: commande,
        });
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false,
            selectedCommande: null,
        });
    };

    displayCommande = (commande) => {
        this.openModal(commande);
    }

    repasserCommande = (commande) => {
        // Implement the logic to copy (repass) the command here
        // You can use the selectedCommande object
    }

    render() {
        const { commande } = this.props;

        return (
            <div key={commande.id} style={styles.container}>
                <h3 style={styles.title} className={"badge badge-"+commande.color_commande_etat}>
                    {/* Commande CMD{commande.id} du {commande.date_fr} */}
                    {commande.codification} du {commande.date_fr}
                </h3>
                <div className="row">
                    <div style={styles.details} className=" col-md-12">
                        {/* <p><strong>Client:</strong> {commande.client.nom_complet}</p> */}
                        {/* <p><strong>Téléphone:</strong> {commande.client.telephone}</p> */}
                        {/* <p><strong>Email:</strong> {commande.client.email}</p> */}
                        <p class='d-flex justify-content-between'>
                            <strong className={"my-auto "}> {commande.total_ttc} FCFA </strong>
                            <span  className={"my-auto badge badge-"+commande.color_etat_paiement}>
                                {commande.etat_paiement}
                            </span>
                        </p>
                        {/* <p><strong>Total à payer:</strong> {commande.net_a_payer} FCFA</p> */}
                        
                        {/* <p><strong>Montant TVA:</strong> {commande.montant_tva} FCFA</p> */}
                        {/* <p><strong>Total remise:</strong> {commande.total_remise} FCFA</p> */}
                        {/* <p><strong>Remise:</strong> {commande.remise} FCFA</p> */}
                        {/* <p><strong>Remise en pourcentage:</strong> {commande.remisepourcent}%</p> */}
                  
                        {/* <p><strong>Reliquat:</strong> {commande.reliquat} FCFA</p> */}
                        {/* <p>{commande.date_fr}</p> */}
                        {/* <p><strong>Date :</strong> {commande.created_at_fr}</p> */}
                        <p><strong>{commande.vente_produits.length} {this.app.getCurrentLangText("site_text_produits")}</strong></p>
                        <hr/>
                    </div>
                </div>
                <div style={styles.actionBar}>
                    <Eye onClick={() => this.displayCommande(commande)} style={styles.icon} />
                    {commande.left_to_pay > 0 && <CreditCard onClick={() => this.app.gestion_panier.onlinePay(commande,commande.mode_paiement)} style={styles.icon} />}
                    {/* <Copy onClick={() => this.repasserCommande(commande)} style={styles.icon} /> */}
                    {
                        commande.collisage ? <Truck onClick={() => this.goTo(this.suffixe+"/livraison/"+commande.collisage.track_number)} style={styles.icon} /> : ""
                    }
                </div>
                <div className="progress mx-auto col-md-11 p-0" style={{display:'grid',gridTemplateColumns:'repeat(4,25%)'}}>
                    <div className="progress-bar bg-success" role="progressbar" style={{ display:'grid',alignItems:'center',width:'100%',height: '100%' }}  aria-valuemax="100">25%</div>
                    <div className="progress-bar bg-warning" role="progressbar" style={{ display:commande.commande_etat >= 1 ? 'grid' : 'none',alignItems:'center',width: commande.commande_etat>= 1 ? '100%' : '0%',height: '100%' }}  aria-valuemax="100">50%</div>
                    <div className="progress-bar bg-info" role="progressbar" style={{ display:commande.commande_etat >= 2 ? 'grid' : 'none',alignItems:'center',width: commande.commande_etat>= 2 ? '100%' : '0%',height: '100%' }}  aria-valuemax="100">75%</div>
                    <div className="progress-bar bg-primary" role="progressbar" style={{ display:commande.commande_etat >= 4 ? 'grid' : 'none',alignItems:'center',width: commande.commande_etat>= 4 ? '100%' : '0%',height: '100%' }}  aria-valuemax="100">100%</div>
                </div>

                {
                    this.state.isModalOpen 
                    && (
                        <div style={styles.modal}>
                            <div style={styles.modalContent}>
                                <div style={styles.modalHeadLine} className=" material">
                                    <h2 className="text-center text-white m-0 p-0">{this.app.getCurrentLangText("site_text_commande")}  {this.state.selectedCommande.codification}</h2>
                                    <span style={styles.close} onClick={this.closeModal}>&times;</span>
                                </div>
                                <div style={styles.modalBody}>
                                    <div className="row w-100">
                                        <div className="col-md-4">
                                            <p><strong>{this.app.getCurrentLangText("site_text_client")} : </strong> {this.state.selectedCommande.client.nom_complet}</p>
                                            <p><strong>{this.app.getCurrentLangText("site_text_email")} : </strong> {this.state.selectedCommande.client.email}</p>
                                            <p><strong>{this.app.getCurrentLangText("site_text_total_ttc")} : </strong> {this.state.selectedCommande.total_ttc} FCFA</p>
                                            <p><strong>{this.app.getCurrentLangText("site_text_total_a_payer")} : </strong> {this.state.selectedCommande.net_a_payer} FCFA</p>
                                            <p><strong>{this.app.getCurrentLangText("site_text_montant_tva")} : </strong> {this.state.selectedCommande.montant_tva} FCFA</p>
                                            <p><strong>{this.app.getCurrentLangText("site_text_total_remise")} : </strong> {this.state.selectedCommande.total_remise} FCFA</p>
                                            <p><strong>{this.app.getCurrentLangText("site_text_remise")} : </strong> {this.state.selectedCommande.remise} FCFA</p>
                                            <p><strong>{this.app.getCurrentLangText("site_text_remise_en_pourcentage")} : </strong> {this.state.selectedCommande.remisepourcent}%</p>
                                            <p><strong>{this.app.getCurrentLangText("site_text_reliquat")} : </strong> {this.state.selectedCommande.reliquat} FCFA</p>
                                            <p><strong>{this.app.getCurrentLangText("site_text_creation_at")} : </strong> {this.state.selectedCommande.created_at_fr}</p>
                                        </div>
                                        <div className="col-md-7">    
                                            <h3>Articles</h3>
                                            <table className='table'>
                                                <tr className="tr">
                                                    {/* <td className="td text-center align-middle">
                                                        ID
                                                    </td> */}
                                                    <td className="td text-center align-middle">
                                                        {this.app.getCurrentLangText("site_text_article")}
                                                    </td>
                                                    <td className="td text-center align-middle">
                                                        {this.app.getCurrentLangText("site_text_montant")}
                                                    </td>
                                                    <td className="td text-center align-middle">
                                                        {this.app.getCurrentLangText("site_text_quantite")}
                                                    </td>
                                                    <td className="td text-center align-middle">
                                                        {this.app.getCurrentLangText("site_text_total")}
                                                    </td>
                                                </tr>
                                                {this.state.selectedCommande.vente_produits.map((article) =>{
                                                    return <tr className="tr" key={article.id} style={styles.article}>
                                                        {/* <td className="td text-center align-middle">{article.id}</td> */}
                                                        <td className="td text-center align-middle">
                                                            {article.produit.nom_langue}
                                                            <img loading="lazy" src={article.produit.image} alt={article.produit.nom_langue} style={styles.image} />
                                                        </td>
                                                        <td className="td text-center align-middle">{article.montant} FCFA</td>
                                                        <td className="td text-center align-middle">{article.quantite}</td>
                                                        <td className="td text-center align-middle">{article.total} FCFA</td>
                                                    </tr>
                                                })}
                                                <tr className="tr">
                                                    <td className="td text-center align-middle">
                                                        {this.app.getCurrentLangText("site_text_total")}
                                                    </td>
                                                    <td className="td text-center align-middle">
                                                    </td>
                                                    <td className="td text-center align-middle">
                                                    </td>
                                                    <td className="td text-center align-middle">
                                                    </td>
                                                    <td className="td text-center align-middle">
                                                        {commande.total}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="progress mt-3 col-md-1  p-0" style={{display:'grid',gridTemplateRows:'repeat(4,25%)',height:'50vh'}}>
                                            <div className="progress-bar bg-success" role="progressbar" style={{ display:'grid',alignItems:'center',height:'100%',width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{this.app.getCurrentLangText("site_text_commande_recue")}</div>
                                            <div className="progress-bar bg-warning" role="progressbar" style={{ display:commande.commande_etat >= 1 ? 'grid' : 'none',alignItems:'center',height: commande.commande_etat>= 1 ? '100%' : '0%',width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{this.app.getCurrentLangText("site_text_en_cours_de_traitement")}</div>
                                            <div className="progress-bar bg-info" role="progressbar" style={{ display:commande.commande_etat >= 2 ? 'grid' : 'none',alignItems:'center',height: commande.commande_etat>= 2 ? '100%' : '0%',width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{this.app.getCurrentLangText("site_text_en_cours_de_livraison")}</div>
                                            <div className="progress-bar bg-primary" role="progressbar" style={{ display:commande.commande_etat >= 4 ? 'grid' : 'none',alignItems:'center',height: commande.commande_etat>= 4 ? '100%' : '0%',width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{this.app.getCurrentLangText("site_text_livre")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

const styles = {
    container: {
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '10px',
        // margin: '10px',
        maxWidth: '300px', // Adjust the width as needed
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    modalBody:{
        display:"grid",
        padding: '20px',
        gridTemplateColumns:"100%"
    },
    title: {
        fontSize: '1.1em',
        margin: '0',
    },
    details: {
        marginTop: '10px',
        position:'relative'
    },
    actionBar: {
        marginTop: '10px',
    },
    icon: {
        cursor: 'pointer',
        padding:"5px",
        margin:"8px",
        width:"32px",
        height:"32px",
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    modal: {
        display: 'block',
        position: 'fixed',
        zIndex: 10,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    modalContent: {
        backgroundColor: '#fefefe',
        margin: 'auto',
        border: '1px solid #888',
        width: '80%',
        height: '80%',
        borderRadius: '5px',
        marginTop:"5%",
        position:"relative",
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        boxShadow: 'box-shadow: 4.0px 8.0px 8.0px rgba(200,200,200)'
    },
    modalHeadLine: {
        display:"grid",
        width:"100%",
        color: 'white',
        alignItems:"center",
        gridTemplateColumns:"calc(100% - 40px) 40px"
    },
    close: {
        float: 'right',
        fontSize: '40px',
        cursor: 'pointer',
    },
    image:{
        width:'100%'
    }
};

export default ResumeCommande;
