import AjaxComp from "../../data/AjaxComp";

class MultiPages extends AjaxComp{
    constructor(props){
        super(props)
        this.state = {...props}
        this.state.currentPage = 0
        this.state.shownext = true
        this.state.showprevious = false
        this.state.beforeBox = null
        this.state.afterBox = null
        if(!this.state.pages){
            this.state.pages = []
            if(props.children){
                this.state.pages = props.children
            }
        }
    }
    hideBeforeAndAfter(){
        this.setState(

            {beforeBox:null,afterBox:null}
        )
    }
    showPrevious(){
        this.setState({showprevious : true})
    }
    showNext(){
        this.setState({shownext : true})
    }
    hidePrevious(){
        this.setState({showprevious : false})
    }
    hideNext(){
        this.setState({shownext : false})
    }
    handleNextPage(){
        this.hideBeforeAndAfter()
        if(this.state.currentPage < this.state.pages.length - 1){
            this.setState({currentPage : this.state.currentPage + 1,custombuttontext:false,custombutton:false})
        }
        this.setState({showprevious:true})
    
    }
    handlePreviousPage(){
        this.hideBeforeAndAfter()
        if(this.state.currentPage > 0){
            this.setState({currentPage : this.state.currentPage - 1,showprevious:true,custombuttontext:false,custombutton:false})
        }
        this.setState({shownext:true})
    }
    handleGoToPage(page){
        if(page < 0 || page >= this.state.pages.length - 1) return
        this.setState({currentPage : page})
    }
    render(){
        return (
            <div className='multipage p-4'  style={{width:'50vw',maxHeight:'80vh',display:'flex',flexDirection:'column',justifyContent:'center',height:'100%',background:'#fff'}}>
                {
                    this.state.beforeBox ? this.state.beforeBox : ''
                }
                <div className='content' style={{maxHeight:'70vh',overflowY:'scroll'}}>
                    {this.state.pages.map((page,index)=>{
                        if(this.state.currentPage == index){
                            return <div key={index}>{page(this)}</div>
                        }
                    })}
                </div>
                {
                    this.state.afterBox ? this.state.afterBox : ''
                }
                <div className='navigate d-flex'>  
                    {
                        (this.state.showprevious && this.state.currentPage > 0) && (
                            <button style={{cursor:'pointer',background:'white !important',width:'100%',maxWidth:'100px'}} className="p-1 left-navigate" onClick={e=>this.handlePreviousPage(e)}>
                                <img style={{transform:'rotate(-90deg)',width:"14px"}} src="/assets/pictures/fleche-couleur.svg" alt="" />
                            </button>
                        )
                    }
                    {
                        (this.state.shownext && this.state.pages.length > 1 && this.state.pages.length-1 > this.state.currentPage) && (
                            <button style={{cursor:'pointer',maxWidth:'150px'}} className="d-flex align-items-center justify-content-center p-1 ml-1" onClick={e=>this.handleNextPage(e)}>
                                {this.app.getCurrentLangText("site_text_suivant") ? this.app.getCurrentLangText("site_text_suivant") : 'Suivant'}
                                <img className='ml-4' style={{transform:'rotate(90deg)',width:"14px"}} src="/assets/pictures/fleche.svg" alt="" />
                            </button>
                        )
                    }
                    {
                        (this.state.custombutton && this.state.custombuttontext) ? (
                            <button style={{cursor:'pointer',maxWidth:'150px'}} className="d-flex align-items-center justify-content-center p-1 ml-1" onClick={(e)=>(this.state.custombuttonaction ? this.state.custombuttonaction(e) : ()=>{})}>
                                {this.state.custombuttontext}
                            </button>

                        ) : ''
                    }
                </div>
            </div>
        )
    }
}
export default MultiPages