import { Link } from "react-router-dom";
import Loader from "../../Loader";
import AjaxComp from "../../data/AjaxComp";


class ListeCoupsdCoeur extends AjaxComp {


    constructor(props){
        super(props)
        this.state = {...props,history:null}
        
      
    }
    componentDidMount()
    {
        this.customGraphql(
            'selections',{status:true,langue_id:this.app.getCurrentLang().id},['id','image','titre','titre_langue','description', 'description_langue'],(data)=>{
                if(!data.errors){
                    this.setState({data})
                    this.forceUpdate()
                }
                else{
                    console.info('issues ...'+data.errors)
                }
            }
        )
    }
    Collection(selection,key){
        return <div className="collection overlaybox" key={key}>
            <img loading="lazy" src={selection.image} alt="" className="overlay"/>
            <div className="content">
                <h1 className="label-collection">
                    {selection.titre_langue}
                </h1>
                <p className="description-collection">
                    
                    {selection.description_langue}
                
                </p>
            </div>
        </div>
    }
    render(){
        // alert('here'+JSON.stringify(this.state.data))
        const load = !this.state.data || (this.state.data && !this.state.data.selections)
        return   (load) ?  <Loader loading={load} /> : (
                    this.state.data?.selections.length
                ?
                    <div className="zoneFull" id="selection">
                        <div className="container_selection">
                            <h2 className="label-selection">
                                <span className="line text-uppercase">&#10072;</span> {this.app.getCurrentLangText("site_text_nos_coups_de_coeur") ? this.app.getCurrentLangText("site_text_nos_coups_de_coeur") : 'NOS COUPS DE COEUR DU MOIS'}
                            </h2>
                            <div className="liste-collections">
                                {
                                    this.state.data?.selections.map(
                                        (collection,key)=>{
                                            return this.Collection(collection,key)
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                : ""
        ) 
    }


}

export default ListeCoupsdCoeur