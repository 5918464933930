import AjaxComp from "../../data/AjaxComp";
import ListSlider from "../containers/sliders/ListSlider";
import DetailsCreateur from "../extra/details-createur";
import DonneesPanierDetailsProduit from "../extra/donnees-panier-details-produit";
import "./details-produit.css";
import Loader from "../../Loader";
import React from "react";
// import { produit } from "../../myseed";
class DetailsProduit extends AjaxComp {
  produit_id  = null
  queryname   = 'produits'
  attrs_query = ['id','image','sur_commande','description','description_langue','nom_langue','prix','prix_devises{prix},prix_promo,pourcentage_promo,is_promo,is_nouveaute,stock_global,promos{id,pourcentage}','designation','details{designation,valeur}','produit_complementaires{id,produit_id,produit_complementaire{id,designation,image,prix,declinaisons{id,taille_id,couleur_id,couleur{id,nom},declinaisons{id,taille{id,nom},taille_id,couleur_id}}}}','notes_avis{note,avis,created_at,client{id,nom_complet,email}}','createur{id,designation,photo,couverture,nbr_produits},images{id,src},declinaisons{id,produit_id,nom_declinaison,display_text,quantity,couleur_id,couleur{id,nom,description,code_couleur},declinaison_id,declinaisons{id,produit_id,nom_declinaison,display_text,quantity,declinaison_id,taille_id,taille{id,nom,description},produit{id,designation,prix}}}']
  default_conditions = {
      website:true,for_website:true
  }
  constructor(props) {
    super(props);
    this.app.zone_details_produit = this;
    this.state = {...props};  
    
    const {id} = props
    this.produit_id = id;
    console.log('this.produit_id papa 2',this.state, id)

  }
  componentDidMount(){
        console.log('this.produit_id papa',this.state)
        this.produit_id = this.state.id
        this.getProduits()
  }

  getProduits = async () =>
  {
    console.log('this.state.produit_id papa',this.state);
    if(this.state)
    {
      this.customGraphql
      (
          'produits',
          {
            website:true,for_website:true,id:this.produit_id,langue_id:this.app.getCurrentLang().id
          },
          this.attrs_query,
          (produits)=>{
              if(produits.produits && produits.produits.length>0){
                  console.log(produits,'as produits detail')
                  this.setState({produit:produits.produits[0]})
                  this.forceUpdate()
              }
              else
              {
                  console.info('probleme detail produit')
              }
          }
      )
    }
  }

  render() {
  
    const {produit} = this.state;
    // const pictures = produit?.images
    const imgs = produit?.images.map(img=>{
      img.src = this.apiUri+'/'+img.src
      return img
    });
    let pictures =[]

    if(this.state.data && this.state.data.produits && this.state.data.produits.length)
      {
          this.state.data.produits.forEach(
            (dataCat)=>{
              if(dataCat.id == this.createur_id)
              {
                produit = dataCat;
              }
            }
          )
      }

    if(produit)
    {
      pictures = [{src:(produit.image&&!produit.image.match('upload.jpg')?produit.image:produit.createur.photo).replace(this.apiUri,'').replace('//','/')}]
    }
    console.info(pictures,'as pictures there ')
    pictures = (imgs && imgs.length) ? imgs : pictures
    
    console.log("woyyyy",pictures,produit)
    return (
      (produit && produit.createur) ?
      // (produit) ?
      <div className="zone details-produit">
        <div className="les_images">
          <div className="details_createur_mobile">
            <DetailsCreateur
              createur={
                produit.createur
              }
            />
            <div className="donnees-panier">
              <div className="info-produit">
                <div className="nom-article-like">
                  <div className="nom-article">{produit.nom_langue}</div>
                  <label
                    className="like-wrapper cursor-pointer mt-3"
                    htmlFor={`favori_product+${produit.id}`}
                  >
                    <input
                      type="hidden"
                      name=""
                      id={`favori_product+${produit.id}`}
                    />
                    <svg
                      className="beatonhover"
                      onClick={e=>this.app.isFavoriClient(produit.id)?this.app.removeFavoriClient(produit.id):this.app.addFavoriClient(produit.id)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="27"
                      viewBox="0 0 30 27"
                      fill={this.app.isFavoriClient(produit.id)?'#E1C564':"#1113"}
                    >
                      <path
                        d="M15 4.90001C15.6 3.90001 16.3 3.10001 17.2 2.40001C18.8 1.30001 20.5 0.900005 22.4 1.20001C25.3 1.70001 27.6 3.90001 28.1 6.70001C28.5 8.50001 28.2 10.3 27.4 12C26.9 13.2 26.1 14.2 25.2 15.2C23.8 16.8 22.1 18.1 20.6 19.5C18.8 21.1 17.1 22.8 15.7 24.7C15.5 25 15.3 25.2 15.1 25.5C15 25.7 14.9 25.7 14.8 25.5C13.1 23 10.9 21 8.7 19C7.3 17.8 5.8 16.5 4.6 15.1C3.3 13.6 2.3 12 1.9 10.1C1.6 8.50001 1.6 7.00001 2.2 5.50001C2.8 4.10001 3.9 2.80001 5.4 2.00001C7.2 1.00001 9.2 0.900005 11.1 1.60001C12.6 2.10001 13.8 3.10001 14.6 4.50001C14.8 4.60001 14.9 4.80001 15 4.90001Z"
                        stroke={this.app.isFavoriClient(produit.id)?'silver':"white"}
                        stroke-width="1"
                        stroke-miterlimit="10"
                      />
                    </svg>
                  </label>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="27"
                    viewBox="0 0 30 27"
                    fill="none"
                  >
                    <path
                      d="M15 4.90001C15.6 3.90001 16.3 3.10001 17.2 2.40001C18.8 1.30001 20.5 0.900005 22.4 1.20001C25.3 1.70001 27.6 3.90001 28.1 6.70001C28.5 8.50001 28.2 10.3 27.4 12C26.9 13.2 26.1 14.2 25.2 15.2C23.8 16.8 22.1 18.1 20.6 19.5C18.8 21.1 17.1 22.8 15.7 24.7C15.5 25 15.3 25.2 15.1 25.5C15 25.7 14.9 25.7 14.8 25.5C13.1 23 10.9 21 8.7 19C7.3 17.8 5.8 16.5 4.6 15.1C3.3 13.6 2.3 12 1.9 10.1C1.6 8.50001 1.6 7.00001 2.2 5.50001C2.8 4.10001 3.9 2.80001 5.4 2.00001C7.2 1.00001 9.2 0.900005 11.1 1.60001C12.6 2.10001 13.8 3.10001 14.6 4.50001C14.8 4.60001 14.9 4.80001 15 4.90001Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-miterlimit="10"
                    />
                  </svg> */}
                </div>
                <div className="prix-produit">
                      
                  <span style={produit.is_promo ? {textDecoration:'line-through',color:'red'}:{}}>
                    {this.formatNumber(this.app.getCurrentPrice(produit))}
                    {produit.is_promo && this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''}
                  </span>
                  {
                    produit.is_promo ? 
                      <span style={{marginInline:'var(--base-spacing)'}}> 
                        {this.formatNumber(this.app.getCurrentPrice(produit,true))}
                      </span> 
                    :
                      ''
                  }
                  {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''}
                </div>
              </div>
            </div>
          </div>
          <ListSlider pictures={pictures} />
        </div>

        <div className="infos">
          <div className="details_createur_web">
            <DetailsCreateur
              createur={
                produit.createur
              }
            />
            
          </div>
          <div className="produit">
            <DonneesPanierDetailsProduit produit_id={produit.id} produit={produit}/>

          </div>
        </div>
      </div>
      : <Loader loading={!this.state.produit} />
    );
  }
}

export default DetailsProduit;
