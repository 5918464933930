import AjaxComp from "../../data/AjaxComp";



class ZoneLostPassword extends AjaxComp{

    componentDidMount() {
        this.setState({mounted:true})
        if(this.isLogged() && this.app.state?.client && !this.app.state.client?.visitor){
            this.goTo(this.suffixe+"/compte");
        }else{
            const resetToken = localStorage.getItem('resetToken')
            if(resetToken){
                this.setState({resetToken})
                this.forceUpdate()
                document.title = this.app.getCurrentLangText("site_text_reinitialiser_votre_mot_de_passe");
            }
            else
            {
                document.title = this.app.getCurrentLangText("site_text_mot_de_passe_oublie");
            }
        }
    }
    
    constructor(props){
        super(props)
        this.state = {...props}
        this.state.data = {email:null}
    }

    requestChangePassword(e){
        e.preventDefault()
        const form = new FormData(e.target) 
        const email = form.get('email')
        if(email){
            this.ajaxPost(
                'client/lostpassword',{email,langue_id: this.app.getCurrentLang().id},response=>{
                    if(response.message){
                        this.setDisplayMessage(response.message)
                        this.forceUpdate()
                        document.querySelector('#lostpassemail').value=""
                    }
                    if(response.errors){
                        this.app.setDisplayError(response.errors)
                        this.forceUpdate()
                        console.error(this.state.display_error) 
                    }
                }
            )
        }
    }

    changePassword(e){
        e.preventDefault()
        const form = new FormData(e.target) 
        const email = form.get('email')
        const password = form.get('password')
        if(email && password){
            this.ajaxPost(
                'client/resetpassword',{email,password,resetToken:this.state.resetToken,_token:this.state.resetToken,langue_id: this.app.getCurrentLang().id},response=>{
                    if(response.message){
                        this.setDisplayMessage(response.message)
                        this.forceUpdate()
                        document.querySelector('#lostpassemail').value=""
                        document.querySelector('#lostpasspassword').value=""
                        localStorage.removeItem('resetToken')
                    }
                    if(response.errors){
                        this.app.setDisplayError(response.errors)
                        this.forceUpdate()
                        console.error(this.state.display_error) 
                    }
                }
            )
        }
    }
    updateEmail(e){
        this.updateSafelyState({data:{email:e.target.value}})
    }
    updatePassword(e){
        this.updateSafelyState({data:{password:e.target.value}})
    }
    



    render(){
        if(this.isLogged() && this.app.state?.client && !this.app.state?.client?.visitor){
            this.goTo(this.suffixe+"/compte");
        }
        return <div className="zone" style={{display:'grid',position:'relative',justifyItems:"center"}}>
            <h1>
                {this.app.getCurrentLangText("site_text_mot_de_passe_oublie")} ?
            </h1>
            <div className="form" >
                {
                    (this.state.display_message ? this.showDisplayMessage(this.app.getCurrentLangText('site_text_mot_de_passe')) : '')
                }
                {
                    (this.state.display_error ? this.showDisplayError(this.app.getCurrentLangText('site_text_mot_de_passe')) : '')
                    // this.showDisplayMessage()
                }
                <form onSubmit={e=>this.state.resetToken?this.changePassword(e):this.requestChangePassword(e)}>
                    <div className="form-field" style={{display:'grid'}}>
                        <label htmlFor='email' style={{padding:'4px'}}>
                            { this.state.resetToken ? this.app.getCurrentLangText('site_text_adresse_email') : this.app.getCurrentLangText("site_text_renseignez_votre_email")}
                        </label>
                        <input className="form-input" placeholder={this.app.getCurrentLangText("site_text_adresse_email")} required={true} id='lostpassemail' name='email' type="email" style={{padding:'12px',margin:"4px"}} onKeyUp={e=>this.updateEmail(e)} onChange={e=>this.updateEmail(e)}/>
                    </div>
                    {
                        this.state.resetToken ?
                            <div className="form-field" style={{display:'grid'}}>
                                <label htmlFor='password' style={{padding:'4px'}}> 
                                    {this.app.getCurrentLangText("site_text_votre_nouveau_mot_de_passe")}
                                </label>
                                <input className="form-input" placeholder={this.app.getCurrentLangText("site_text_votre_nouveau_mot_de_passe")} type="password" required={true} id='lostpasspassword' name='password' style={{padding:'12px',margin:"4px"}} onKeyUp={e=>this.updatePassword(e)} onChange={e=>this.updatePassword(e)}/>
                            </div>
                        :
                            <></>
                    }

                    <div className="form-field" style={{display:'grid',justifyItems:"center"}}>
                        {/* <button onClick={e=>this.requestChangePassword(e)} style={{width:'32%',padding:'12px',margin:'8px'}}> */}
                        <button  style={{width:'32%',padding:'12px',margin:'8px'}}>
                            {this.app.getCurrentLangText("site_text_envoyer")} 
                        </button>
                    </div>
                </form>
            </div>  
        </div>
    }
}
export default ZoneLostPassword