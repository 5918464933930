document.addEventListener('click', function(event) {
    const targetElement = event.target;
    // Vérifier si l'élément cliqué a un id contenant le mot 'toggle'
    if (!targetElement.id.includes('toggle') && !targetElement.classList.contains('splide__arrownext')) {
      // Sélectionner tous les éléments qui ont la classe 'visible'
      const visibleElements = document.querySelectorAll('.navbar_header_top .visible');
      // Retirer la classe 'visible' de tous ces éléments
      visibleElements.forEach(el => {
        el.classList.remove('visible');
      });
    }
  });