import { Delete } from "react-feather";
import AjaxComp from "./AjaxComp";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';

class RemoveDataType extends AjaxComp{
    constructor(props){
        super(props)
        this.state = {...props}
    }
    doDelete(){
        const {id,type,onDeleted,confirmtext} = this.state
        const data = {id,type}
        // if(id && type && window.confirm(confirmtext ? confirmtext : this.app.getCurrentLangText("site_text_vous_voulez_vraiment_vous_supprimer"))){
        //     this.ajaxDelete(
        //         type+'/'+id,{},response=>{
        //             if(!response.errors){
        //                 if(typeof onDeleted == 'function'){
        //                     onDeleted()
        //                 }
        //             }
        //         }
        //     )
        // }
        let self = this;
        if(id && type)
        {
            iziToast.question({
                timeout: 0,
                close: false,
                overlay: true,
                displayMode: 'once',
                id: 'question',
                zindex: 999,
                title: 'Suppression',
                message: confirmtext ? confirmtext : (self.app.getCurrentLangText("site_text_vous_voulez_vraiment_vous_supprimer") ? self.app.getCurrentLangText("site_text_vous_voulez_vraiment_vous_supprimer") : "Voulez vous vraiment effectuer cette suppression ?"),
                position: 'center',
                buttons: [
                    ['<button class="font-bold btn btn-success" style="color: green!important">Confirmer</button>', function (instance, toast) {
    
                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
    
                        self.ajaxDelete(
                            type+'/'+id,{},response=>{
                                if(!response.errors){
                                    if(typeof onDeleted == 'function'){
                                        onDeleted()
                                    }
                                }
                            }
                        )
    
                    }, true],
                    ['<button class="btn btn-danger" style="color: red!important">Annuler</button>', function (instance, toast) {
    
                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
    
                    }],
                ],
                onClosing: function (instance, toast, closedBy) {
                    console.log('Closing | closedBy: ' + closedBy);
                },
                onClosed: function (instance, toast, closedBy) {
                    console.log('Closed | closedBy: ' + closedBy);
                }
            });
        }
    }
    render(){
        return <span className="icon">
            <Delete onClick={e=>this.doDelete()}/>
        </span>
    }


}
export default RemoveDataType