import AjaxComp from "../../data/AjaxComp";
import ListeProduitsAccroche from "../containers/listes/liste-produits-accroche";
import LabelZone from "../extra/labelZone";
class AccrocheSuggestions extends AjaxComp {
  default_multiple = true;
  default_conditions = [{ is_favori: true ,for_website:true}];
  querynames = [["produits"]];
  attrs_queries = [
    [
      [
        "id",
        "image",
        "designation",
        "nom_langue",
        "sur_commande",
        "images{id,src}",
        "createur{id,designation,photo},prix,prix_devises{prix},prix_promo,pourcentage_promo,is_promo,is_nouveaute,stock_global",
      ],
    ],
  ];

  constructor(props) {
    super(props);
    this.state = { ...props };
    this.app.accroche_suggestions = this;
  }
  render() {
    return (
      <div className="zone" id="accroche-suggestions">
        <LabelZone label={this.app.getCurrentLangText("site_text_suggestions")} voirPlus={this.suffixe+"/categorie?is_favori=true"}></LabelZone>
        <ListeProduitsAccroche isfor={"suggestions"}></ListeProduitsAccroche>
        {/* <ListeProduitsAccroche isfor={"produits"}></ListeProduitsAccroche> */}
      </div>
    );
  }
}

export default AccrocheSuggestions;
