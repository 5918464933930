import { Link } from "react-router-dom";
import AjaxComp from "../../../data/AjaxComp";
import Loader from "../../../Loader";

class ListeAccroches extends AjaxComp {
  
  accroches = [
    {
        link:this.suffixe+'/categories?meilleur_vente=true&is_nahyel=true',
        designation:this.app.getCurrentLangText("site_text_meilleures_ventes") ? this.app.getCurrentLangText("site_text_meilleures_ventes") : 'MEILLEURES VENTES',
        image:'/assets/pictures/meilleur_vente.jpg'
    },
    {
        link:this.suffixe+'/categories?is_nouveaute=true&is_nahyel=true',
        designation:this.app.getCurrentLangText("site_text_nouveautes") ? this.app.getCurrentLangText("site_text_nouveautes") : 'NOUVEAUTES',
        image:'/assets/pictures/nouveaute.jpg'
    },
    {
        link:this.suffixe+'/categories?is_suggestion=true&is_nahyel=true',
        designation: this.app.getCurrentLangText("site_text_suggestions") ? this.app.getCurrentLangText("site_text_suggestions") : 'SUGGESTIONS',
        image:'/assets/pictures/suggestion.jpg'
    },
    {
        link:this.suffixe+'/categories?is_promo=true&is_nahyel=true',
        designation:this.app.getCurrentLangText("site_text_promos") ? this.app.getCurrentLangText("site_text_promos") +' %' : 'PROMOS  %',
        image:'/assets/pictures/promo.jpg'
    }
  ]
  constructor(props){
    super(props);
    this.state = {...props};  
  }

  componentDidMount()
  {
      this.setState({ mounted: true });
      if(this.state)
      {
        this.app.getCurrentLangText("site_text_meilleures_ventes") 
      }
      
  }
 
  render() {
    
    
    return (
      <>
        <div className="zone liste-decouvertes">
          
            {
                this.accroches.map((accroche, idx) => {
                    return (
                        <a href={accroche.link} key={idx} className="decouverte overlaybox" style={{maxWidth:"30%"}}>
                            <img loading="lazy" src={accroche.image} alt="" className="overlay" />
                            <div className="content">
                              <div className="label-decouverte text-uppercase">{accroche.designation}</div>
                            </div>
                        </a>
                    );
                })
            }
        </div>      
      </>
    );
  }
}

export default ListeAccroches;
