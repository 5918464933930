import AjaxComp from "../data/AjaxComp";
import BaseMinimale from "./base_minimale";
import LabelZone from "../layout/extra/labelZone";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import Blog from "../layout/sections/blog";

class HomeCreateur extends AjaxComp {
  render() {
    return <>
          <BaseMinimale
            title={this.app.getCurrentLangText("site_text_le_blog_nahyel")}
            subtitle= {this.app.getCurrentLangText("site_text_opinions_analyses_et_conseils")}
            customHeader="/assets/pictures/bg-blog.jpg"
            noHeader={true}
          >
          <Blog/> 
      </BaseMinimale>
    </>
  }
}

export default HomeCreateur;
