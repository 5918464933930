
import { Link } from "react-router-dom";
import AjaxComp from "../../data/AjaxComp";

class SearchPagination extends AjaxComp{
    constructor(props){
        super(props)
        this.state = {...props}
        if(this.app.search_results && this.app.search_results.state && this.app.search_results.state.searchdata  && this.app.search_results.state.searchdata.metadata){
            
            const {current_page,last_page,per_page,total}               = this.app.search_results.state.searchdata.metadata
            const pagination                                            = {current_page,last_page,per_page,total}
            this.state = {...this.state,...pagination}
        
        }
    }
    paginationElem(elem){
        
        let current_page   =   0
        if(this.app.search_results.state && this.app.search_results.state.searchdata  && this.app.search_results.state.searchdata.metadata)
        {
            current_page               = this.app.search_results.state.searchdata.metadata.current_page
        }
        const style = {
            // display:'flex',
            // justifyContent:'center',
            // color:"#FFF",
            // cursor:'pointer',
            // borderRadius:'var(--base-spacing)',
            // padding:'var(--base-spacing)',
            // margin:'calc(var(--base-spacing)/2)',
            // marginBlock:'var(--base-spacing)',
            // background: "repeat center/contain url('/assets/pictures/bois.png')"

            background: '#ffffff',
            width: '20px',
            height: '20px',
            borderRadius: '3px',
            color: 'gray',
            margin: '0 3px',
            lineHeight: '20px',
            textAlign: 'center',
            cursor: 'pointer',
        }
        if(elem == current_page){
            style.background                    = "#cc9b6a"
            style.color                         = "#fff"
            // style.background                    = "#eee"
            // style.border                        = "2px solid #333"
            // style.color                         = "#333"
        }
        this.state.searchdata = {...this.state.searchdata,...this.state.searchdata.metadata}
        return <span style={style} key={elem} onClick={e=>{this.app.search_results.search(this.state.searchdata,elem) && window.history.pushState({page:elem},document.title+' - page '+elem,'',this.app.setUrlParam(document.location.href,'page',elem)) && alert('passed')}}>{elem}</span>
        // return <a href={this.app.setUrlParam(document.location.href,'page',elem)} style={style} key={elem}>{elem}</a>
    }
    paginationElems(){
        let last_page   =   0
        if(this.app.search_results.state && this.app.search_results.state.searchdata  && this.app.search_results.state.searchdata.metadata)
        {
            last_page               = this.app.search_results.state.searchdata.metadata.last_page
        }
        const elems = []
        for(let i = 1 ; i <= last_page ; i++){
            if(i!=0 && ( ((i/10)==1) || ((i/10)==10) || ((i/10)==100)) && ((i%10) == 0)){
                elems.push(
                    <span style={{width:'100%'}}/>
                )
            }
            if(i<11){
                elems.push(
                    this.paginationElem(i)
                )
            }else{
                if((i%10) == 0){
                    elems.push(
                        this.paginationElem(i)
                    )
                }
            }
        }
        return elems
    }
    render(){
        let total = 0
        if(this.app.search_results.state && this.app.search_results.state.searchdata  && this.app.search_results.state.searchdata.metadata)
        {
            total               = this.app.search_results.state.searchdata.metadata.total
        }
        return total ? 
            <div style={{display:'flex',flexWrap:"wrap",justifyContent:'center',padding:'var(--base-spacing)'}}>{
                this.paginationElems().map(
                    (elem)=>{
                        return elem
                    }
                )
            } </div> : ""
        
    }
}

export default SearchPagination