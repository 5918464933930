import AjaxComp from "../../data/AjaxComp";
import ScrollCategories from "../containers/scroll-categories"

class HorizontalScrollCategories extends AjaxComp {

    render (){
        return <div className="zone scroll-categories">
            <ScrollCategories></ScrollCategories>
        </div>
    }
}
export default HorizontalScrollCategories