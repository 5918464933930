import AjaxComp from "../../data/AjaxComp";

class FormPicture extends AjaxComp {
  constructor(props) {
    super(props);
    this.state = {
      children: [],
      ...props,
    };
    if (props.children) {
      this.state = { children: props.children };
    }
  }
  render() {
    const { children } = this.state;

    return (
      <>
        <div className="d-flex cmb_container">
          <div
            class="w-100 d-flex justify-content-end"
            onClick={(e) => this.switchPopupElement(e, "#section-partenaire")}
          >
            <svg
              class="svg-inline--fa fa-xmark text-danger"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="xmark"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              ></path>
            </svg>
          </div>
          <div style={style.picture} className="img_container">
            <img
              loading="lazy"
              src="/assets/pictures/form-picture.png"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </div>
          <div className="bl_content">
            {typeof children == "string" || typeof children == "object"
              ? children
              : children.map((Child, key) => {
                  return Child;
                })}
          </div>
        </div>
      </>
    );
  }
}
const style = {
  picture: {
    objectFit: "cover",
    maxWidth: "50%",
    height: "100% !important",
  },
  container: {
    borderRadius: ".5rem !important",
  },
};
export default FormPicture;
