import AjaxComp from "../../data/AjaxComp";
import LoginForm from "../../forms/login";

class SectionLogin extends AjaxComp {


    render(){

        return (

                <div id="section-login">
                    <LoginForm></LoginForm>
                </div>
    
        )
    }
}
export default SectionLogin