import AjaxComp from "../../data/AjaxComp";
import LabelZone from "../extra/labelZone";
import Temoignages from "../sections/temoignages";
import "./feedbacks.css";
class FeedBacks extends AjaxComp {
  emailNewsletter = null;
  constructor(props) {
    super(props);
    console.log(props,' my props')
    this.state = { ...props };
  }


  componentDidMount() {

    this.setState({ mounted: true });
    // if(this.state && this.state.currentSection == 'mon-compte')
    if(this.state)
    {
      console.log("ici voir => ",this.state.currentSection);
      this.app.getCurrentLangText("site_text_sincrire_a_la_newsletter");
      this.app.getCurrentLangText("site_text_adresse_email");
      //this.switchSection("mon-compte");
    }
  }

  registerToNewsletter(){
    if(this.emailNewsletter){
      this.ajaxPost('newsletter',{email:this.emailNewsletter,langue_id: this.app.getCurrentLang().id},response=>{
        console.error("reponse enregistrement newsletter ",response)
        document.querySelector('.newsletterinput input').value = ""
      })
    }
  }
  render() {
    return <>
      
        <Temoignages/>

        {(this.state ? 
          <div className="newsletterinput-wrapper">
          <div className="newsletterinput-wrapper-label">
            { this.app.getCurrentLangText("site_text_sincrire_a_la_newsletter") ? this.app.getCurrentLangText("site_text_sincrire_a_la_newsletter") : "S'incrire à la newsletter"}
          </div>
          <div className="newsletterinput">
              <input type="email" placeholder={this.app.getCurrentLangText("site_text_adresse_email") ? this.app.getCurrentLangText("site_text_adresse_email") : "Adresse email ..."}  onChange={e=>this.emailNewsletter=e.target.value}/>
              <div className="newsletter_btn">
                <img loading="lazy" src="/assets/pictures/send.svg" alt="" onClick={e=>this.registerToNewsletter()}/>
              </div>
          </div>
        </div> : '' )}
      
    </>
  }
}

export default FeedBacks;
