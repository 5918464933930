import React from 'react';
import AjaxComp from '../../data/AjaxComp';

class CplComponent extends  AjaxComp {
    constructor(props){
        super(props)
        this.state = {...props}
        this.state.validate = false
    }
    valider(value=true){
        this.setState({validate : value})
        if(this.state.parent){
            this.state.parent[(value?'show':'hide')+'Next']()
        }
    }
  render() {
    return (
      <div style={{display:'grid',width:'75%',margin:'auto',alignItems:'center',justifyItems:'center',padding:'1rem',background:'white'}}>
        <h1>{this.app.getCurrentLangText("site_text_commande_paiement_livraison")}</h1>
      
        <div className="texte" style={{padding:"4rem",height:"70vh",overflowY:'scroll'}}>
          {/*  */}
          <div className="texte">
            <h2>
              {this.app.getCurrentLangText("site_text_commande")}
            </h2>
            <p>
              {this.app.getCurrentLangText("site_text_toute_personne_physique_et_majeure_disposant_dun_compte_utilisateur_sur")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_il_est_a_noter_que_des_notifications_par_mail")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_note_les_informations_utilisateurs")}
              <ul>
                <li>{this.app.getCurrentLangText("site_text_adresse_de_livraison")}</li>
                <li>{this.app.getCurrentLangText("site_text_moyen_de_paiement_mode_de_paiement")}</li>
              </ul>
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_de_plus_l_acheteur")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_nous_recommandons_au_acheteur")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_nahel_se_reserve_le_droit_d_annuler_toute_commande")}
            </p>
          </div>

          {/*  */}
          <div className="texte">
            <h2>
              {this.app.getCurrentLangText("site_text_paiement")}
            </h2>
            <p>
              {this.app.getCurrentLangText("site_text_le_paiement_s_effectue_l_acceptation_de_la_commande_par_le_vendeur")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_au_cas_qu_un_solde_disponible_sur_le_moyen_de_paiement_indique")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_dans_ces_cas_nahel_se_reserve_le_droit_de_contacter_par_le_vendeur")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_les_moyens_de_paiement_pris_en_charge")}
            </p>
          </div>
          
          {/*  */}
          <div className="texte">
              {/*  */}
            <h2>
              {this.app.getCurrentLangText("site_text_livraison")}
            </h2>
            <p>
              {this.app.getCurrentLangText("site_text_le_processus_de_livraison")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_la_plateforme_nayhelconceptstore_travaille_en_collaboration")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_dans_le_cas_des_commandes_groupees")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_les_frais_de_livraison_a_la_charge")}
            </p>
            <p>
              {this.app.getCurrentLangText("site_text_il_est_a_noter_que_nahyel_pratique_le_paiement_des_frais_de_douane")}
            </p>
          </div>
        </div>

        <div className="buttonbox mt-4">
          <button className='' onClick={e=>this.switchPopupElement(e,'#section-cpl')} style={{padding:"1rem"}} id="toggle-section-cpl">
            {this.app.getCurrentLangText("site_text_fermer")}
          </button>
        </div>
      </div>
    );
  }
}

export default CplComponent;