import AjaxComp from "../../data/AjaxComp";
import "./header.css";
import FocusSlider from "../containers/sliders/FocusSlider";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import SimpleImageSlider from "react-simple-image-slider";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
class Header extends AjaxComp {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    const slides=[]
    for(let i=1;i<4;i++){
      slides.push(
        // {key:i,content:<img loading="lazy" onClick={e=>this.goTo(this.suffixe+"/categories?is_promo=true&createur_id="+1)} src={"/assets/promo/"+i+'.jpeg'}/>}
        {key:i,url:"/assets/pictures/slide_head_"+i+'.png'}
      )
    }
    return (
      <header>
        {this.state.customHeader ? (
          <img loading="lazy" className="custom-header" src={this.state.customHeader} alt="" />
        ) : (
          <>
            {/* <SimpleImageSlider
              width={"100%"}
              height={"100%"}
              images={slides}
              autoPlay={true}
              autoPlayDelay={2}
              // showBullets={true}
              // showNavs={true}
              // navStyle={2}
            /> */}
            <Carousel useKeyboardArrows={true} showThumbs={false} dynamicHeight={true} height={"100%"} showArrows={false} autoPlay={true}>
              {slides.map(({url, key}) => (
                <div className="slide">
                  <img alt="sample_file" src={url} key={key} />
                </div>
              ))}
            </Carousel>
          </>
          // <video loading="lazy" muted className="custom-header" loop={true} autoPlay={true}>
          //   <source src="/assets/pictures/header.mp4" type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"'></source>
          // </video>
          // <video className="custom-header" src="/assets/header.mp4" alt="" autoplay="true"></video>
        )}
        <div className="content">
          <h1 className="headline">{this.app.getCurrentLangText(this.props.title)}</h1>
          <div className="subline">{this.app.getCurrentLangText(this.props.subtitle)}</div>
        </div>
        <div
          className="scroll"
          onClick={(e) => {
            window.App.scrollToBottom();
          }}
        >
          Scroll
          <img loading="lazy" src="/assets/pictures/scroll.svg" alt="" />
        </div>
      </header>
    );
  }
}

export default Header;
