
import AjaxComp from "../../data/AjaxComp";
import Produit_accroche from "../containers/listes/produit-accroche";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import ListeProduitsAccroche from "../containers/listes/liste-produits-accroche";
import LabelZone from "../extra/labelZone";
import { Link } from "react-router-dom";

class CategorieCreateur extends AjaxComp {
  perPage = 4;

  constructor(props) {
    super(props);
    this.state = { ...props };
    this.state.page = 0;
    this.state.pageCount = this.state.produits?.length / this.perPage;
    this.paginate(1);
    // this.customGraphql('produits',{categorie_id:this.state.categorie_id,website:true,for_website:true,langue_id:this.app.getCurrentLang().id},["id,image,designation,sur_commande,prix,prix_devises{prix},images{id,src},createur_id,createur{id,designation,photo}"],data=>{
    //   console.info('got categorie produit data',data)
    // })
  }
  goToPage(idx) {
    const page = idx < this.state.pageCount ? idx : this.state.page;
    this.state.page = page;
    this.paginate();
  }
  nextPage() {
    const page =
      this.state.page + 1 < this.state.pageCount
        ? this.state.page + 1
        : this.state.page;
    this.state.page = page;
    this.paginate();
  }
  previousPage() {
    const page =
      this.state.page - 1 >= 0 ? this.state.page - 1 : this.state.page;
    this.state.page = page;
    this.paginate();
  }
  pagination() {
    const pages = [];
    for (let i = 0; i < this.state.pageCount; i++) {
      pages.push(
        <span
          onClick={(e) => this.goToPage(i)}
          className={`pagination-page ${this.state.page == i ? "current" : ""}`}
        >
          {i + 1}
        </span>
      );
    }
    return (
      <div className="pagination">
        <div
          className="before pagination-control"
          onClick={(e) => this.previousPage()}
        >
          {"<"}
        </div>
        <div className="pages">{pages}</div>
        <div
          className="after pagination-control"
          onClick={(e) => this.nextPage()}
        >
          {">"}
        </div>
      </div>
    );
  }
  paginate(init = false) {
    let page = 0;
    let totalpaginated = 0;
    const pages = [];

    while (page < this.state.pageCount) {
      let paginated = 0;
      pages[page] = [];
      while (
        paginated < this.perPage &&
        totalpaginated != this.state.produits.length
      ) {
        pages[page][paginated] = this.state.produits[totalpaginated];
        totalpaginated++;
        paginated++;
      }
      page++;
    }
    const currentProduits =
      this.state.page < this.state.pageCount ? pages[this.state.page] : [];
    if (init) {
      this.state = {
        ...this.state,
        pageCount: this.state.pageCount,
        currentProduits,
      };
    } else {
      this.setState({
        ...this.state,
        pageCount: this.state.pageCount,
        currentProduits,
      });
    }
  }
  render() {
    console.warn('cat crea ',this.state)
    const createur_id  = (this.state.produits && this.state.produits.length) ? this.state.produits[0].createur_id : null
    // const {createur_id} = this.state
    const {designation} = this.state
    return (
      (this.state.produits && this.state.produits.length) ? <div className="zone categorie">
        {
          (createur_id)
            ? <Link to={this.suffixe+"/categories?createur_id="+createur_id+(designation?'&search='+designation:'')}>
                <LabelZone label={designation ? designation : this.app.getCurrentLangText('site_text_titre_de_la_categorie')} number_of_article={this.state.count} voirPlus={this.suffixe+"/categories?createur_id="+createur_id+(designation?'&search='+designation:'')}></LabelZone>
              </Link>
            : <LabelZone label={designation ? designation : this.app.getCurrentLangText('site_text_titre_de_la_categorie')} number_of_article={this.state.count} voirPlus={this.suffixe+"/categories?createur_id="+createur_id+(designation?'&search='+designation:'')}></LabelZone>
        }

        <ListeProduitsAccroche isfor={"produits"} produits={this.state.produits ?? []}></ListeProduitsAccroche>
        
      </div> : ""
    ) ;
  }
}

export default CategorieCreateur;
