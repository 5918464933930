import AjaxComp from "../data/AjaxComp";
import BaseMinimale from "./base_minimale";
import ZoneDetailsCreateur from "../layout/zones/details-createur";
import HorizontalScrollCategories from "../layout/zones/scroll-categories";
import ProduitAccroche from "../layout/containers/listes/produit-accroche";

class Categories extends AjaxComp {
  default_multiple = true;
  default_conditions = [];
  querynames = [];
  attrs_queries = [
    
  ];
  
  concerned = {
    categorie:{name:'categories',attrs:[
      "id,nom_langue,designation,image,enfants{id,designation,nom_langue,image,enfants{id,designation,nom_langue,image}}"
    ]},
    produits:{name:'produits',attrs:["id,image,sur_demande,images{id,src},createur{id,designation,photo},mis_en_avant,website,code,designation,nom_langue,designation_en,prix,prix_devises{prix},current_quantity"]},
  }

  constructor(props) {
    super(props);
    const metadata = {current_page:1,per_page:40}
    this.state = { ...props ,v:this.currentView(),metadata}; 
    this.queryStuff()
    
    this._update(
      data=>{
        if(this.state.mounted){
          this.forceUpdate()
        }
      }
    )
  }

  componentDidMount() {
    document.title = this.app.getCurrentLangText("site_text_accueil_categorie");
    this.setState({ mounted: true });
    this.app.voirplus = this
  }
  currentView(){
    const queryParams = new URLSearchParams(window.location.search);
    // Access the 'v' parameter
    const v = queryParams.get('v');
    return v
  }
  nextPage(){
    const {metadata} = this.state
    metadata.current_page += (metadata.last_page > metadata.current_page) ? 1 : 0 
    this.updateSafelyState({metadata})
    if(this.currentView() === 'categorie'){
      this.getCategorieProduits()
    }
  }
  previousPage(){
    const {metadata} = this.state
    metadata.current_page -= (metadata.current_page > 1) ? 1 : 0 
    this.updateSafelyState({metadata})
    if(this.currentView() === 'categorie'){
      this.getCategorieProduits()
    }
  }
  getCategorieProduits(id){
    const {metadata} = this.state 
    console.warn(metadata)
    const categorie_id = id ? id : this.state.categorie_id 
    this.updateSafelyState({categorie_id})
    this.customPaginatedGraphql('produits',{categorie_id,website:true,for_website:true},this.concerned.produits.attrs,metadata.current_page,metadata.per_page,data=>{
      
      if(data['produitspaginated'].metadata){
        this.updateSafelyState({metadata:data['produitspaginated'].metadata})
      }
      if(data['produitspaginated'].data){
        const produits = data['produitspaginated'].data
        this.updateSafelyState({produits})
      }
      let intervar = null
      intervar = setInterval(
        ()=>{
          
          if(this.state.mounted){
            this.forceUpdate()
            clearInterval(intervar)
          }
        },1500
      )
    })
  }
  queryStuff(){
    Object.keys(this.concerned).forEach(
      key=>{
        if(key === this.currentView()){
          this.querynames.push(this.concerned[key].name)
          this.attrs_queries.push(this.concerned[key].attrs)
          if(key === 'produits'){
            this.default_conditions.push({website:true,for_website:true})
            this.default_conditions.push({langue_id:this.app.getCurrentLang() ? this.app.getCurrentLang().id : 1})
          }
          if(key === 'categorie'){
            this.path_params = this.suffixe+'/categorie/:id'
            this.setRouteParams()
            const categorie_id = this.getRouteParams().params.id
            this.updateSafelyState({categorie_id})
            this.default_conditions.push({id:categorie_id})
            this.default_conditions.push({is_on_website:true,website:true})
            this.default_conditions.push({langue_id:this.app.getCurrentLang() ? this.app.getCurrentLang().id : 1})
            this.getCategorieProduits(categorie_id)
          }
        }
      }
    )
  }

  render() {
    
    const { metadata } = this.state;

    // Generate an array of page numbers
    const pageNumbers = Array.from({ length: metadata.last_page }, (_, index) => index + 1);
    
    let {produits} = this.state 
    if(!produits){
      produits = []
    }
    const {data} = this.state
    let {categories} = data?data:{};
    if(!categories){
      categories = []
    }
    console.warn(categories)
    const {createurs} = this.state.data ? this.state.data : {};
    return (
      <BaseMinimale noHeader={true} >
        <>
          {produits.map((produit, idx) => (
            <ProduitAccroche
              produit={produit}
              app={this.app}
              key={idx}
            ></ProduitAccroche>
          ))}
          
          <HorizontalScrollCategories />
          
          {categories.map((categorie, idx) => (
            <div className="tableau_catégorie" key={idx}>
              <div>{categorie.nom_langue}</div>
            </div>
          ))}
        </>
      </BaseMinimale>
    );

  }
}

export default Categories;
