import AjaxComp from "./AjaxComp";
import './AddAdresse.css'
class AddAdresse extends AjaxComp{
    constructor(props){
        super(props)
        this.updateSafelyState(props)
    }
    setDesignation(e){
        this.setState({designation:e.target.value})
        this.setState({adresse:e.target.value})
    }
    setCodePostal(e){
        this.setState({code_postal:e.target.value})
    }
    setVille(e){
        this.setState({ville:e.target.value})
    }
    setNomComplet(e){
        this.setState({nom_complet:e.target.value})
    }
    setTelephone(e){
        this.setState({telephone:e.target.value})
    }
    setEmail(e){
        this.setState({email:e.target.value})
    }
    saveAdresse(e){
        e.preventDefault()
        const fields = document.querySelectorAll('.form_adresse');

        const {client_id,designation,adresse,code_postal,ville,par_defaut,nom_complet,telephone,email} = this.state
        const data = {client_id,designation,adresse,code_postal,par_defaut,ville,nom_complet,telephone,email,langue_id: this.app.getCurrentLang().id}
        if(designation && client_id && code_postal){
            this.ajaxPost(
                'adresse_client',data,response=>{
                    if(this.props.onAdressAdded) this.props.onAdressAdded(response)
                    if(!response.errors){
                        if(this.app?.compte_view){
                            this.app.compte_view.requestClient();
                        }

                        // Réinitialiser les champs après l'enregistrement
                        fields.forEach(field => {
                            field.value = '';
                        });
                    }
                }
            )
        }
    }
    render(){
        return <form>
            <div className="add-adresse-block">
                <div className="field">
                    <input className="form_adresse" placeholder={this.app.getCurrentLangText("site_text_designation")} onChange={e=>this.setDesignation(e)}/>
                </div>
                <div className="field">
                    <input className="form_adresse" placeholder={this.app.getCurrentLangText("site_text_nom_complet")} onChange={e=>this.setNomComplet(e)}/>
                </div>
                <div className="field">
                    <input type="number" className="form_adresse" placeholder={this.app.getCurrentLangText("site_text_telephone")} onChange={e=>this.setTelephone(e)}/>
                </div>
                <div className="field">
                    <input className="form_adresse" placeholder={this.app.getCurrentLangText("site_text_email")} onChange={e=>this.setEmail(e)}/>
                </div>
                <div className="field">
                    <input className="form_adresse" placeholder={this.app.getCurrentLangText("site_text_ville")} onChange={e=>this.setVille(e)}/>
                </div>
                <div className="field">
                    <input type="number" className="form_adresse" placeholder={this.app.getCurrentLangText("site_text_code_postal")} onChange={e=>this.setCodePostal(e)}/>
                </div>
                <div className="field">
                    <button onClick={e=>{this.saveAdresse(e)}}>
                    {this.app.getCurrentLangText("site_text_ajouter")}
                    </button>
                </div>
            </div>

        </form>
    }
}
export default AddAdresse