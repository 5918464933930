import AjaxComp from "../data/AjaxComp";
import Base from "./base_minimale";
import SectionCompte from "../layout/sections/section_compte";



class Compte extends AjaxComp {
    constructor(props){
        super(props)
        this.app.compte = this
    }
    render(){
        if(this.app.client && this.app.client.visitor){
            document.location.href = this.suffixe
        }
        return (
            <>
                <Base noHeader={false}>
                    <SectionCompte/>
                </Base>
            </>
        )
    }
}

export default Compte