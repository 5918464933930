import AjaxComp from "../../data/AjaxComp";
import Base from "../../pages/base";
import ProduitAccroche from "../containers/listes/produit-accroche";
import "./search_results.css"

class ZonePetitPrix extends AjaxComp{
    queryname   = 'petitprix'
    attrs_query = ['id','image','description','prix','prix_devises{prix}','designation','notes_avis{note,avis,created_at,client{id,nom_complet,email}}','createur{id,designation,photo,couverture,nbr_produits},images{id,src},declinaisons{id,produit_id,nom_declinaison,display_text,quantity,couleur_id,couleur{id,nom,description,code_couleur},declinaison_id,declinaisons{id,produit_id,nom_declinaison,display_text,quantity,declinaison_id,taille_id,taille{id,nom,description},produit{id,designation,prix}}}']
    default_conditions = {
        
    }
    constructor(props){
        super(props)
        this.state = {...props}
        this._update(
            data=>{
                this.updateSafelyState(data)
            }
        )
    }
    render(){
        const {petitprix}   = this.state
        const results       = petitprix ? petitprix : []
        return <>
            <div className="zone">
                <h1 className="label-zone">
                    {this.app.getCurrentLangText("site_text_petit_prix")} 
                </h1>
                <div id='search_results'>
                    <div className="list_results">
                        {results?.map(
                            (produit, idx) => {
                                return (
                                    <ProduitAccroche key={idx} produit={produit} app={this.app} ></ProduitAccroche> 
                                )
                            }
                        )}
                    </div>
                </div>
            </div>
        </>
    }
}

export default ZonePetitPrix
