import Loader from "../../Loader";
import AjaxComp from "../../data/AjaxComp";
import NoteForm from "../../forms/note";

class SectionNote extends AjaxComp {

    constructor(props)
    {
        super(props);
        this.state = { ...props };
        console.log(props, "papa propos", this.state.produit_id, this.state.createur_id)
    }
    render(){

        return (
            this.state ?
                <div id="section-note">
                    <NoteForm produit_id={this.state.produit_id} createur_id={this.state.createur_id}></NoteForm>
                </div>

      : <Loader loading={!this.state} />
    
        )
    }
}
export default SectionNote