import AjaxComp from "../../data/AjaxComp";

class ContactAndLangs extends AjaxComp {


    render(){
        const langues = (this.app.state.data && this.app.state.data.langues) ? this.app.state.data.langues : []; 
        return (
            <div className="contactandlangs text-center d-flex justify-content-between">
                <a href="#section-partenaire" className="contact useraction-toggle text-uppercase" id='toggle-section-partenaire' onClick={e=>this.switchPopupElement(e,'#section-partenaire')} >
                    {this.app.getCurrentLangText('site_text_creer_ma_boutique')}
                </a>
                <a></a>
                <a href="#formulaire-contact" className="contact useraction-toggle text-uppercase"  id='toggle-section-contact' onClick={e=>this.switchPopupElement(e,'#section-contact')} >
                    {this.app.getCurrentLangText('site_text_nous_contacter')}
                </a>
                <span href="/" className="langs">
                    <span className="labels">
                        {
                            langues.map(
                                (lang,idx)=>{
                                    const diminutifcurrentlang = this.app.state.currentlang ? this.app.state.currentlang : ''
                                    const {nom,image,diminutif} = lang
                                    const classe = "langs-label "+(diminutifcurrentlang == diminutif ? 'current' : '')
                                    return <>
                                        {((idx) ? '/' : '')}
                                        <span className={classe} style={{cursor:'pointer'}}  onClick={e=>{this.app.setCurrentLang(lang);document.location.href=document.location.href}}>
                                            {diminutif.toUpperCase()}
                                        </span>
                                    </>
                                }
                            )
                        }
                    </span>
                    <span className="icons">
                        {
                            langues.map(
                                (lang,idx)=>{
                                    const {nom,image,diminutif} = lang
                                    return <a className="icon" onClick={e=>{this.app.setCurrentLang(lang);document.location.href=document.location.href}}>
                                        <img loading="lazy" src={image} alt=""/>
                                    </a>    
                                }
                            )
                        }
                    </span>
                </span>
            </div>
        )


    }
}

export default ContactAndLangs