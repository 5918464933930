import { matchPath } from "react-router";
import NahyelComponent from "../NahyelComponent";
import { MToaster } from "../custom/MToast";

import { createBrowserHistory } from "history";
window.popups = {};
const { REACT_APP_apiUri, REACT_APP_suffixe } = process.env;
class AjaxComp extends NahyelComponent {
  ajax_data = {};
  edit_data = {};
  data = {};
  apiUri = REACT_APP_apiUri;
  suffixe = REACT_APP_suffixe;
  default_multiple = false;
  default_conditions = null;
  name = "nahyel_component";
  apiToken = localStorage.getItem("apiToken");
  constructor(props) {
    super(props);

    this.toaster = new MToaster();
    this.history = createBrowserHistory();
    this.updateSafelyState({ data: this.data });
    this.setRouteParams();

    this.cache = {}; // Cache pour stocker les réponses des requêtes


    // this.state.display_error = null
    // this.state.display_message = null
  }
  formatNumber(
    number,
    decimals = 2,
    decimalSeparator = ".",
    thousandsSeparator = " "
  ) {
    number = parseFloat(number);

    if (!Number.isFinite(number) || isNaN(number)) return "";

    const fixedNumber = number.toFixed(decimals);
    const [integerPart, decimalPart] = fixedNumber.split(".");

    let formattedNumber = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      thousandsSeparator
    );

    if (decimalPart && parseInt(decimalPart) !== 0) {
      formattedNumber += decimalSeparator + decimalPart;
    }

    return formattedNumber;
  }
  setFavori(produit_id) {
    if (this.app.state.client_id) {
      this.ajaxPost(
        "favorisclient",
        { produit_id, is_visite: false, client_id: this.app.state.client_id,langue_id: this.app.getCurrentLang().id },
        (data) => {
          this.setState({ setrecent: false });
        }
      );
    }
  }
  showPopupElement = (e, id = null) => {
    const elem = id ? document.querySelector(id) : e.target.nextElementSibling;
    if (elem) {
      elem.classList.add("visible");
    }
  };

  hidePopupElement = (e, id = null) => {
    const elem = id ? document.querySelector(id) : e.target.nextElementSibling;
    if (elem) {
      elem.classList.remove("visible");
    }
  };

  switchPopupElement = (e, id = null) => {
    const elem = id ? document.querySelector(id) : e.target.nextElementSibling;

    const visibleElements = document.querySelectorAll(".visible");
    visibleElements.forEach((el) => {
      if(elem !== el){
        el.classList.remove("visible");
      }
    });

    if (elem) {
      id = elem.id;
      let visibleState = {};
      const elemstate = "show_" + elem.id.replace("#", "");

      if (elem.classList.contains("visible")) {
        elem.classList.remove("visible");
      } else {
        elem.classList.add("visible");
      }
      if (this.app) {
        if (!elem.classList.contains("visible")) {
          visibleState[elemstate] = false;
          if (window.popups.hasOwnProperty("popup_" + id)) {
            window.popups["popup_" + id] = null;
            window.clearPopup(id);
          }
        } else {
          console.warn(elem.classList);
          visibleState[elemstate] = true;
          window["popup_" + id] = elem;
          window.popups["popup_" + id] = window["popup_" + id];
        }
        this.app.setState({ ...visibleState });
      }
    }

  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  saveClientModel(client = null) {
    localStorage.setItem("client", JSON.stringify(client));
  }
  verifyClientLoggedState(client) {
    console.warn(client);
  }
  getClientModel() {
    let client = localStorage.getItem("client");
    if (client) {
      client = JSON.parse(client);
      this.verifyClientLoggedState(client);
      return client;
    }
    return null;
  }
  requestClientData(cb) {
    this.ajaxPost(
      "client/compte",
      {langue_id: this.app.getCurrentLang() ? this.app.getCurrentLang().id : null},
      (rawdata) => {
        try {
          const data = JSON.parse(JSON.stringify(rawdata));
          if (data == null) {
            return this.logoutUser();
          } else {
            this.saveClientModel(data);
            this.updateSafelyState({
              client: data,
              stopWaitingClient: true,
              client_id: data.id,
            });
            this.app.client_id = data.id;
            this.app.client = data;
            if (typeof cb == "function") {
              cb(data);
            }
          }
        } catch (e) {
          console.warn(e, " are retrieve client data results errors");
          return this.logoutUser();
        }
      },
      (e) => {
        console.warn(e, " is the thing to process");
      }
    );
  }
  setupClientData(cb, request = false) {
    if (this.isLogged()) {
      console.warn("setup cli data with request..");
      this.requestClientData(cb);
    } else {
      this.ajaxPost("client/addVisitor", {langue_id: this.app.getCurrentLang() ? this.app.getCurrentLang().id : null}, (data) => {
        try {
          if (data.errors) {
            console.warn(data.errors);
          } else {
            data = JSON.parse(JSON.stringify(data));
            if (data.token) {
              this.goTo(this.suffixe + "/?settoken=" + data.token);
            }
          }
        } catch (e) {
          console.warn(e, " are retrieve client data results errors");
        }
      });
    }
  }

  goTo(path) {
    document.location.href = path;
    // this.history.push(path)
    // window.history.push(path)
  }

  logoutUser() {
    this.ajaxPost("client/logout", {langue_id: this.app.getCurrentLang().id}, (response) => {
      localStorage.removeItem("apiToken");
      localStorage.removeItem("client");
      this.goTo(this.suffixe + "/");
    });
  }
  isLogged() {
    return this.apiToken;
  }
  setRouteParams() {
    this.route_params = matchPath(
      this.path_params ? this.path_params : window.location.pathname,
      window.location.pathname
    );
  }
  getRouteParams() {
    return this.route_params;
  }
  async _update(cb = null) {
    this.getData(
      (data) => {
        if (typeof cb === "function") {
          cb(this.data);
        } else {
          console.log("this is from  ", this.className());
        }
        if (this.default_multiple) {
          this.data = this.state.data
            ? this.state.data
            : this.data
            ? this.data
            : {};
          Object.keys(data).forEach((key) => {
            //on recupere la reponse pour chaque query
            const paginatekey = this.needs_pagination ? key + "paginated" : key;
            const queryResponse = data[key];
            if (queryResponse.errors) {
              this.toaster.create(queryResponse.errors, "danger");
            } else {
              if (paginatekey !== key) {
                this.data[key] = queryResponse[paginatekey]["data"];
                this.data["pagination" + key] =
                  queryResponse[paginatekey]["metadata"];
              } else {
                this.data[key] = queryResponse[key];
              }
            }
          });
        } else {
          if (data.errors) {
            data = {};
            this.toaster.create(data.errors, "danger");
          }
          if (data.message) {
            this.data = data;
            this.toaster.create(data.message, "success");
          }
          this.data = data;
        }
        if (this.state.mounted == true) {
          this.setState({ data: this.data });
        } else {
          this.state.data = this.data;
        }
        this.updateSafelyState({ data: this.data });
      },
      this.default_conditions,
      this.default_multiple,
      this.needs_pagination
    );
  }
  getObjectForTodayOrMostRecent(objects) {
    const currentDate = new Date().toLocaleDateString("en-GB"); // Format: DD/MM/YYYY

    // Filter objects for today's date
    const todayObjects = objects.filter((object) => {
      const createdAtDate = new Date(object.created_at_fr.split(" ")[0]);
      const objectDate = createdAtDate.toLocaleDateString("en-GB");
      return objectDate === currentDate;
    });

    // If there are objects for today, find the one with the most recent ID
    if (todayObjects.length > 0) {
      const latestTodayObject = todayObjects.reduce(
        (max, object) => (object.id > max.id ? object : max),
        todayObjects[0]
      );
      return latestTodayObject;
    } else {
      // If no objects for today, find the one with the most recent ID
      const mostRecentObject = objects.reduce(
        (max, object) => (object.id > max.id ? object : max),
        objects[0]
      );
      return mostRecentObject;
    }
  }
  // getObjectForTodayOrMostRecent(objects) {
  //     const currentDate = new Date().toISOString().split('T')[0];

  //     // Filter objects for today's date
  //     const todayObjects = objects.filter(object => object.created_at.startsWith(currentDate));

  //     // If there are objects for today, find the one with the most recent ID
  //     if (todayObjects.length > 0) {
  //       const latestTodayObject = todayObjects.reduce((max, object) => (object.id > max.id ? object : max), todayObjects[0]);
  //       return latestTodayObject;
  //     } else {
  //       // If no objects for today, find the one with the most recent ID
  //       const mostRecentObject = objects.reduce((max, object) => (object.id > max.id ? object : max), objects[0]);
  //       return mostRecentObject;
  //     }
  // }
  timeAgoString(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);
    const timeDifference = currentDate - inputDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysAgo === 0) {
      return this.app.getCurrentLangText("site_text_ajourd_hui");
    } else if (daysAgo === 1) {
      return this.app.getCurrentLangText("site_text_hier");
    } else {
      return this.app.getCurrentLangText("site_text_il_y_a") + ` ${daysAgo} ` + this.app.getCurrentLangText("site_text_jours");
    }
  }

  ajaxURI(path) {
    // const apiToken = localStorage.getItem('apiToken'); // Retrieve the API token
    return `${this.apiUri}/${path}`;
  }

  customGraphql(type = null, conditions = null, attrs = null, cb = () => {}) {
    const cacheKey = JSON.stringify({ type, conditions, attrs });
    const uri = this.graphqlQueryUri(type, conditions, attrs);
    const req = new XMLHttpRequest();
    req.addEventListener("load", (e) => {
      try {
        const response = JSON.parse(req.response);
        cb(response.data ? response.data : response);
      } catch (e) {
        console.log("Utilisation des données mises en cache", e);
        this.app.setDisplayError(e);
      }
    });
    req.open("GET", uri);
    if (this.isLogged()) {
      req.setRequestHeader("Authorization", `Bearer ${this.apiToken}`);
    }
    req.send();
  }

  // customGraphql(type = null, conditions = null, attrs = null, cb = () => {}) {
  //   console.log("type =>", type);
  //   const uri = this.graphqlQueryUri(type, conditions, attrs);
  //   const req = new XMLHttpRequest();
  //   req.addEventListener("load", (e) => {
  //     try {
  //       const response = JSON.parse(req.response);
  //       cb(response.data ? response.data : response);
  //     } catch (e) {
  //       this.app.setDisplayError(e);
  //     }
  //   });
  //   req.open("GET", uri);
  //   if (this.isLogged()) {
  //     req.setRequestHeader("Authorization", `Bearer ${this.apiToken}`);
  //   }
  //   req.send();
  // }
  customPaginatedGraphql(
    type = null,
    conditions = null,
    attrs = null,
    page,
    count,
    cb = () => {}
  ) {

    console.info(type,' from ',this)
    const uri = this.graphqlPaginatedQueryUri(
      type,
      conditions,
      attrs,
      null,
      page,
      count
    );
    const req = new XMLHttpRequest();
    req.addEventListener("load", (e) => {
      const response = JSON.parse(req.response);
      cb(response.data ? response.data : response);
    });
    req.open("GET", uri);
    if (this.isLogged()) {
      req.setRequestHeader("Authorization", `Bearer ${this.apiToken}`);
    }
    req.send();
  }
  graphqlQueryUri(
    query = null,
    conditions = null,
    attributes = null,
    only_attributes = false
  ) {
    if (query) {
      let attr_string = "";
      let cond_string = "";
      let attrs = [];

      if (attributes) {
        if (only_attributes) {
          attrs = attributes;
        } else {
          attributes.forEach((attr) => {
            if (!attrs.includes(attr)) {
              attrs.push(attr);
            }
          });
        }
      }
      if (conditions) {
        cond_string = "(";
        Object.keys(conditions).forEach((key, idx) => {
          cond_string = `${cond_string}${idx ? "," : ""}${key}:${
            conditions[key]
          }`;
        });
        cond_string += ")";
      }
      attr_string = `{${attrs.join(",")}}`;
      return `${this.apiUri}/graphql?query={${query}${
        cond_string ? cond_string : ""
      }${attr_string}}`;
    }
  }
  graphqlPaginatedQueryUri(
    query = null,
    conditions = null,
    attributes = null,
    only_attributes = false,
    page,
    count
  ) {
    if (query) {
      let attr_string = "";
      let cond_string = "";
      let attrs = [];

      if (attributes) {
        if (only_attributes) {
          attrs = attributes;
        } else {
          attributes.forEach((attr) => {
            if (!attrs.includes(attr)) {
              attrs.push(attr);
            }
          });
        }
      }
      if (conditions) {
        cond_string = "(";
        Object.keys(conditions).forEach((key, idx) => {
          cond_string = `${cond_string}${idx ? "," : ""}${key}:${
            conditions[key]
          }`;
        });
        cond_string += `${
          Object.keys(conditions).length ? "," : ""
        }page:${page},count:${count}`;
        cond_string += ")";
      } else {
        cond_string += `(page:${page},count:${count})`;
      }
      attr_string = `{metadata{total,per_page,current_page,last_page},data{${attrs.join(
        ","
      )}}`;
      return `${this.apiUri}/graphql?query={${query}paginated${
        cond_string ? cond_string : ""
      }${attr_string}}}`;
    }
  }
  graphqlURI(conditions, attributes = null, only_attributes = false) {
    //generer l'url de la requete graphql
    let attr_string = "";
    let cond_string = "";
    let attrs = this.attrs_query;
    if (attributes) {
      if (only_attributes) {
        attrs = attributes;
      } else {
        attributes.forEach((attr) => {
          if (!attrs.include(attr)) {
            attrs.push(attr);
          }
        });
      }
    }
    if (conditions) {
      cond_string = "(";
      Object.keys(conditions).forEach((key, idx) => {
        cond_string = `${cond_string}${idx ? "," : ""}${key}:${
          conditions[key]
        }`;
      });
      cond_string += ")";
    }
    attr_string = `{${attrs.join(",")}}`;
    return `${this.apiUri}/graphql?query={${this.queryname}${
      cond_string ? cond_string : ""
    }${attr_string}}`;
  }
  graphqPaginatedURI(
    conditions,
    attributes = null,
    only_attributes = false,
    page = 1,
    count = 10
  ) {
    //generer l'url de la requete graphql
    let attr_string = "";
    let cond_string = "";
    let attrs = this.attrs_query;
    if (attributes) {
      if (only_attributes) {
        attrs = attributes;
      } else {
        attributes.forEach((attr) => {
          if (!attrs.include(attr)) {
            attrs.push(attr);
          }
        });
      }
    }
    if (conditions) {
      cond_string = "(";
      Object.keys(conditions).forEach((key, idx) => {
        cond_string = `${cond_string}${idx ? "," : ""}${key}:${
          conditions[key]
        }`;
      });
      cond_string += `${
        Object.keys(conditions).length ? "," : ""
      }page:${page},count:${count}`;
      cond_string += ")";
    } else {
      cond_string += `(page:${page},count:${count})`;
    }
    attr_string = `{metadata{total,per_page,current_page,last_page},data{${attrs.join(
      ","
    )}}`;
    return `${this.apiUri}/graphql?query={${this.queryname}paginated${
      cond_string ? cond_string : ""
    }${attr_string}}}`;
  }
  getData(
    cb = console.log,
    conditions = null,
    multiple = false,
    paginate = false
  ) {
    const currentlang = this.app.getCurrentLang();
    if (multiple) {
      if (currentlang) {
        conditions = conditions?.map((condition) => {
          if (condition) {
            condition.lang = `"${currentlang.diminutif}"`;
          }
          return condition;
        });
      }
      return this.getMultipleData(cb, conditions);
    }

    if (currentlang) {
      if (conditions) {
        conditions.lang = `"${currentlang.diminutif}"`;
      } else {
        conditions = { lang: `"${currentlang.diminutif}"` };
      }
    }
    const req = new XMLHttpRequest();
    req.addEventListener("load", (e) => {
      const response = JSON.parse(req.response);
      cb(response.data ? response.data : response);
    });
    let uri = this.graphqlURI(conditions);
    if (paginate) {
      uri = this.graphqPaginatedURI(conditions);
    }
    req.open("GET", uri);
    if (this.isLogged()) {
      req.setRequestHeader("Authorization", `Bearer ${this.apiToken}`);
    }
    req.send();
  }
  ajaxGet(path, cb = console.log) {
    const req = new XMLHttpRequest();
    req.addEventListener("load", (e) => {
      try {
        const response = JSON.parse(req.response);
        cb(response);
      } catch (e) {
        console.error(e);
        cb({ errors: e.toString(), message: req.response.toString() });
      }
    });
    req.open("GET", this.ajaxURI(path));
    if (this.isLogged()) {
      req.setRequestHeader("Authorization", `Bearer ${this.apiToken}`);
    }
    req.send();
  }
  formPost(path, form, cb = console.log, errorcb = null, dismiss = null) {
    const req = new XMLHttpRequest();
    const formdata = new FormData(form);
    req.addEventListener("load", (e) => {
      try {
        const response = JSON.parse(req.response);
        if (response.errors) {
          if (this.app && !errorcb) {
            this.app.setDisplayError(response.errors);
          } else {
            if (errorcb && typeof errorcb == "function") {
              errorcb(response.errors);
            }
          }
        }
        if (response.message) {
          if (this.app) {
            this.app.setDisplayMessage(response.message);
            if (dismiss) {
              this.switchPopupElement(e, dismiss);
            }
          }
        }
        if (cb && typeof cb == "function") {
          cb(response);
        }
      } catch (e) {
        console.error(req.response + "throwed an error FOR " + path);
        if (this.app && !errorcb) {
          this.app.setDisplayError(e);
        } else {
          if (errorcb && typeof errorcb == "function") {
            errorcb(e);
          }
        }
        if (cb && typeof cb == "function") {
          cb({ errors: e.toString(), message: req.response.toString() });
        }
      }
    });
    req.open("POST", this.ajaxURI(path));
    if (this.isLogged()) {
      req.setRequestHeader("Authorization", `Bearer ${this.apiToken}`);
    } else {
      // alert('here')
    }
    req.send(formdata);
  }
  ajaxPost(path, data, cb = console.log, errorcb = null) {
    const req = new XMLHttpRequest();
    const formdata = new FormData();
    Object.keys(data).forEach((cle) => {
      formdata.append(cle, data[cle]);
    });
    req.addEventListener("load", (e) => {
      try {
        let response = {};
        if (req.status == 200) {
          response = JSON.parse(req.response);
          console.log("retours response", response);
          if (response.errors) {
            if (this.app && !errorcb) {
              this.app.setDisplayError(response.errors);
            } else {
              errorcb(response.errors);
            }
          }
          if (response.message) {
            if (this.app) {
              this.app.setDisplayMessage(response.message);
            }
          }
        } else {
          response = { errors: req.responseText, data: req.responseText };
        }
        cb(response);
      } catch (e) {
        console.error(req.response + "throwed an error FOR " + path);
        if (this.app && !errorcb) {
          this.app.setDisplayError(e);
        } else {
          errorcb(e);
        }
        cb({ errors: e.toString(), message: req.response.toString() });
      }
    });
    req.open("POST", this.ajaxURI(path));
    if (this.isLogged()) {
      req.setRequestHeader("Authorization", `Bearer ${this.apiToken}`);
    } else {
      // alert('here')
    }
    req.send(formdata);
  }
  ajaxDelete(path, data, cb = console.log) {
    const req = new XMLHttpRequest();
    const formdata = new FormData();
    Object.keys(data).forEach((cle) => {
      formdata.append(cle, data[cle]);
    });
    req.addEventListener("load", (e) => {
      const response = JSON.parse(req.response);
      cb(response);
    });
    req.open("DELETE", this.ajaxURI(path));
    if (this.isLogged()) {
      req.setRequestHeader("Authorization", `Bearer ${this.apiToken}`);
    }
    req.send(formdata);
  }
  getMultipleData(cb = console.log, conditionsArray = []) {
    //permettre de faire plusieurs requetes graphql
    if (this.querynames.length !== this.attrs_queries.length) {
      //on verifie que les attributs sont bien spécifiés pour toutes les requetes
      this.toaster.create("Invalid input lengths.", "danger");
      return;
    }
    const responseData = {};
    this.querynames.forEach((queryname, index) => {
      let requestUri = this.constructMultipleDataUri(
        index,
        conditionsArray.length > index ? conditionsArray[index] : null
      );
      if (this.needs_pagination) {
        requestUri = this.constructMultiplePaginatedDataUri(
          index,
          conditionsArray.length > index ? conditionsArray[index] : null
        );
      }

      const req = new XMLHttpRequest();

      req.addEventListener("load", (e) => {
        //on stocke la réponse pour chaque query dans l'objet réponse
        try {
          const response = JSON.parse(req.response);
          responseData[queryname] = response.data ? response.data : response;
          if (Object.keys(responseData).length === this.querynames.length) {
            cb(responseData);
          }
        } catch (e) {
          this.app.setDisplayError(e);
        }
      });
      req.open("GET", requestUri);
      if (this.isLogged()) {
        req.setRequestHeader("Authorization", `Bearer ${this.apiToken}`);
      }
      req.send();
    });
  }
  constructMultipleDataUri(idx, conditions = null) {
    let attr_string = "";
    let cond_string = "";
    let queryname = this.querynames[idx];
    const currentlang = this.app.getCurrentLang();
    let attrs = this.attrs_queries[idx]; // on recupere les attributs correspondant a la requete actuelle
    if (conditions) {
      cond_string = "(";
      Object.keys(conditions).forEach((key, idx) => {
        cond_string = `${cond_string}${idx ? "," : ""}${key}:${
          conditions[key]
        }`;
      });
      // if(currentlang){
      //     cond_string+='lang:"'+currentlang.diminutif+'"'
      // }
      cond_string += ")";
    }
    attr_string = `{${attrs.join(",")}}`;
    return `${this.apiUri}/graphql?query={${queryname}${
      cond_string ? cond_string : ""
    }${attr_string}}`;
  }
  constructMultiplePaginatedDataUri(
    idx,
    conditions = null,
    page = 1,
    count = 10
  ) {
    let attr_string = "";
    let cond_string = "";
    let queryname = this.querynames[idx];
    const currentlang = this.app.getCurrentLang();
    let attrs = this.attrs_queries[idx]; // on recupere les attributs correspondant a la requete actuelle
    if (conditions) {
      cond_string = "(";
      Object.keys(conditions).forEach((key, idx) => {
        cond_string = `${cond_string}${idx ? "," : ""}${key}:${
          conditions[key]
        }`;
      });
      cond_string += `${
        Object.keys(conditions).length ? "," : ""
      }page:${page},count:${count}`;
      // if(currentlang){
      //     cond_string+=',lang:"'+currentlang.diminutif+'"'
      // }
      cond_string += ")";
    } else {
      cond_string += `(page:${page},count:${count})`;
    }
    attr_string = `{metadata{total,per_page,current_page,last_page},data{${attrs.join(
      ","
    )}}`;
    return `${this.apiUri}/graphql?query={${queryname}paginated${
      cond_string ? cond_string : ""
    }${attr_string}}}`;
  }
}
export default AjaxComp;
