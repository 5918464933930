import AjaxComp from "../../data/AjaxComp";
import CplComponent from "./infoscpl";


class SectionCpl extends AjaxComp {


    render(){

        return (

                <div id="section-cpl">
                    <CplComponent/>
                </div>
    
        )
    }
}
export default SectionCpl