import Loader from "../../Loader";
import AjaxComp from "../../data/AjaxComp";

class Waiter extends AjaxComp{
    state={
        currentLang:null,
        currentDevise:null
    }
    constructor(props){
        super(props)
        this.state = {...props,show:false,...this.state}
        this.app.waiter=this
        if(this.app.getCurrentLang())
        {
            this.updateCurrentLang(this.app.getCurrentLang())
        }
        if(this.app.getCurrentDevise())
        {
            this.updateCurrentDevise(this.app.getCurrentDevise())
        }
    }
    getLocalDevise(id){
        const match = this.app.state.devises?.filter(devise=>devise.id==id)
        return match.length ? match[0] : null
    }
    getLocalLangue(id){
        const match = this.app.state.langues?.filter(langue=>langue.id==id)
        return match.length ? match[0] : null
    }
    validate(){
        const currentDevise = this.currentDevise()
        const currentLang = this.currentLang()
        if(currentDevise && currentLang){
            this.app.setCurrentDevise(currentDevise)
            this.app.setCurrentLang(currentLang)
            this.setState({show:false})
            this.app.setState({loading:false,changelangordevise:false})
        }
    }
    updateCurrentLang(langue){
        if(this.state.mounted)
        {
            this.setState({currentLang:langue})
        }
        else{
            this.state = ({...this.state,currentLang:langue})
        }
    }
    updateCurrentDevise(devise){

        if(this.state.mounted)
        {
            this.setState({currentDevise:devise})
            if(this.app.gestion_panier) 
            {
                this.app.gestion_panier.getIt()
            }
        }
        else
        {
            this.state = ({...this.state,currentDevise:devise})
        }
    }
    processShow(){
        const {langues,devises} = this.app.state
        
        this.updateSafelyState({show:(langues&&devises)})
    }
    currentDeviseVal(){
        return (this.state.currentDevise ? this.state.currentDevise.id :   (this.app.getCurrentDevise() ? this.app.getCurrentDevise().id : ''))
    }
    currentLangVal(){
        return (this.state.currentLang ? this.state.currentLang.id :   (this.app.getCurrentLang() ? this.app.getCurrentLang().id : ''))
    }
    currentDevise(){
        return (this.state.currentDevise ? this.state.currentDevise :   (this.app.getCurrentDevise() ? this.app.getCurrentDevise() : ''))
    }
    currentLang(){
        return (this.state.currentLang ? this.state.currentLang :   (this.app.getCurrentLang() ? this.app.getCurrentLang() : ''))
    }
    render(){
        
        const {langues,devises} = this.app.state
        const show =  (langues && devises) 
        if(this.app.getCurrentDevise() && this.app.getCurrentLang())
        {
            if( ((typeof this.app.userpreferences) == 'undefined') || (this.app.userpreferences && this.app.userpreferences.state && this.app.userpreferences.state.changelangordevise))
            {   
                // alert(this.app.userpreferences.state.changelangordevise)
                if( ((typeof this.app.userpreferences) == 'undefined') || !this.app.userpreferences.state.changelangordevise ) 
                {
                    this.app.setState({changelangordevise:false})
                    if(this.app.userpreferences)
                    {
                        this.app.userpreferences.updateSafelyState({changelangordevise:false})
                    }
                    this.app.setState({loading:false})
                    this.app.setState({loading:false})
                }
            }
        }
        return  <div style={{position:'fixed',width:"100%",height:"100%",zIndex:"100",background:"url('/assets/pictures/header.png')",backgroundSize:'cover'}}>
                
                <div style={{width:"100%",display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", height:"100%"}}>
                    <div style={{marginBottom:"2em"}} className="text-center">
                        <img loading="lazy" src={"/assets/pictures/logo.svg"}  style={{margin:'auto',padding:"8px",justifySelf:'center'}}/>
                    </div>
                    {
                    (show == false) 
                        ?
                            <div style={{padding:"calc(var(--base-spacing) * 3)",margin:"auto",background:"#AAA5",display:"grid",width:"380px"}}>
                                <Loader loading={this.state.show == false}/> 
                            </div>
                        :
                        <>
                            <div style={{padding:"calc(var(--base-spacing) * 3)",paddingTop:'calc(var(--base-spacing)/2)',background:"#AAA5",display:"grid",width:"380px"}}>
                                <label style={{padding:"8px"}}>
                                    Langue
                                </label>
                                <select value={this.currentLangVal()} style={{padding:"12px",cursor:"pointer"}}  onChange={e=>this.updateCurrentLang(this.getLocalLangue(e.target.value))}>
                                    <option value="" onClick={e=>this.updateCurrentLang(null)}>
                                    Selectionner
                                    </option>
                                    {
                                        langues?.map(
                                            langue=>{
                                                return <option selected={this.currentLangVal()==langue.id} value={langue.id} onClick={e=>this.updateCurrentLang(langue)}>
                                                    {langue.nom}
                                                </option>
                                            }
                                        )
                                    }
                                </select>
                            </div>
                            <div style={{padding:"calc(var(--base-spacing) * 3)",paddingTop:'calc(var(--base-spacing)/2)',background:"#AAA5",display:"grid",width:"380px"}}>
                                <label style={{padding:"8px"}}>
                                    Devise
                                </label>
                                <select value={this.currentDeviseVal()} style={{padding:"12px",cursor:"pointer"}} onChange={e=>this.updateCurrentDevise(this.getLocalDevise(e.target.value))}>
                                    <option value="" onClick={e=>this.updateCurrentDevise(null)}>
                                    Selectionner
                                    </option>
                                    {
                                        devises?.map(
                                            devise=>{
                                                return <option selected={this.currentDeviseVal()==devise.id}  value={devise.id} onClick={e=>{this.updateCurrentDevise(devise)}}>
                                                    {devise.designation}
                                                </option>
                                            }
                                        )
                                    }
                                </select>
                            </div>
                            <div style={{padding:"calc(var(--base-spacing) * 3)",paddingTop:'var(--base-spacing)',background:"#AAA5",display:"grid",width:"380px"}}>
                                <button className="btn-gradient" onClick={e=>{
                                        this.validate()
                                    }}>
                                    Continuer
                                    
                                </button>
                            </div>
                        </>
                    }

                </div>
        </div>
    }
}
export default Waiter