import AjaxComp from "../../data/AjaxComp";
import SearchBar from "./searchbar";
import UserActions from "./useractions";
import ContactAndLangs from "./contactandlangs";
import ListeIcones from "../containers/listes/liste-icones";
import "./heading.css";
import Loader from "../../Loader";
import { Link } from "react-router-dom";
import React, { Component } from "react";

import SectionCategories from "../sections/section_categories";

class Heading extends AjaxComp {
  constructor() {
    super();
    this.state.createurs = [];
    
  }

  componentDidMount() {
    this.setState({ mounted: true });
    if(!this.state.categories_menu)
    {
      this.customGraphql(
        "categories",
        {
          menu_website: true,
          menu_website_order: true,
          //lang: '"' + this.app.getCurrentLang().diminutif + '"',
          langue_id: this.app.getCurrentLang().id,
          website:true
        },
        ["id","designation","nom_langue","parent{id,designation}"],
        (data) => {
          if (data.categories) {
            this.state.categories_menu = data.categories;
            this.updateSafelyState({ categories_menu: data.categories });
          }
        }
      );
    }

    if(!this.state.createur)
    {
      this.customGraphql(
        "createurs",
        { is_nahyel: true, active: true},
        ["id,designation,photo"],
        (data) => {
          if (data.createurs) {
            this.state.createurs = data.createurs;
            this.updateSafelyState({ data });
          }
        }
      );
    }

  }
  render() {
    const { t } = this.props;
    // console.log("ppa data",this.state)
    return this.app.state.loading ? (
      <Loader loading={this.app.state.loading} />
    ) : (
      <div className="pagenavbar_header page">
        <div className="navbar_header heading-navband">
          <div className="navbar_header_top zone">
            <div>
              <span
                className="togglemenu"
                style={{ position: "relative", isolation: "isolate" }}
              >
                <span
                  className="togglemenu-icon"
                  onClick={(e) =>
                    this.switchPopupElement(e, "#section-categories")
                  }
                >
                  <img
                    style={{ width: "24px" }}
                    id="toggle-menu-icon"
                    src="/assets/pictures/menu.svg"
                    alt=""
                    className="togglemenu-icon togglemenu-text"
                  />
                </span>
              </span>
              {/* <SectionCategories></SectionCategories> */}
            </div>
            <div className="logobox">
              <a href={this.suffixe + "/"}>
                <img
                  src="/assets/pictures/logo.svg"
                  alt="NAHYEL"
                  className="logo"
                />
              </a>
            </div>
            <div className="use_actions_icons center">
              <UserActions></UserActions>
            </div>
          </div>
          <div className="shopsList_links_container zone">
            <div id="shops">
              <ul>
                <li>{this.app.getCurrentLangText("site_text_nos_boutiques")} </li>
                <li>
                  <ul className="suggestion_language">
                    {this.state.createurs?.map((createur, idx) => {
                      return (
                        <>
                          <li key={idx}>
                            <a href={this.suffixe + "/shop/" + createur.id}>
                              {createur.designation}
                            </a>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </div>
            <ul className="shopsList_links">
              <ul className="shopsList_links">
                {this.state.categories_menu?.map((category, idx) => {
                  return (
                    <>
                      <li key={idx}>
                        <a
                          href={
                            this.suffixe +
                            "/categories?search=" +
                            category.id + "&stepp=1"
                          }
                          className="shopsList_link"
                        >
                          {category.nom_langue}
                        </a>
                      </li>
                    </>
                  );
                })}
              </ul>
              <div className="heading-navband_container">
                <div className="center news_navbar">
                  <SearchBar></SearchBar>
                </div>
              </div>
              <li>
                <a
                  href="#formulaire-contact"
                  className="shopsList_link useraction-toggle text-uppercase"
                  id="toggle-contactform"
                  onClick={(e) =>
                    this.switchPopupElement(e, "#section-contact")
                  }
                >
                  {this.app.getCurrentLangText("site_text_nous_contacter")}
                </a>
              </li>
            </ul>
            <Link
              to={this.suffixe + "/categories?is_promo=true"}
              className="promotion_btn text-uppercase"
              style={{ cursor: "pointer" }}
            >
              {this.app.getCurrentLangText("site_text_promotion")}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Heading;
