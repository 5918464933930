import AjaxComp from "../data/AjaxComp";
class GoHome extends AjaxComp {
  constructor(props) {
    super(props);
    this.goTo(this.suffixe+'/')
  }
  render() {
    return ('');
  }
}
export default GoHome;
