import AjaxComp from "../../data/AjaxComp";
import CompteView from "./compte_view";
import CompteSidebar from "./compte_sidebar";
import LabelZone from "../extra/labelZone";

class SectionCompte extends AjaxComp {


    constructor(props){
        super(props)
        this.app.section_compte_client = this
        this.updateSafelyState({currentSection:'mon-compte'})
    }
    render(){
        return (
            <div id="section-compte" className="zone">
                <LabelZone label={this.app.getCurrentLangText("site_text_compte_mon_compte")} whitlogo={true}></LabelZone>
                <div className="data">
                    <CompteSidebar />
                    <CompteView />
                </div>
                    
            </div>
        )
    }


}

export default SectionCompte