import Loader from "../../Loader";
import AjaxComp from "../../data/AjaxComp";

class RaccourcisFavoris extends AjaxComp {
  constructor(props) {
    super(props);
    // this.articles = this.panier?.articles()
    // this.updateSafelyState({data:{articles:this.articles}})
    // this.app.raccourcis_favoris = this
    this.state = { ...props };
  }

  render() {
    return (
      <>
        <h3 className="useractionview-label">
          <div>
          {this.app.getCurrentLangText("site_text_compte_mes_favoris")}
           ({" "}
            <span className="count-panier">
              {" "}
              {this.app.state.client &&
              this.app.state.client.favoris &&
              this.app.state.client.favoris.length
                ? this.app.state.client.favoris.length
                : 0}
            </span>{" "}
            )
          </div>
          <div className="" onClick={(e) => this.switchPopupElement(e)}>
            <svg
              class="svg-inline--fa fa-xmark text-danger"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="xmark"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              ></path>
            </svg>
          </div>
        </h3>
        <div className="sepa"></div>
        <div className="articles-panier">
          {this.app.state.client &&
          this.app.state.client.favoris &&
          this.app.state.client.favoris.length
            ? this.app.state.client.favoris.map((article, key) => {
                const couleur = article.declinaisons
                  ? article.declinaisons[0]
                  : {};
                const taille = couleur.declinaisons
                  ? couleur.declinaisons[0]
                  : {};
                return (
                  <div className="article-panier" key={key}>
                    <div className="illustration">
                      <img
                        loading="lazy"
                        src={this.apiUri + "/" + article.image}
                        alt=""
                      />
                    </div>
                    <div className="details">
                      <div className="infos">
                        <div className="info-panier info-createur">
                          <span className="icon">
                            <img
                              loading="lazy"
                              src={this.apiUri + "/" + article.createur.photo}
                              alt=""
                            />
                          </span>
                          <span>{article.createur.designation}</span>
                        </div>
                        <div className="info-panier info-designation">
                          {article.nom_langue}
                        </div>
                        <div className="info-panier info-prix">
                          {article.prix} XOF
                        </div>
                      </div>
                      <div className="remove">
                        <img
                          loading="lazy"
                          onClick={(e) =>
                            this.app.removeFavoriClient(article.id)
                          }
                          src="/assets/pictures/croix.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="action">
                      {this.app.gestion_panier.state.gotData ? (
                        taille ? (
                          this.app.gestion_panier.isInCart(taille.id) ? (
                            <button
                              onClick={(e) =>
                                this.app.gestion_panier.delFromCart(taille?.id)
                              }
                            >
                              {this.app.getCurrentLangText("site_text_retirer_du_panier")}
                            </button>
                          ) : (
                            <button
                              onClick={(e) =>
                                this.app.gestion_panier.addToCart(taille?.id, 1)
                              }
                            >
                              {this.app.getCurrentLangText("site_text_ajouter_panier")}
                            </button>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        <Loader loading={true} />
                      )}
                    </div>
                  </div>
                );
              })
            : this.app.getCurrentLangText("site_text_vous_navez_pas_de_produit_favori")}
        </div>
      </>
    );
  }
}
export default RaccourcisFavoris;
