import React from 'react';
import AjaxComp from '../../data/AjaxComp';

class PolicyComponent extends  AjaxComp {
  constructor(props){
      super(props)
      this.state = {...props}
      this.state.validate = false
      if(this.state.parent)
      {
        this.state.parent.hideNext()

        if(this.state.parent.beforeBox == null || !this.state.parent.beforeBox)
        {
          // this.state.parent.setState(
          //   {
          //     beforeBox:
          //   }
          // )
        }
        if(this.state.parent.afterBox == null || !this.state.parent.afterBox)
        {
          this.state.parent.setState(
            {
              afterBox:<>
                <span  className='d-flex my-2 p-1 px-2' style={{maxWidth:'100%',background:'#EDEDED',borderRadius:'.5rem'}}>
                  <input  className='mr-2' type='checkbox' onChange={e=>this.valider(e.target.checked)}/><span style={{cursor:"pointer"}} onClick={e=>e.target.previousElementSibling.click()}>{ this.app.getCurrentLangText("site_text_mon_activite_respecte_ces_conditions")}</span>
                </span>
              </>
            }
          )
        }
      }
    }
    valider(value=true){
      this.setState({validate : value})
      if(this.state.parent){
        this.state.parent[(value?'show':'hide')+'Next']()
      }
    }

  render() {
    return (
      <div>
        <h1>{this.app.getCurrentLangText("site_text_politique_confidentialite")}</h1> 
        <h2 style={{fontSize:'1.5rem'}}>({this.app.getCurrentLangText("site_text_conditions_requises_pour_devenir_partenaire")})</h2>
        <p className="my-4">{this.app.getCurrentLangText("site_text_la_politique_de_qualite_de_nayhel")} :</p>
        <ul>
          <li>{this.app.getCurrentLangText("site_text_produire_des_articles_de_bonne_qualite")}</li>
          <li>{this.app.getCurrentLangText("site_text_etre_proactif_sur_internet")}</li>
          <li>{this.app.getCurrentLangText("site_text_etiqueter_imperativement_les_articles")}</li>
          <li>{this.app.getCurrentLangText("site_text_proposer_un_packaging_adequat_pour_les_produits")}</li>
          <li>{this.app.getCurrentLangText("site_text_fournir_les_details_concernant_chaque_article")}</li>
          <li>{this.app.getCurrentLangText("site_text_offrir_un_service_a_reactif_client")}</li>
          <li>{this.app.getCurrentLangText("site_text_respecter_les_delais_de_livraison")}</li>
          <li>{this.app.getCurrentLangText("site_text_garantir_la_securite_des_informations_personnelles")}</li>
          <li>{this.app.getCurrentLangText("site_text_etre_capable_de_demander_au_moins_3_nouvelles_collections_par_an")}</li>
        </ul>
        
      </div>
    );
  }
}

export default PolicyComponent;