import AjaxComp from "../data/AjaxComp";
import Base from "./base";
import ZoneStatutPaiement from "../layout/zones/statutpaiement";

class StatutPaiement extends AjaxComp {

    componentDidMount(){
        if(!this.isLogged()){
            this.goTo(this.suffixe+"/");
        }
    }
    render(){
        return (
            <>
                <Base noHeader={false}>
                    <ZoneStatutPaiement/>
                </Base>
            </>



        )
    }


}

export default StatutPaiement