import React from 'react';
import AjaxComp from '../../data/AjaxComp';

class AProposComponent extends  AjaxComp {
    constructor(props){
        super(props)
        this.state = {...props}
        this.state.validate = false
    }
    valider(value=true){
        this.setState({validate : value})
        if(this.state.parent){
            this.state.parent[(value?'show':'hide')+'Next']()
        }
    }
  render() {
    return (
      <div style={{display:'grid',width:'75%',margin:'auto',alignItems:'center',justifyItems:'center',padding:'1rem',background:'white'}}>
        <h1>{this.app.getCurrentLangText("site_text_a_propos_de_nous")} </h1>
      
        <div className="texte" style={{padding:"4rem",height:"70vh",overflowY:'scroll'}}>
          {/*  */}
          <h2>
            {this.app.getCurrentLangText("site_text_du_concept_store_a_la_wales_en_ligne")}
          </h2>
          <p>
            Nahyeconceptstore.com  {this.app.getCurrentLangText("site_text_est_lillustration_dune_vision_plus_mondialiste_et_plus_ouverte")}
          </p>
          {/*  */}
          <h2>
            {this.app.getCurrentLangText("site_text_une_marketplace_pour_plus_dopportunites")}
          </h2>
          <p>
            {this.app.getCurrentLangText("site_text_d_autre_part_nahyelconceptstore_com_est_une_version_evolutive")}
          </p>

          {/*  */}
          <h2>
            {this.app.getCurrentLangText("site_text_la_creation_africaine_a_la_portee_de_tous")}
          </h2>
          <p>
            {this.app.getCurrentLangText("site_text_ici_vous_pouvez_voir_des_articles_inedits")}
          </p>
          <p>
            {this.app.getCurrentLangText("site_text_par_ailleurs_l_un_des_objectifs_principaux_de_la_plateforme")}
          </p>

        </div>
        <div className="buttonbox mt-4">
          <button className='' onClick={e=>this.switchPopupElement(e,'#section-apropos')} style={{padding:"1rem"}} id="toggle-section-apropos">{this.app.getCurrentLangText("site_text_fermer")}</button>
        </div>
      </div>
    );
  }
}

export default AProposComponent;