import AddAdresse from "../../data/AddAdresse";
import AjaxComp from "../../data/AjaxComp";
import RemoveDataType from "../../data/RemoveDataType";
import SetAdresseDefaut from "../../data/SetAdresseDefaut";
import ResumeCommande from "./resume_commande";
import Produit_accroche from "../containers/listes/produit-accroche";
import { NavLink } from "react-router-dom";
import SetDeviseDefaut from "../../data/SetDeviseDefaut";
import Loader from "../../Loader";
import ProduitAccroche from "../containers/listes/produit-accroche";

const styles = {
  edit_input: {
    border: "unset",
    background: "unset",
    outline: "2px solid #3331",
    padding: "1%",
    width: "100%",
  },
  show_input: {
    border: "unset",
    background: "unset",
    outline: "unset",
    width: "100%",
    paddingBlock: "1%",
  },
};

class CompteView extends AjaxComp {
  edit_data = {
    edit_adresse_defaut: false,
    edit_adresses: false,
    edit_donnees_client: false,
    client: {
      id: null,
      nom_complet: null,
      telephone: null,
      civilite_id: null,
      email: null,
    },
  };
  initial_data = {};
  change_password = false;
  state = {
    getrecent:true,getfavoris:true
  }
  getFavoris(is_visite=false){

    const datakey       = is_visite ? 'recentviews' : 'favoris'
    const getdatakey    = is_visite ? 'recent' : 'favoris'
    if(this.state.data?.id)
    {
      if(!this.state[datakey] && !this.state['get'+getdatakey])
      {
        this.ajaxPost(
            'getfavorisclient',{is_visite,client_id:(this.state.data?.id)?this.state.data.id:'',langue_id: this.app.getCurrentLang().id},data=>{
                const currentState  = {}
                currentState[datakey] = data
                currentState['get'+getdatakey] = false
                if(is_visite ? 'recentviews' : 'favoris')
                this.setState(currentState)
            }
        )
      }
    }
  }
  constructor(props) {
    super(props);
    this.state = { ...props, ...this.state,change_password: this.change_password };
    this.updateSafelyState({ edit_data: this.edit_data });
    this.app.compte_view = this;
    this.updateSafelyState({data:this.app.state.client})
    if(this.state.data)
    {
      this.clientData(this.state.data)
    }
    if (this.hasUrlProp("newpwd")) {
      this.change_password = new Boolean(this.hasUrlProp("newpwd"));
      this.updateSafelyState({ change_password: this.change_password });
      window.onload = (e) => {
        this.goTo("#edit-password-container");
        if(window.document.querySelector("#edit-password"))
        {
          window.document.querySelector("#edit-password").focus();
        }
      };
    }
  }

  clientData(data){
    data = data.client?data.client:data
    if(data){
      this.updateSafelyState({ data});
      Object.keys(data).forEach(
        (donnee) => {
          this.updateEditDonneeClient(donnee, data[donnee]);
          this.initial_data[donnee] = data[donnee];
        }
      )
    }
  }
  requestClient(cb=(data)=>data) {
    this.setupClientData(
      data=>{
        this.clientData(data)
        
      },true
    )
  }
  updateEditDonneeClient(donnee, valeur) {
    this.edit_data.client[donnee] = valeur ? valeur : this.initial_data[donnee];
    const newState                = { edit_data: this.edit_data } 
    this.setState(newState)
    
  }
  componentDidMount() {
    this.setState({ mounted: true });
    if(!this.state.pays)
    {
      this.customGraphql('pays',null,["id","name","code,zone_livraison_id,zone_livraison{designation,id},display_commande"],data=>{
        if(data.errors){
          console.log('erreur pays data ,',data.errors)
        }else{
          this.updateSafelyState({pays:data.pays})
        }
      })
    }

    if(!this.state.civilites)
    {
      this.customGraphql('civilites',null,["id","designation"],data=>{
        if(data.errors){
          console.log('erreur civilites data ,',data.errors)
        }else{
          this.updateSafelyState({civilites:data.civilites})
        }
      })
    }
  }
  updateDonneesClient(cb = (data) => console.warn) {
    const form = {};
    Object.keys(this.state.edit_data.client).forEach((key) => {
      let value = this.state.edit_data.client[key]
        ? this.state.edit_data.client[key]
        : this.state.data[key];
      if (value && key == "adresses") {
        value = JSON.stringify(value);
      }
      if (key == "id") {
        value = this.app.client.id;
      }
      form[key] = value;
    });
    form["from_website"] = true;
    form["langue_id"] = this.app.getCurrentLang().id;
    this.ajaxPost("client", form, (response) => {
      cb(response);
      this.state.edit_data.edit_donnees_client = false;
      this.updateSafelyState({ edit_data: this.state.edit_data });
    });
  }
  changePassword(e) {
    this.updateEditDonneeClient("password", e.target.value);
  }
  render() {
    if(!this.state.data){
      this.requestClient(true)
    }
    if(this.state.getrecent == true){
        this.getFavoris(true)
    }
    if(this.state.getfavoris == true){
        this.getFavoris()
    }
    let   adresse_defaut            = null
    const adresses                = this.state.data?.adresses
    const checkadressedefaut      = adresses?.filter(
      adresse=>adresse.par_defaut==true
    ) 
    if(checkadressedefaut && checkadressedefaut.length){
      adresse_defaut = checkadressedefaut[0]
    }
    const editDonneesClientInput  =
      this.state.edit_data.edit_donnees_client == true;
    const editDonneesClientStyle  =
      styles[editDonneesClientInput ? "edit_input" : "show_input"];
    return (this.app.state.client && this.state.data)? (
      <div className="compte-view">
        <div
          className="section-compte-wrapper"
          id="mon-compte"
          style={
            this.app.section_compte_client.state.currentSection == "mon-compte"
              ? section
              : inactiveSection
          }
        >
          <div className="section-compte">
            <h3 className="label-section-compte">{this.app.getCurrentLangText("site_text_compte_mes_informations")}</h3>
            <div className="donnees">
              <div className="categorie-section-compte">
                <div className="label-categorie-section-compte">
                  <div>{this.app.getCurrentLangText("site_text_mes_coordonnes")}</div>

                  <div
                    className="icon"
                    onClick={(e) => {
                      this.editDataState(
                        "edit_donnees_client",
                        !this.state.edit_data.edit_donnees_client
                      );
                    }}
                  >
                    <img loading="lazy"
                      width=""
                      src={
                        "/assets/pictures/" +
                        (this.state.edit_data.edit_donnees_client
                          ? "save"
                          : "edit") +
                        ".svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="ligne-categorie-section-compte">
                  {
                    <input
                      disabled={!editDonneesClientInput}
                      defaultValue={this.state.data?.nom_complet}
                      onChange={(e) =>
                        this.updateEditDonneeClient(
                          "nom_complet",
                          e.target.value,data=>this.requestClient(()=>{},true)
                        )
                      }
                    />
                  }
                </div>
                <div className="ligne-categorie-section-compte">
                    {
                        !editDonneesClientInput ?
                            <input disabled={!editDonneesClientInput} style={editDonneesClientStyle} placeholder={this.state.data?.civilite}   />
                        : <select style={editDonneesClientStyle} onChange={e=>this.updateEditDonneeClient('civilite',e.target.value)}>
                            {
                              this.state.civilites?.map(
                                (civilite,idx)=>{
                                  return <option key={idx} value={civilite.designation} selected={this.state.edit_data.client.civilite == civilite.designation}>
                                    {
                                      civilite.designation  
                                    } 
                                  </option>
                                }
                              )
                            }  
                        </select> 
                    }
                </div>
                  <div className="ligne-categorie-section-compte">
                  {/* {JSON.stringify(this.state.data)} */}
                    {
                        !editDonneesClientInput ?
                            <input disabled={!editDonneesClientInput} style={editDonneesClientStyle} placeholder={this.state.data?.pays?.name}   />
                        : <select style={editDonneesClientStyle} onChange={e=>this.updateEditDonneeClient('pays_id',e.target.value)}>
                            {
                              this.state.pays?.map(
                                (pays,idx)=>{
                                  return <option key={idx} value={pays.id} selected={this.state.edit_data.client.pays_id == pays.id}>
                                    {
                                      pays.name  
                                    } 
                                  </option>
                                }
                              )
                            }  
                        </select>
                    }
                </div>
                <div className="ligne-categorie-section-compte">
                  {
                    <input
                      disabled={!editDonneesClientInput}
                      defaultValue={this.state.data?.email}
                      onChange={(e) =>
                        this.updateEditDonneeClient("email", e.target.value)
                      }
                    />
                  }
                </div>
                <div className="ligne-categorie-section-compte">
                    {
                        <input disabled={!editDonneesClientInput} style={editDonneesClientStyle} placeholder={this.state.data?.telephone}  onChange={e=>this.updateEditDonneeClient('telephone',e.target.value)}/>
                    }
                </div>
                {this.state.change_password ? (
                  <div
                    className="ligne-categorie-section-compte"
                    id="edit-password-container"
                  >
                    <label>
                      <h3>{this.app.getCurrentLangText("site_text_compte_modifier_votre_mot_de_passe")}:</h3>
                    </label>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "85% 15%",
                      }}
                    >
                      <input
                        style={{ padding: "1rem" }}
                        type={"password"}
                        className="form-input"
                        id="edit-password"
                        onChange={(e) => this.changePassword(e)}
                        placeholder="********"
                        name={"password-compte"}
                      />
                      <button
                        style={{ marginLeft: "1rem", padding: "1rem" }}
                        onClick={(e) => {
                          this.updateDonneesClient((response) => {
                            if (!response.errors) {
                              this.setState({ change_password: false });
                            }
                          });
                        }}
                      >
                        modifier
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="ligne-categorie-section-compte link">
                    <a href="?newpwd=1" className="mdpm_btn">
                      {this.app.getCurrentLangText("site_text_compte_modifier_le_mot_de_passe")}
                    </a>
                  </div>
                )}
                {editDonneesClientInput ? (
                  <button
                    style={styles.savebutton}
                    onClick={(e) => this.updateDonneesClient()}
                  >
                    VALIDER
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>




          <div className="section-compte">
            <h3 className="label-section-compte">{this.app.getCurrentLangText("site_text_compte_devise")}</h3>
            <div className="donnees">
              <div className="categorie-section-compte">
                
                <div className="label-categorie-section-compte">
                  <div>{this.app.getCurrentLangText("site_text_compte_devise_par_defaut")}</div>
                  <div
                    className="icon"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      this.editDataState(
                        "edit_devise_defaut",
                        !this.state.edit_data.edit_devise_defaut
                      );
                    }}
                  >
                    <img loading="lazy"
                      width=""
                      src={
                        "/assets/pictures/" +
                        (this.state.edit_data.edit_devise_defaut
                          ? "save"
                          : "edit") +
                        ".svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="ligne-categorie-section-compte">
                  {this.state.edit_data.edit_devise_defaut ? (
                    <SetDeviseDefaut
                      deviseChanged={(e) => {
                        this.requestClient(true);
                        this.editDataState("edit_devise_defaut", false);
                      }}
                    />
                  ) : (
                    <div className="info">
                      {this.state.data?.devise
                        ? this.state.data?.devise.designation
                        : this.app.getCurrentLangText("site_text_compte_aucune_devise_par_defaut")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>




          <div className="section-compte">
            <h3 className="label-section-compte">{this.app.getCurrentLangText("site_text_compte_mes_adresses")}</h3>
            <div className="donnees">
              <div className="categorie-section-compte">
                <div className="label-categorie-section-compte">
                  <div>{this.app.getCurrentLangText("site_text_compte_adresse_livraison_par_defaut")}</div>
                  <div
                    className="icon"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      this.editDataState(
                        "edit_adresse_defaut",
                        !this.state.edit_data.edit_adresse_defaut
                      );
                    }}
                  >
                    <img loading="lazy"
                      width=""
                      src={
                        "/assets/pictures/" +
                        (this.state.edit_data.edit_adresse_defaut
                          ? "save"
                          : "edit") +
                        ".svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="ligne-categorie-section-compte">
                  {this.state.edit_data.edit_adresse_defaut ? (
                    <SetAdresseDefaut
                      adresses={(adresses)?adresses:[]}
                      adresseChanged={(e) => {
                        this.requestClient(true);
                        this.editDataState("edit_adresse_defaut", false);
                      }}
                    />
                  ) : (
                    <div className="info">
                      {adresse_defaut
                        ? adresse_defaut.designation
                        : this.app.getCurrentLangText("site_text_compte_aucune_adresse_livraison_par_defaut")}
                    </div>
                  )}
                </div>
                <div className="donnees_section_compte"></div>
              </div>
              <div className="categorie-section-compte">
                <div className="label-categorie-section-compte">
                  <div>{this.app.getCurrentLangText("site_text_compte_voir_mes_adresses")}</div>
                  <div
                    className="icon"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      this.editDataState(
                        "edit_adresses",
                        !this.state.edit_data.edit_adresses
                      );
                    }}
                  >
                    <img loading="lazy"
                      width=""
                      src={
                        "/assets/pictures/" +
                        (this.state.edit_data.edit_adresses ? "save" : "edit") +
                        ".svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
                {this.state.edit_data.edit_adresses ? (
                  <AddAdresse onAdressAdded={e=>this.requestClient()} client_id={this.state?.data.id} />
                ) : (
                  ""
                )}
                <table class='table'>
                  <thead>
                    <tr>
                      <th>
                        {this.app.getCurrentLangText("site_text_adresse")}
                      </th>
                      <th>
                        {this.app.getCurrentLangText("site_text_code_postal")}
                      </th>
                      <th>
                        
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(adresses?adresses:[]).map((adresse, key) => {
                      return (
                          <tr>
                            <td>{adresse.designation ? adresse.designation : adresse.adresse}</td>
                            <td>{adresse.code_postal}</td>
                            <td>
                            {this.state.edit_data.edit_adresses ? (
                                <RemoveDataType
                                  id={adresse.id}
                                  type="adresse_client"
                                  confirmtext={this.app.getCurrentLangText("site_text_compte_supprimer")}
                                  onDeleted={(e) => {
                                    this.requestClient(true);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* {(adresses?adresses:[]).map((adresse, key) => {
                  return (
                    <div className="ligne-categorie-section-compte" key={key}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>{adresse.designation}</span>
                        <span>{adresse.code_postal}</span>
                        {this.state.edit_data.edit_adresses ? (
                          <RemoveDataType
                            id={adresse.id}
                            type="adresse_client"
                            confirmtext="Voulez vous vraiment supprimer cette adresse ?"
                            onDeleted={(e) => {
                              this.requestClient(true);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })} */}
              </div>
            </div>
          </div>
        </div>
        <div  
          className="section-compte-wrapper"
          id="mes-commandes"
          style={
            this.app.section_compte_client.state.currentSection ==
            "mes-commandes"
              ? section
              : inactiveSection
          }
        >
          <div className="section-compte">
            <h3 className="label-section-compte">{this.app.getCurrentLangText("site_text_compte_mes_commandes")}</h3>
            <div
              className="donnees"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {this.app.commandes.Commandes().map((commande) => {
                return <ResumeCommande commande={commande} />;
              })}
            </div>
          </div>
        </div>
        <div
          className="section-compte-wrapper"
          id="mes-favoris"
          style={
            this.app.section_compte_client.state.currentSection == "mes-favoris"
              ? section
              : inactiveSection
          }
        >
          <div className="section-compte">
            <h3 className="label-section-compte">{this.app.getCurrentLangText("site_text_compte_mes_favoris")}</h3>
            <div
              className="donnees"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {this.app.state.client.favoris?.map((produit,idx) => {
                return <ProduitAccroche app={this.app} key={idx} produit={produit}/>
              })}
            </div>
          </div>
        </div>
        <div
          className="section-compte-wrapper"
          id="recents"
          style={
            this.app.section_compte_client.state.currentSection ==
            "recents"
              ? section
              : inactiveSection
          }
        >
          <div className="section-compte">
            <h3 className="label-section-compte">{this.app.getCurrentLangText("site_text_compte_recemment_consulte")}</h3>
            <div
              className="donnees"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {this.app.state.client.recents?.map((produit,idx) => {
                return <ProduitAccroche app={this.app} key={idx} produit={produit}/>
              })}
            </div>
          </div>
        </div>
        <div
          className="section-compte-wrapper"
          id="code-promo"
          style={
            this.app.section_compte_client.state.currentSection ==
            "code-promo"
              ? section
              : inactiveSection
          }
        >
          <div className="section-compte">
            <h3 className="label-section-compte">{this.app.getCurrentLangText("site_text_compte_code_promo")}</h3>
            <div
              className="donnees"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {this.app.commandes.Commandes().map((commande) => {
                return <ResumeCommande commande={commande} />;
              })}
            </div>
          </div>
        </div>
      </div>
    ) : <Loader loading={true}/>;
  }
}
const inactiveSection = {
  display: "none",
};
const section = {
  display: "grid",
};
export default CompteView;
