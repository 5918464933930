import { LogOut } from "react-feather";
import AjaxComp from "../../data/AjaxComp";
import RaccourcisFavoris from "./raccourcis_favoris";
import RaccourcisPanier from "./raccourcis_panier";
import ResumeRaccourcisPanier from "./resume_panier";
import { NavLink } from "react-router-dom";
import UserPreferences from "./userpreferences";
import DeviseLangueChange from "./deviselangue";
import LangueChange from "./langue";




class UserActions extends AjaxComp {


    
    render(){

        return (

                <div className="useractions useractions-container">
                    <div className="useraction">
                        {/* <LangueChange/> */}
                        <UserPreferences/>
                    </div>
                    {/* <div className="useraction" >
                        <DeviseLangueChange/>
                    </div> */}
                    <div className="useractions">
                    <div className="useraction">
                        {
                            this.isLogged() && !(this.app.client && this.app.client.visitor)
                            ? <NavLink to={this.suffixe+"/compte"}>
                                <img  loading="lazy" src="/assets/pictures/user.svg" alt="" id="toggle-section-login" />
                            </NavLink>
                            // ? <span  onClick={e=>this.logoutUser()}>
                            //     <LogOut color="#fff"/>
                            // </span>
                            :<span  className="useraction-toggle">
                                <img  loading="lazy" src="/assets/pictures/user.svg" className="useraction-toggle" id="toggle-section-login" onClick={e=>this.switchPopupElement(e,'#section-login')}/>
                            </span>
                        }
                    </div>
                    <div className="useraction">
                        {
                            this.app.state.user
                                ?
                                    <div className="info">
                                        <span>{this.app.state.user.favoris.length}</span> 
                                    </div>
                                :
                                    ""
                        }
                        <img  loading="lazy" src="/assets/pictures/heart.svg" alt="" className="useraction-toggle" id="toggle-raccourcis-favoris" onClick={e=>this.switchPopupElement(e)} />
                        <div className="useractionview" id="raccourcis-favoris">
                            <RaccourcisFavoris/>
                        </div>
                    </div>
                    <div className="useraction">
                        <div className="info">
                            <span>{this.app.gestion_panier.nb_articles()}</span>
                        </div>
                        <img  loading="lazy" src="/assets/pictures/cart.svg" alt="" className="useraction-toggle" onClick={e=>this.switchPopupElement(e)} id="toggle-raccourcis-panier"/>
                        <div className="useractionview" id="raccourcis-panier">
                            <RaccourcisPanier/>
                            <div className="sepa"></div>
                            <ResumeRaccourcisPanier/>
                        </div>
                    </div>
                    </div>
                </div>
        )


    }
}

export default UserActions