import AjaxComp from "../../data/AjaxComp";
import "./accroche-produits.css";
import ListeProduitsAccroche from "../containers/listes/liste-produits-accroche";
import LabelZone from "../extra/labelZone";
// import { accroche_produits } from "../../myseed";
class AccrocheProduits extends AjaxComp {
  needs_pagination = true
  queryname   = 'produits'
  attrs_query = ['id','image','designation','nom_langue','sur_commande']
  default_conditions = {website:true,for_website:true}
  constructor(props){
      super(props)
      this.state = {...props}
      this.app.accroche_produits = this;
  }
  render() {
    
    return (
          <div className="zone" id="accroche-produits">
            <ListeProduitsAccroche labelZone={true} isfor={"produits"}></ListeProduitsAccroche>
          </div>
    );
  }
}
export default AccrocheProduits;
