import AjaxComp from "../../data/AjaxComp";
class FootingHook extends AjaxComp {
  render() {
    return (
      <div className="hook">
        <img loading="lazy"
          className="overlay"
          src="/assets/pictures/footinghook.png"
          alt="rejoignez nous"
        />
        <img loading="lazy"
          className="imageeffect"
          src="/assets/pictures/goldpowder.svg"
          alt="rejoignez nous"
        />
        <div className="content">
          <div>{this.app.getCurrentLangText("site_text_pour_devenir_partenaire")}...</div>

          <div>
            {this.app.getCurrentLangText("site_text_c_est")} <a className="maintenant_txt" href="">{this.app.getCurrentLangText("site_text_maintenant")}</a>
          </div>

          <button className="useraction-toggle" id='toggle-section-partenaire' onClick={e=>this.switchPopupElement(e,'#section-partenaire')} >
            {this.app.getCurrentLangText("site_text_rejoignez_la_famille_nahyel")}
          </button>
        </div>
      </div>
    );
  }
}
export default FootingHook;
