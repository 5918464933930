import AjaxComp from "../../data/AjaxComp";
import SearchBar from "./searchbar";
import UserActions from "./useractions";
import ContactAndLangs from "./contactandlangs";
import ListeIcones from "../containers/listes/liste-icones";
import "./heading.css";
import { useTranslation } from "react-i18next";
import "./heading_mobile.css";
import SectionCategories from "../sections/section_categories";
class Heading extends AjaxComp {
  constructor() {
    super();

    this.state.createurs = [];
    this.customGraphql(
      "categories",
      {
        menu_website: true,
        langue_id: this.app.getCurrentLang().id,
        website: true,
      },
      ["id","designation","nom_langue","parent{id,designation}"],
      (data) => {
        if (data.categories) {
          this.state.categories_menu = data.categories;
          this.updateSafelyState({ categories_menu: data.categories });
        }
      }
    );
    this.customGraphql(
      "createurs",
      { is_nahyel: true, active: true },
      ["id,designation,photo"],
      (data) => {
        if (data.createurs) {
          this.state.createurs = data.createurs;
          this.updateSafelyState({ createurs: data.createurs });
        }
      }
    );
  }
  render() {
    return (
      <div className="heading">
        {/* <div className="heading-promoband">
          % NOUVELLE PROMO CHEZ NAHYEL DE 25 %
        </div> */}
        <div className="heading-socialband_container">
          <div className="heading-socialband">
            <ListeIcones></ListeIcones>
            <ContactAndLangs></ContactAndLangs>
          </div>
        </div>
        <div className="heading-navband_container">
          <div className="heading-navband">
            <div className="heading-navband-mobile">
              <div className="flex toggles">
                <span
                  className="togglemenu cursor-pointer "
                  onClick={(e) =>
                    this.switchPopupElement(e, "#section-categories")
                  }
                >
                  <img
                    src="/assets/pictures/menu.svg"
                    alt=""
                    id="toggle-menu-icon"
                    className="togglemenu-icon togglemenu-text"
                  />
                </span>

                <div id="shops">
                  <ul>
                    <li>
                      <img
                        loading="lazy"
                        className="cursor-pointer "
                        src={"/assets/pictures/home.svg"}
                        alt=""
                        style={{ width: "1.5em" }}
                      />
                      {/* <span className="fa cursor-pointer fa-store my-1" style={{fontSize:"1.5rem"}}></span> */}
                    </li>
                    <li>
                      <ul className="suggestion_shop">
                        {this.state.createurs?.map((createur, idx) => {
                          return (
                            <>
                              <li key={idx} className="px-4 ">
                                <a href={this.suffixe + "/shop/" + createur.id}>
                                  {createur.designation}
                                </a>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <SectionCategories></SectionCategories>
              <div className="shopsandlogo">
                <div className="logobox">
                  <a href={this.suffixe + "/"}>
                    <img
                      src="/assets/pictures/logo.svg"
                      alt="NAHYEL"
                      className="logo"
                    />
                  </a>
                </div>
              </div>

              <UserActions></UserActions>
            </div>
            <div className="d-flex justify-content-center list_categorie">
              {this.state.categories_menu?.map((category, idx) => {
                return (
                  <>
                    <span className="p-2 text-center text-white" key={idx}>
                      <a
                        href={
                          this.suffixe +
                          "/categories?search=" +
                          category.id +"&stepp=1"
                        }
                        className="shopsList_link"
                      >
                        {category.nom_langue}
                      </a>
                    </span>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <SearchBar></SearchBar>
      </div>
    );
  }
}

export default Heading;
