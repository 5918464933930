import AjaxComp from "../../data/AjaxComp";

class UserPreferences extends AjaxComp{

    constructor(props){
        super(props)
        this.app.userpreferences = this
        this.customGraphql('langues',{website:true},['id,nom,image,diminutif'],(data)=>{
            if(data.langues){
                this.updateSafelyState({langues:data.langues})
            }
        })
        this.customGraphql(
            'devises',{website:true},['id,designation,signe'],(data)=>{
                if(data.devises){
                    this.updateSafelyState({devises:data.devises})
                }
            }
        )
        this.app.userpreferences.updateSafelyState({changelangordevise:false})
    }
    requestNewLangOrDevise(){
        this.app.updateSafelyState({changelangordevise:true})
        this.app.userpreferences.updateSafelyState({changelangordevise:true})
        this.app.updateSafelyState({loading:true})
        // this.app.forceUpdate()
        console.info(this.app.userpreferences.state)
        console.info(this.app.state)
    }
    
    render(){
        return  <div className="cursor-pointer d-flex align-items-center text-white">
            <span className="px-1 set-langueoption">
                <img src={this.app.getCurrentLang().image} alt=""  onClick={e=>{this.requestNewLangOrDevise()}}/>
                <ul>
                    {
                        this.state.langues?.map(
                            langue=>{
                                return <li key={langue.id} onClick={e=>{this.app.setCurrentLang(langue)}}>{langue.nom}</li>
                            }
                        )
                    }
                </ul>
            </span>
                /
            <span className='align-middle px-1 set-deviseoption'>
                <span  onClick={e=>{this.requestNewLangOrDevise()}}>
                    {this.app.getCurrentDevise().signe}
                </span>
                <ul>
                    {
                        this.state.devises?.map(
                            devise=>{
                                return <li key={devise.id} onClick={e=>{this.app.setCurrentDevise(devise)}}>{devise.designation}</li>
                            }
                        )
                    }
                </ul>
            </span>
        </div>
    }
}
export default UserPreferences
