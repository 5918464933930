import AjaxComp from "../data/AjaxComp";
import Base from "./base";
import ZoneCommande from "../layout/zones/commande";

class Commande extends AjaxComp {


    constructor(props){
        super(props)
        this.state = {...props}
    }

    componentDidMount(){
        if(!this.isLogged()){
            this.goTo(this.suffixe+"/");
        }
    }
    render(){
        return (
            <>
                <Base noHeader={true}>
                    <ZoneCommande/>
                </Base>
            </>



        )
    }


}

export default Commande