import { Link } from "react-router-dom"
import AjaxComp from "../../data/AjaxComp"
import { categories } from "../../myseed"
import "./section_categories.css"
class SectionCategories extends AjaxComp {
    
    default_multiple = true
    default_conditions=[
        {is_on_website:true,website:true,langue_id:this.app.getCurrentLang().id},
        null,
    ]
    querynames = [
        'categories'
    ]
    attrs_queries = [
        ["id","designation","nom_langue","image","enfants{id,designation,nom_langue,image,enfants{id,designation,nom_langue,image}}"],
    ]
    updateCurrent(idx){
        
        this.updateSafelyState({current:idx})
        this.updateSelected(idx)

        
    }
    updateSelected(idx=null){
        const current = (idx!=null) ? idx : (this.state.current?this.state.current:0)
        if(current < this.state.data.categories.length){
            
            this.updateSafelyState(
                {current} 
            )
            this.forceUpdate()
        }
    }
    getCurrent(){

        return this.state.data.categories ? this.state.data.categories[this.state.current] : null

    }
    unselect(){
        this.updateSafelyState(
            {current:null,selected:null}
        )
    }
    constructor(props){
        super(props)
        this.state.current = 0
        this.state.selected = null
        this.customGraphql('categories',{website:true,langue_id:this.app.getCurrentLang().id},this.attrs_queries,data=>{
            
            this.updateSafelyState({data})
            
        })
    }
    
    render(){
        // console.info(this.state.current)
        return (
            <div id="section-categories" >
                <div id="section-categories-form">
                    <div id='section-categories-top'>
                        <span style={{color:'red'}} id='close-section-categories' onClick={(e) =>this.switchPopupElement(e, "#section-categories")}>
                            <img loading="lazy" src={"/assets/pictures/close.svg"} alt="" style={{width:'12px'}}/>
                        </span>
                        <div className="logobox">
                            <img loading="lazy" src={"/assets/pictures/logo.svg"} alt=""/>
                        </div>
                    </div>
                    <div id='section-categories-list'>
                        <div className='section-categories-list'>
                            {this.state.data.categories && this.state.data.categories.map((category,idx)=>{
                                return (
                                    <div key={idx} className={"section-categories-item "+(this.state.current === idx ? 'current' : '')} onClick={e=>this.updateCurrent(idx)}>
                                    {/* <img loading="lazy" src={category.image} alt=""/> */}
                                        <span>
                                            {category.nom_langue}
                                        </span>
                                    </div>
                                )
                            })} 
                        </div>
                    </div>
                    <div id='section-categories-list'>
                        <div className='section-categories-list-enfants'>
                            {this.getCurrent() && this.getCurrent().enfants.map((category,idx)=>{
                                return (
                                    <div key={idx} className="section-categories-item">

                                        {/* <img loading="lazy" src={category.image} alt=""/> */}
                                        <Link to={this.suffixe+"/categories?seachCat="+category.id+'&search='+this.getCurrent()?.id+"&stepp=2"}>
                                            {category.nom_langue}
                                        </Link>
                                    </div>
                                )
                            })} 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SectionCategories