import AjaxComp from "../data/AjaxComp";
import LabelZone from "../layout/extra/labelZone";
class RegisterForm extends AjaxComp {
  credentials = {
    email: null,
    password: null,
    nom_complet: null,
    telephone: null,
    from_website: true,
  };

  constructor(props) {
    super(props);
    this.state = { ...props };
    this.state.showPassword = false;
    this.state.display_error = null;
    this.state.display_message = null;

    this.customGraphql('pays',null,["id","name","code,zone_livraison_id,zone_livraison{designation,id},display_commande"],data=>{
      if(data.errors){
        console.log('erreur pays data ,',data.errors)
      }else{
        this.updateSafelyState({pays:data.pays})
      }
    })


    this.customGraphql('civilites',null,["id","designation"],data=>{
      if(data.errors){
        console.log('erreur civilites data ,',data.errors)
      }else{
        this.updateSafelyState({civilites:data.civilites})
      }
    })



  }
  openFacebookPopup() {
    var width = 600; // Popup window width
    var height = 400; // Popup window height
    var left = window.innerWidth / 2 - width / 2;
    var top = window.innerHeight / 2 - height / 2;

    // Open the popup with the specified dimensions and route
    var popup = window.open(
      this.apiUri + "/client/auth/facebook",
      "Facebook Register",
      "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top
    );

    // Callback function to handle popup results
    function handlePopupResult(result) {
      // Handle the result in the parent window
      if (result === "success") {
        // The Facebook register was successful
        // Implement your success handling logic here
      } else if (result === "failure") {
        // The Facebook register failed
        // Implement your failure handling logic here
      }
    }

    // Poll the popup window for updates
    var popupCheckInterval = setInterval(function () {
      if (popup.closed) {
        clearInterval(popupCheckInterval);
        handlePopupResult(popup.result);
      }
    }, 500);
  }
  toggleShowPassWord() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  setEmail(value) {
    this.credentials.email = value;
  }
  setCivilite(value) {
    this.credentials.civilite = value;
  }
  setVille(value) {
    this.credentials.ville = value;
  }
  setPays(value) {
    this.credentials.pays_id = value;
  }
  setNomComplet(value) {
    this.credentials.nom_complet = value;
  }
  setTelephone(value) {
    this.credentials.telephone = value;
  }
  setAdresse(value) {
    this.credentials.adresse = value;
  }
  setCodePostal(value) {
    this.credentials.code_postal = value;
  }
  setPassword(value) {
    this.credentials.password = value;
  }
  register(e) {
    let sendIt = true
    e.preventDefault();
    if(this.isLogged() && this.app.client.visitor){
      this.credentials.visitor = this.app.client.id
    }
    this.credentials.adresses = JSON.stringify([
      {
        designation:this.credentials.adresse,
        adresse:this.credentials.adresse,
        code_postal:this.credentials.code_postal,
        ville:this.credentials.ville,
        telephone:this.credentials.telephone ?? "",
        email:this.credentials.email ?? "",
        nom_complet:this.credentials.nom_complet ?? "",
        par_defaut:true
      }
    ])
    if(!this.credentials.code_postal)
    {
      sendIt = false
      this.app.setDisplayError(this.app.getCurrentLangText("site_text_code_postal_required"))
    }
    if(!this.credentials.adresse)
    {
      sendIt = false
      this.app.setDisplayError(this.app.getCurrentLangText("site_text_adresse_required"))
    }
    if(!this.credentials.ville)
    {
      sendIt = false
      this.app.setDisplayError(this.app.getCurrentLangText("site_text_ville_required"))
    }
    if(sendIt)
    {
      this.credentials.langue_id = this.app.getCurrentLang().id;
      this.ajaxPost("client/register", this.credentials, (response) => {
        try {
          if (response.token) {
            localStorage.setItem("apiToken", response.token);
            this.history.push("/compte");
            window.hideHideable();
          }
          if (response.errors) {
            this.app.showDisplayError(response.errors.toString());
            //this.setState({ display_error: response.errors });
          } else {
            this.switchPopupElement(null,'#section-register')
            this.setDisplayMessage(
              this.app.getCurrentLangText("site_text_votre_inscription_est_un_succes ") +
              this.credentials.email
            );
          }
          if (response.message) {
            this.setState({ display_message: response.message });
          }
        } catch (e) {
          console.warn(response);
          console.warn("erreur resultats register ", e);
        }
      });
    }
  }

  render() {
    return this.isLogged() && !(this.app.client && this.app.client.visitor) ? (
      ""
    ) : (
      <div id="registerform">
        <form action="" className="zone">
          {this.state.display_message
            ? this.showDisplayMessage(this.app.getCurrentLangText("site_text_inscription"))
            : ""}
          {this.state.display_error ? this.showDisplayError(this.app.getCurrentLangText("site_text_inscription")) : ""}
          <LabelZone label={this.app.getCurrentLangText("site_text_benvenue_inscrivez_vous")} whithlogo={true} />
          <div className="formfield">
            <div className="icon">
              <img loading="lazy" src="/assets/pictures/user-client-color.svg" alt="" />
            </div>
            <div className="input">
              <input
                type="text"
                placeholder={this.app.getCurrentLangText("site_text_nom_complet")}
                name="nom_complet"
                onChange={(e) => this.setNomComplet(e.target.value)}
              />
            </div>
          </div>
          <div className="formfield">
            <img loading="lazy"
              src="/assets/pictures/icon-mail-color.svg"
              className="icon"
              alt=""
            />

            <div className="input">
              <input
                type="email"
                placeholder={this.app.getCurrentLangText("site_text_adresse_email")}
                name="email"
                onChange={(e) => this.setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="formfield">
            <img loading="lazy"
              src="/assets/pictures/icon-phone-color.svg"
              className="icon"
              alt=""
            />
            <div className="input">
              <input
                type="tel"
                placeholder={this.app.getCurrentLangText("site_text_telephone")}
                name="telephone"
                onChange={(e) => this.setTelephone(e.target.value)}
              />
            </div>
          </div>
          <div className="formfield">
            <img loading="lazy"
              src="/assets/pictures/icon-country-color.svg"
              className="icon"
              alt=""
            />
            <div className="input">
              <select
                onChange={(e) => this.setPays(e.target.value)}
                style={{ width: "100%" }}
                placeholder={this.app.getCurrentLangText("site_text_pays")}
              >
                <option>{this.app.getCurrentLangText("site_text_pays")}</option>
                {this.state.pays?.map((pays, key) => {
                  return <option value={pays.id} key={key}>{pays.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="formfield">
            <img loading="lazy"
              src="/assets/pictures/icon-adresse-color.svg"
              className="icon"
              alt=""
            />
            <div className="input">
              <input
                type="texte"
                placeholder={this.app.getCurrentLangText("site_text_adresse")}
                name="adresse"
                onChange={(e) => this.setAdresse(e.target.value)}
              />
            </div>
          </div>
          <div className="formfield">
            <img loading="lazy"
              src="/assets/pictures/icon-adresse-color.svg"
              className="icon"
              alt=""
            />
            <div className="input">
              <input
                type="texte"
                placeholder={this.app.getCurrentLangText("site_text_ville")}
                name="ville"
                onChange={(e) => this.setVille(e.target.value)}
              />
            </div>
          </div>
          <div className="formfield">
            <img loading="lazy"
              src="/assets/pictures/icon-code-postal-color.svg"
              className="icon"
              alt=""
            />
            <div className="input">
              <input
                type="texte"
                placeholder={this.app.getCurrentLangText("site_text_code_postal")}
                name="code_postal"
                onChange={(e) => this.setCodePostal(e.target.value)}
              />
            </div>
          </div>
          <div className="formfield">
            <img loading="lazy" src="/assets/pictures/lock.svg" className="icon" alt="" />
            <div className="input withicon">
              <input
                type={this.state.showPassword ? "text" : "password"}
                placeholder={this.app.getCurrentLangText("site_text_mot_de_passe")}
                name="password"
                onChange={(e) => this.setPassword(e.target.value)}
              />

              <img loading="lazy"
                className="cursor-pointer icon"
                src="/assets/pictures/view.svg"
                alt=""
                onClick={(e) => this.toggleShowPassWord()}
              />
            </div>
          </div>
          <div className="formfield">
            <img loading="lazy"
              src="/assets/pictures/user-client-color.svg"
              className="icon"
              alt=""
            />
            <div className="input">
              <select
                onChange={(e) => this.setCivilite(e.target.value)}
                style={{ width: "100%" }}
                placeholder={this.app.getCurrentLangText("site_text_civilite")}
              >
                <option>{this.app.getCurrentLangText("site_text_civilite")}</option>
                {this.state.civilites?.map((civilite, key) => {
                  return <option key={key}>{civilite.designation}</option>;
                })}
              </select>
            </div>
          </div>
          <button onClick={(e) => this.register(e)}>{this.app.getCurrentLangText("site_text_sinscrire")}</button>
        </form>
        <form action="" className="zone">
          <div className="label_classic">{this.app.getCurrentLangText('site_text_connecter_directement_avec')} :</div>
          <div className="liste-options-connexion">
            <div className="option-connexion">
              <img loading="lazy" src="/assets/pictures/icon-google-color.svg" alt="" />
            </div>
            <div
              className="option-connexion"
              onClick={(e) => this.openFacebookPopup()}
            >
              <img loading="lazy" src="/assets/pictures/icon-facebook-color.svg" alt="" />
            </div>
            <div className="option-connexion d-none">
              <img loading="lazy" src="/assets/pictures/icon-apple-color.svg" alt="" />
            </div>
          </div>
        </form>

        {/* <div className="sepa faded"></div>
                <div className="zone">
                    <div className="label">
                        <h3>
                            j'ai déja un compte
                        </h3>
                    </div>
                    <button className="lined useraction-toggle" id="toggle-section-login">
                        SE CONNECTER
                    </button>
                </div> */}
      </div>
    );
  }
}

export default RegisterForm;
