import AjaxComp from "../../data/AjaxComp";
class CompteSidebar extends AjaxComp {
  constructor(props) {
    super(props);
    this.state = { ...props ,currentSection:'mon-compte'};
  }
  switchSection(id) {
    // alert(id)
    this.app.section_compte_client.updateSafelyState({
      currentSection: id,
    });
    this.updateSafelyState({
      currentSection: id,
    });
  }

  componentDidMount() {

    this.setState({ mounted: true });
    if(this.state)
    {
      console.log("ici voir => ",this.state.currentSection);
      this.app.getCurrentLangText("site_text_compte_mon_compte");
      //this.switchSection("mon-compte");
    }
  }

  render() {
    return (
      <div className="compte-sidebar">
        <div
          className={
            "compte-sidebar-item " +
            (this.state.currentSection == "mon-compte" ? "current" : "")
          }
          onClick={(e) => this.switchSection("mon-compte")}
        >
          <img
            loading="lazy"
            src="/assets/pictures/user-client-color.svg"
            alt=""
            className="icon"
          />
          <div className="compte-sidebar-label text-uppercase">{this.app.getCurrentLangText("site_text_compte_mon_compte")}</div>
          <img
            loading="lazy"
            src="/assets/pictures/caret-right.svg"
            className="caret"
            alt=""
          />
        </div>
        <div
          className={
            "compte-sidebar-item " +
            (this.state.currentSection == "mes-commandes" ? "current" : "")
          }
          onClick={(e) => this.switchSection("mes-commandes")}
        >
          <img
            loading="lazy" src="/assets/pictures/commande.svg" alt="" className="icon" />

          <div className="compte-sidebar-label">{this.app.getCurrentLangText("site_text_compte_mes_commandes")}</div>

          <img
            loading="lazy"
            src="/assets/pictures/caret-right.svg"
            className="caret"
            alt=""
          />
        </div>
        <div
          className={
            "compte-sidebar-item " +
            (this.state.currentSection == "mes-favoris" ? "current" : "")
          }
          onClick={(e) => this.switchSection("mes-favoris")}
        >
          <img
            loading="lazy" src="/assets/pictures/heart-color.svg" className="icon" alt="" />
          <div className="compte-sidebar-label">{this.app.getCurrentLangText("site_text_compte_mes_favoris")}</div>
          <img
            loading="lazy"
            src="/assets/pictures/caret-right.svg"
            alt=""
            className="caret"
          />
        </div>
        <div
          className={
            "compte-sidebar-item " +
            (this.state.currentSection == "recents" ? "current" : "")
          }
          onClick={(e) => this.switchSection("recents")}
        >
          <img
            loading="lazy" src="/assets/pictures/clock-color.svg" className="icon" alt="" />
          <div className="compte-sidebar-label">{this.app.getCurrentLangText("site_text_compte_recemment_consulte")}</div>
          <img
            loading="lazy"
            src="/assets/pictures/caret-right.svg"
            alt=""
            className="caret"
          />
        </div>
        <div className={
            "compte-sidebar-item " +
            (this.state.currentSection == "code-promo" ? "current" : "")
          }
          onClick={(e) => this.switchSection("code-promo")}>
          <img
            loading="lazy" src="/assets/pictures/promo.svg" className="icon" alt="" />
          <div className="compte-sidebar-label">{this.app.getCurrentLangText("site_text_compte_code_promo")}</div>
        </div>
        <div className="compte-sidebar-item logout" onClick={(e) => this.logoutUser()}>
          <img
            loading="lazy" src="/assets/pictures/logout.svg" className="icon" alt="" />

          <div
            className="compte-sidebar-label"
            
          >
            {this.app.getCurrentLangText("site_text_compte_deconnexion")}
          </div>
        </div>
      </div>
    );
  }
}

export default CompteSidebar;
