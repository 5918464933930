import React, { Component } from 'react';
import './MToast.css';
import { AlertCircle, AlertTriangle, Info, X, CheckCircle, XCircle } from 'react-feather';


const styles = {
  info: {
    backgroundColor: 'skyblue',
    color: 'white',
  },
  nahyel:{
    background: "white"
  },
  danger: {
    backgroundColor: 'orange',
    color: 'white',
  },
  error: {
    backgroundColor: "#d34d50",
    color: 'white',
  },
  dark: {
    backgroundColor: 'black',
    color: 'white',
  },
  light: {
    backgroundColor: 'white',
    color: 'black',
  },
}

const icons = {
    info: <Info />,
    nahyel: null, // Add the appropriate icon component here
    danger: <AlertCircle />,
    error: <AlertTriangle />,
    dark: <XCircle />,
    light: <CheckCircle />,
}

class MToast extends Component {
    constructor(props) {
      super(props);
      // this.props.disappear = props.disappear ?? 1500
      this.state = {
        visible: true,
      };
      
      if (props.disappear !== null) {
        console.info('we have to disappear after ',props.disappear)
        // Automatically hide the toast after the specified delay in seconds
        this.hideToastWithDelay(props.disappear);
      }
    }
  
    hideToastWithDelay = (delay = 1500) => {
      setTimeout(() => {
        console.info('have to disappear now')
        this.setState({ visible: false });
        if (this.props.onDismiss) {
          this.props.onDismiss();
        }
      }, delay * 1000);
    }
  
    render() {
      const { message, styleType, title } = this.props;
      const { visible } = this.state;
      console.info('we should not be visible')
      if (visible == false) {
        return null;
      }
  
      const titleText = title || styleType;
  
      return (
        <>
          {(message && visible)? (
            <div className={"m-toast "+styleType} style={{ ...styles[styleType], zIndex:  10 }}>
              <div className="toast-title-bar">
                <div className="toast-title">
                    {icons[styleType] ? icons[styleType] : <Info></Info>}
                    {titleText}
                </div>
                <X className="close-button" style={{cursor:'pointer'}} onClick={() => this.hideToastWithDelay()}>X</X>
              </div>
              <div className="toast-message">{message}</div>
            </div>
          ) : ''}
        </>
      );
    }
  }
class MToaster {
    constructor() {
      this.toasts = [];
    }
  
    create(message, styleType = 'info', title = null, disappear = null, onDismiss = null) {
      const toast = <MToast title={title} message={message} styleType={styleType} disappear={disappear} onDismiss={onDismiss} index={this.toasts.length + 1} />;
      this.toasts.push(toast);
  
      return toast;
    }
  
    removeToast(toast) {
      const index = this.toasts.indexOf(toast);
      if (index !== -1) {
        this.toasts.splice(index, 1);
      }
    }
  }
  

export { MToast, MToaster };
