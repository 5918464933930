import Loader from "../../Loader";
import AjaxComp from "../../data/AjaxComp";
class ResumeRaccourcisPanier extends AjaxComp{

    constructor(props){
        super(props)
        this.app.resume_raccourcis_panier = this
    }   
    render(){
        
        let data          =   this.app.gestion_panier.state.panier
        return data ? <>
            <h4 className="info-panier" id='total'>
                <span>
                    {this.app.getCurrentLangText("site_text_panier_sous_total")} TTC
                </span>
                <div className="span">
                    {this.formatNumber(this.app.gestion_panier.total_commande(data.articles??[]))}  {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''} 
                </div>
            </h4>
            {
                data.tva ? 
                    <div className="info-panier faded">
                        <span>
                            {this.app.getCurrentLangText("site_text_panier_tva_inclu")}
                        </span>
                        <div className="span">
                            {data.tva}%
                        </div>
                    </div>
                : ""
            }
        </> : <Loader loading={true}/>
    }
}
export default ResumeRaccourcisPanier