
import AjaxComp from "../../data/AjaxComp";

class Conditions extends AjaxComp {


    constructor(props){
        super(props)
    }
    render(){
        return (

            <div className="conditions-wrapper" style={{fontSize:"12px"}}>
                <div className="copyright">
                {this.app.getCurrentLangText("site_text_nahyel_est_une_marque_deposee_de")}  <span><a href="www.nahyelconceptstore.com">www.nahyelconceptstore.com</a></span>
                </div>
                <div className="conditions">
                    <a href={this.suffixe+"/"}>
                    {this.app.getCurrentLangText("site_text_confidentialite_et_cookies")}
                    </a>
                    <span className="line">&#10072;</span>
                    <a href={this.suffixe+"/"}>
                    {this.app.getCurrentLangText("site_text_mentions_sur_les_termes_et_conditions_generales")}
                    </a>
                </div>
            </div>

        )
    }


}

export default Conditions