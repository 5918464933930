import AjaxComp from "../../data/AjaxComp";
import LabelZone from "../extra/labelZone";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "./blog.css";
import { Link } from "react-router-dom";
import Loader from "../../Loader";
import Header from "../top/header";
import { Heart } from "react-feather";

class Blog extends AjaxComp {
  needs_pagination = true
  queryname   = 'blogs'
  attrs_query = ["id,nb_article,image,nb_likes,likes{client{id,nom_complet}},designation,langue_nom,description,baniere,is_active,is_on_website,articles{id,resume,image,date_publication,titre,contenu,tags{id},blog_id,commentaires{id},statusarticle{id},author{id},blog{id},created_at_fr,updated_at_fr,created_at_fr},created_at,created_at_fr,updated_at,updated_at_fr,deleted_at,deleted_at_fr"]
  default_conditions = null
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.get()
  }
  get(){
    this._update(
      data=>{
        const blogs  = data.hasOwnProperty('blogspaginated') ? data['blogspaginated']['data'] : data['blogs']
        this.updateSafelyState({rawdata:data,data:{blogs}})
      }
    )
  }
  nouvel_abonnement(blog_id){
    const client_id     = this.app.state.client_id 
    const data          = {blog_id,client_id,langue_id: this.app.getCurrentLang().id}
    this.ajaxPost(
      'blog/abonnement', data,response=>{
        console.error(response)
        const {errors,message} = response
        if(errors){
          this.setDisplayError(errors)
        }
        if(message){
          this.setDisplayMessage(message)
        }
      }
    )

  }
  loopUntilArticles =(data)=>{
    let object  = {articles:[]}
    const keys    = Object.keys(data)
    if(keys.includes('articles')){
      object = data
    }else{
      let match = false
      keys.forEach(
        key=>{
          if(!match){
            object = this.loopUntilArticles(data[key])
            if(object.articles.length){
              match = true
            }
          }
        }
      )
    }
    return object
  }
  likeBlog(id){
    if(this.app.state.client_id)
    {
        this.ajaxPost(
            'blog/like',{blog_id:id,client_id:this.app.state.client_id,langue_id: this.app.getCurrentLang().id},data=>{
              this.get()

              this.setupClientData(data=>{
                this.saveClientModel(data)
                this.app.updateSafelyState({client:data})
              },true)
            }
        )
    }
  }
  render() {
    let blogs = this.state.data?.blogs
    blogs     = blogs ? blogs : []
    
    return <>
          {
            (blogs.length ) ? 

              blogs.map(
                blog=>{
                  const {articles,nb_article} = blog ? blog :{articles:[]}
                  const first   =  nb_article > 0 ? articles[0] : null 
                  const second  =  nb_article > 1 ? articles[1] : null 
                  return  <>
                      <Header title={blog.nom_langue} subtitle={blog.description} customHeader={this.apiUri+"/"+blog.image}></Header>
                      <div onClick={e=>this.likeBlog(blog.id)} style={{display:'flex',justifyContent:'center'}}>
                        <div>
                          <Heart></Heart>
                        </div>
                      </div>
                      {first ? <div className="section_blu">
                      <div className="zone">
                          {/* {this.state.display_error   ? this.showDisplayError("Erreur  :") : ""}
                          {this.state.display_message ? this.showDisplayError("Message :") : ""} */}
                          <LabelZone label={blog.nom_langue} />
                          <div className="label-zone">
                            {this.app.state.client_id?<button style={{maxWidth:"200px"}} onClick={e=>this.nouvel_abonnement(blog.id)}>s'abonner</button>:""}
                          </div>
                          <div className="section_inner_banner">
                            <img loading="lazy" src={this.apiUri+"/"+first.image} alt="" srcset="" />
                            <div className="section_inner_banner_inner" >
                              <div className="title_head">
                                <span className="title">{first?.titre}</span>
                                <span className="date">{this.timeAgoString(first?.created_at_fr)}</span>
                              </div>
                              <div className="blog_content_speech">
                                {first?.resume}
                              </div>
                              <hr/>
                              <Link to={this.suffixe+"/article/"+first.id}>{this.app.getCurrentLangText("site_text_lire_texte")} ..</Link>
                            </div>
                          </div>
                        </div>
                      </div> : ""}

                      { second  ?  <div className="section_white">
                        <div className="zone">
                          <div className="section_inner_banner reverse_block">
                            <img loading="lazy" src={this.apiUri+"/"+second.image} alt="" srcset="" />
                            <div className="section_inner_banner_inner">
                              <div className="title_head">
                                <span className="title">
                                  {second?.titre}
                                </span>
                                <span className="date">{this.timeAgoString(second?.created_at_fr)}</span>
                              </div>
                              <div className="blog_content_speech">
                                {second?.resume}
                              </div>
                              <hr/>
                              <Link to={this.suffixe+"/article/"+second.id}>{this.app.getCurrentLangText("site_text_lire_texte")} ..</Link>
                            </div>
                          </div>
                        </div>
                      </div>:""}
                      <div className="section_blu">
                        <div className="zone">
                          <LabelZone label="Explorez plus d'articles"></LabelZone>
                          <div className="section_card_container">
                            <Splide
                              hasTrack={false}
                              options={{
                                width: "100%",
                                perPage: 4,
                                dragMinThreshold: 5,
                                gap: "4em",
                                pagination: true,
                                breakpoints: {
                                  1140: {
                                    perPage: 3,
                                  },
                                  740: {
                                    perPage: 2,
                                    pagination: false,
                                  },
                                  600: {
                                    focus: "center",
                                    perPage: 1.2,
                                    perMove: 0.5,
                                  },
                                },
                              }}
                              className="accroche-produits-container"
                            >
                              <div className="custom-wrapper">
                                <SplideTrack>
                                  {
                                    articles.map(
                                      (article,idx)=>{
                                        return <SplideSlide key={idx}>
                                          <div className="card_in_section">
                                            <img loading="lazy"
                                              src={this.apiUri+"/"+article.image}
                                              alt=""
                                              srcset=""
                                            />
                                            <div className="infos_card">
                                              <div className="title_card">  
                                                {article.titre} 
                                              </div>
                                              <div className="card_text">
                                                {article.resume}
                                              </div>
                                              <hr/>
                                              <Link to={this.suffixe+"/article/"+article.id}>{this.app.getCurrentLangText("site_text_lire_texte")} ..</Link>
                                              <div className="date">
                                                {this.timeAgoString(article?.created_at_fr)}
                                              </div>
                                            </div>
                                          </div>
                                        </SplideSlide>
                                      }
                                    )
                                  }
                                </SplideTrack>
              
                                <div className="splide__arrows">
                                  <button className="splide__arrow splide__arrow--prev">
                                    <svg
                                      width="31"
                                      height="19"
                                      viewBox="0 0 31 19"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.38636 10.7698C5.2803 11.5995 6.18939 12.4293 7.08333 13.2591C8.41667 14.4967 9.75 15.7202 11.0682 16.9578C11.7955 17.6328 11.5379 18.6736 10.5985 18.9408C10.1136 19.0814 9.67424 18.9689 9.31061 18.6314C7.29545 16.7468 5.25 14.8482 3.20455 12.9637C2.29545 12.1199 1.38636 11.2761 0.477273 10.4323C-0.159091 9.84158 -0.159091 9.18059 0.477273 8.57586C3.40152 5.84751 6.34091 3.13322 9.26515 0.418936C9.84091 -0.101419 10.6136 -0.129546 11.1288 0.348618C11.6591 0.826782 11.6439 1.5159 11.0833 2.05032C8.96212 4.01923 6.84091 5.98814 4.7197 7.95705C4.61364 8.0555 4.50758 8.16801 4.32576 8.33677C4.55303 8.33677 4.67424 8.33677 4.79545 8.33677C12.7197 8.33677 20.6591 8.33677 28.5833 8.35084C28.9167 8.35084 29.2803 8.46335 29.553 8.63211C29.9924 8.89932 30.1136 9.41967 29.947 9.88377C29.7652 10.3619 29.3864 10.6151 28.8561 10.6713C28.6439 10.6995 28.4167 10.6854 28.1894 10.6854C20.3864 10.6854 12.5833 10.6854 4.7803 10.6854C4.67424 10.6854 4.55303 10.6854 4.44697 10.6854C4.41667 10.7135 4.40152 10.7417 4.38636 10.7698Z"
                                        fill="#CC9B6A"
                                      />
                                    </svg>
                                  </button>
                                  <button className="splide__arrow splide__arrow--next">
                                    <svg
                                      width="31"
                                      height="19"
                                      viewBox="0 0 31 19"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M25.6323 8.23864C24.7384 7.40888 23.8293 6.57913 22.9353 5.74937C21.602 4.51177 20.2687 3.28823 18.9505 2.05063C18.2232 1.37557 18.4808 0.334863 19.4202 0.0676527C19.905 -0.0729837 20.3444 0.039527 20.7081 0.377055C22.7232 2.26158 24.7687 4.16018 26.8141 6.04471C27.7232 6.88853 28.6323 7.73235 29.5414 8.57617C30.1778 9.16684 30.1778 9.82783 29.5414 10.4326C26.6172 13.1609 23.6778 15.8752 20.7535 18.5895C20.1778 19.1098 19.405 19.138 18.8899 18.6598C18.3596 18.1816 18.3747 17.4925 18.9353 16.9581C21.0566 14.9892 23.1778 13.0203 25.299 11.0514C25.405 10.9529 25.5111 10.8404 25.6929 10.6716C25.4656 10.6716 25.3444 10.6716 25.2232 10.6716C17.299 10.6716 9.35958 10.6716 1.43534 10.6576C1.10201 10.6576 0.738374 10.5451 0.465646 10.3763C0.0262516 10.1091 -0.0949603 9.58875 0.0717057 9.12465C0.253524 8.64648 0.632312 8.39334 1.16262 8.33708C1.37474 8.30895 1.60201 8.32302 1.82928 8.32302C9.63231 8.32302 17.4353 8.32302 25.2384 8.32302C25.3444 8.32302 25.4656 8.32302 25.5717 8.32302C25.602 8.29489 25.6172 8.26677 25.6323 8.23864Z"
                                        fill="#CC9B6A"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </Splide>
                          </div>
                        </div>
                      </div>
                    </>  
                  


                }
              )
              : <div  style={{paddingTop:'calc(var(--block-spacing) * 2)'}}>
                <Loader loading={true}/>
              </div>
            
          }
    </>
  }
}

export default Blog;
