import AjaxComp from "../../data/AjaxComp";

class Star extends AjaxComp {
  constructor(props) {
    super(props);
  }
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="21"
        viewBox="0 0 22 21"
        className={`star ${this.props.starClass}`}
        fill="none"
      >
        <g clip-path="url(#clip0_612_20030)">
          <path
            d="M5.10605 20.7521C5.03047 20.7521 4.95934 20.7521 4.88376 20.7521C4.23024 20.4753 4.05686 20.1851 4.16356 19.5734C4.51032 17.5599 4.85264 15.5463 5.19941 13.5372C5.23053 13.3631 5.19496 13.2425 5.06159 13.1086C3.64341 11.7335 2.23857 10.3494 0.824835 8.96985C0.584767 8.73768 0.371373 8.49659 0.304688 8.16174C0.304688 8.1171 0.304688 8.07245 0.304688 8.0278C0.433613 7.434 0.851509 7.23309 1.40278 7.18398C1.77177 7.14826 2.13632 7.08129 2.50087 7.02772C4.08354 6.79555 5.66176 6.56339 7.24443 6.34016C7.42671 6.31337 7.52451 6.24194 7.60453 6.07674C8.49367 4.25069 9.39615 2.42464 10.2897 0.598595C10.4009 0.375362 10.5343 0.174452 10.7788 0.0806941C11.29 -0.120216 11.7479 0.0673001 11.9969 0.580737C12.8994 2.41125 13.8018 4.24176 14.6954 6.07674C14.7755 6.24194 14.8733 6.3223 15.06 6.34016C15.4112 6.37588 15.7624 6.43838 16.1136 6.48749C17.7096 6.71966 19.3056 6.96521 20.9016 7.17952C21.4485 7.25541 21.8664 7.434 21.9997 8.0278C21.9997 8.07245 21.9997 8.1171 21.9997 8.16174C21.933 8.4832 21.7419 8.71536 21.5151 8.93859C20.0881 10.3271 18.6743 11.7201 17.2428 13.1086C17.105 13.2425 17.0739 13.3586 17.105 13.5327C17.4562 15.5597 17.803 17.5867 18.1497 19.6136C18.1986 19.8949 18.1942 20.1851 17.9853 20.386C17.8208 20.5467 17.5807 20.6316 17.3762 20.7521C17.3184 20.7521 17.2562 20.7521 17.1984 20.7521C17.0161 20.6717 16.8338 20.6003 16.656 20.5065C14.8822 19.5734 13.1083 18.6359 11.3345 17.6938C11.2011 17.6224 11.1033 17.6179 10.9699 17.6938C10.1875 18.1135 9.4006 18.5287 8.61371 18.9394C7.44449 19.5466 6.27527 20.1449 5.10605 20.7521Z"
            fill="#A4B3BB"
          />
        </g>
        <defs>
          <clipPath id="clip0_612_20030">
            <rect
              width="21.695"
              height="20.7518"
              fill="white"
              transform="translate(0.304688)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
}

export default Star;

