import AjaxComp from "../../data/AjaxComp";
import ProduitAccroche from "../containers/listes/produit-accroche";
import LabelZone from "../extra/labelZone";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "./search_results.css"
import SearchPagination from "./pagination";
class SearchResults extends AjaxComp {
    constructor(props){
        super(props);
        this.app.search_results = this;
        this.state = {...props};  
    }
    search(data,page=1,stepp=0,filtre,seachCat,seachSousCat){
        console.log("data search_produit search",data,filtre)
        const search            = {}
        const search_produit    = {}
        const search_cat        = {}
        const search_souscat    = {}
        this.state.niveau       = stepp ? stepp : 0;
        this.state.seachCat       = seachCat ? seachCat : 0;
        this.state.seachSousCat   = seachSousCat ? seachSousCat : 0;
        Object.keys(data).forEach(
            key=>{

                let newval = data[key]
                if(data[key]){
                    newval = ((key == 'search') || (key == 'lang')) ? '"'+data[key]+'"' : data[key]
                    if(typeof newval == 'string'){
                        search[key] = newval.replaceAll('""','"')
                    }
                }
            }
        )
        Object.keys(data).forEach(
            key=>{
                if(data[key]){
                    let newkey = key
                    let newval = data[key]
                    if(key == 'search')
                    {
                        newkey = 'search_categorie'
                        newval = '"'+data[key]+'"'
                    }
                    if(key == 'id')
                    {
                        newkey = 'categorie_id'
                        newval = data[key]
                    }
                    if(key == 'lang')
                    {
                        newval = '"'+data[key]+'"'
                    }
                    if(typeof newval == 'string'){
                        search_produit[newkey] = newval.replaceAll('""','"')
                    }
                }
            }
        )
        if(this.app.state.client_id){
            search.client_id = this.app.state.client_id
        }

        if(filtre){
            search.id = filtre;

            if((seachCat || filtre) && seachSousCat == 0)
            {
                search_cat.categorie_id = filtre;
                search_produit.categorie_id = seachCat != 0 ? seachCat : filtre;

                if (seachCat)
                {
                    search_souscat.categorie_id = filtre;
                    search_souscat.sous_categorie_id = seachCat;
                }
            }

            if(seachSousCat)
            {
                search_produit.categorie_id = seachSousCat;

                search_souscat.id = seachSousCat;
                //search_souscat.categorie_id = seachCat;
                //search_souscat.sous_categorie_id = filtre;
            }

            //search_souscat.categorie_id = filtre;
        }

        console.log("search_produit search niveau",  this.state.niveau, search,search_produit)
        search.langue_id = this.app.getCurrentLang().id;
        search_produit.langue_id = this.app.getCurrentLang().id;

        search_produit.website = true;
        search.website = true;

        this.customPaginatedGraphql('produits',search_produit,["id,image,designation,nom_langue,prix,prix_devises{prix},is_promo,prix_promo,pourcentage_promo,stock_global,is_nouveaute,createur{id,designation,photo},createur_id,images{id,src}","declinaisons{id,produit_id,nom_declinaison,display_text,quantity,couleur_id,couleur{id,nom,description,code_couleur},declinaison_id,declinaisons{id,produit_id,nom_declinaison,display_text,quantity,declinaison_id,taille_id,taille{id,nom,description},produit{id,designation,prix}}}"],page,8,results=>{
            this.updateSafelyState({searchquery:search})
            if(results && !results.errors){
                this.updateSafelyState({searchdata:results.produitspaginated})
            }
        })

        this.customGraphql(
            'categories',search,['id,designation,image','nom_langue'],results=>{
                this.updateSafelyState({searchquery:search})
                if(results && !results.errors){
                    this.updateSafelyState({categories:results.categories})
                }
            }
        )

        this.customGraphql(
            'souscategories',search_cat,['id,designation,image','nom_langue','parent{id,designation}'],results=>{
                //this.updateSafelyState({searchquery:search})
                if(results && !results.errors){
                    this.updateSafelyState({souscategories:results.souscategories})
                }
            }
        )
        this.customGraphql(
            'soussouscategories',search_souscat,['id,designation,image','nom_langue','parent{id,designation,parent{id,designation}}'],results=>{
                // this.updateSafelyState({searchquery:search})
                if(results && !results.errors){
                    this.updateSafelyState({soussouscategories:results.soussouscategories})
                }
            }
        )
    }
    render(){
        let results             = null;
        let categories          = null;
        let souscategories      = null;
        let soussouscategories  = null;
        let pagination          = {}
        if(this.state && this.state.searchdata){
            // alert(JSON.stringify(this.state.searchdata))
            const {metadata,data}                               = this.state?.searchdata
            const {current_page,last_page,per_page,total}       = metadata
            results                                             = data
            pagination                                          = {current_page,last_page,per_page,total}
        }
        if(this.state && this.state.searchdata){
            categories          = this.state?.categories;
            souscategories      = this.state?.souscategories;
            soussouscategories  = this.state?.soussouscategories;
            
        }
        return results ? 
            <>
                {this.state && this.state.niveau == 0 ?
                    <div id="scroll-categories" style={{margin:'var(--block-spacing)'}}>
                        {
                            categories?.map(
                                (categorie,key)=>{
                                    return <a href={this.suffixe+'/categories/?search='+categorie.id+'&stepp=1'} className="scroll-categorie" key={key}>
                                        <img loading="lazy" src={categorie.image} alt=""/>
                                        <span>
                                            {categorie.nom_langue}
                                        </span>
                                    </a>
                                }
                            )
                        }
                    </div> : ''
                }

                {this.state && souscategories && this.state.niveau == 1 ?
                    <div id="scroll-categories" style={{margin:'var(--block-spacing)'}}>
                        {
                            souscategories?.map(
                                (categorie,key)=>{
                                    return <a href={this.suffixe+'/categories/?seachCat='+categorie.id+'&search='+categorie?.parent?.id+'&stepp=2'} className="scroll-categorie" key={key}>
                                        <img loading="lazy" src={categorie.image} alt=""/>
                                        <span>
                                            {categorie.nom_langue}
                                        </span>
                                    </a>
                                }
                            )
                        }
                    </div> : ''
                }
                
                {this.state && soussouscategories && this.state.niveau == 2 ?
                    <div id="scroll-categories" style={{margin:'var(--block-spacing)'}}>
                        {
                            soussouscategories?.map(
                                (categorie,key)=>{
                                    return <a href={this.suffixe+'/categories/?seachSousCat='+categorie.id+'&seachCat='+categorie?.parent?.id+'&search='+categorie?.parent?.parent?.id+'&stepp=2'} className="scroll-categorie" key={key}>
                                        <img loading="lazy" src={categorie.image} alt=""/>
                                        <span>
                                            {categorie.nom_langue}
                                        </span>
                                    </a>
                                }
                            )
                        }
                    </div> : ''
                }
                

                <div id='search_results' className="zone">
                    <div className="list_results">
                        {
                            //(this.state.searchdata?.total == 0) ? "Aucun résultat trouvé" : ""
                            (results.length == 0) ? this.app.getCurrentLangText("site_text_aucun_resultat") : ""
                        }
                        {results.map(
                            (produit, idx) => {
                                return (
                                    <ProduitAccroche key={produit.id} produit={produit} app={this.app} ></ProduitAccroche> 
                                )
                            }
                        )}
                    
                    </div>
                    <SearchPagination {...pagination} searchdata={this.state.searchquery} searchresults={this}/>
                </div>

            </>
        : ''
    }


}
export default SearchResults