import AjaxComp from "../../data/AjaxComp";
import RemboursementComponent from "./politiqueremboursement";

class SectionRemboursement extends AjaxComp {


    render(){

        return (

                <div id="section-remboursement">
                    <RemboursementComponent/>
                </div>
    
        )
    }
}
export default SectionRemboursement