import AjaxComp from "../data/AjaxComp";
import RecapProduitsCommande from "./recap_produits_commande";

class AdressePanier extends AjaxComp {
    querynames = []
    default_conditions = null
    attrs_queries = [];
    default_multiple = true
    constructor(props){
        super(props)
        this.state = {
            ...props,
            ...{
                data:{current_zone:{montant:0,id:null}}
            },
            edit_fullname:{
                state:                      (       this.app.client     &&  this.app.client.visitor                         ) ? true    :   false,
                value:                      (       this.app.client     &&  !this.app.client.nom_complet.match('client_')   ) ? this.app.client.nom_complet:''}
            }
        this.app.adresse_panier = this
        
    }

    componentDidMount(){

        this.setState({ mounted: true });
        if(!this.state.client || (this.state.data))
        {
            this.setupClientData(
                data=>{
                    this.updateSafelyState({client:data})
                }
            )
        }
        if(!this.state.data || (this.state.data && !this.state.data.pays))
        {
            this.customGraphql('pays',null,["id","name","code,zone_livraison_id,zone_livraison{designation,id},display_commande"],data=>{
                
                this.updateSafelyState({pays:data.pays?data.pays:[]})
        
            })
        }
        if(!this.state.data || (this.state.data && !this.state.data.zonelivraisons))
        {
            this.customGraphql('zonelivraisons',null,["id,designation,montant,latitude,longitude"],Data=>{
                const data = this.state.data ? this.state.data : {}
                data.zonelivraisons = Data.zonelivraisons ? Data.zonelivraisons : []
                this.updateSafelyState({data})
            })
        }
        if(!this.state.data || (this.state.data && !this.state.data.modepaiements))
        {
            this.customGraphql('modepaiements',{website:true},["id,designation,etat,online,image"],Data=>{
                const data = this.state.data ? this.state.data : {}
                data.modepaiements = Data.modepaiements ? Data.modepaiements : []
                this.updateSafelyState({data})
            })
        }
    }

    updateDonneesClient(cb = (data) => console.warn) {
        const form = {};
        Object.keys(this.state.client).forEach((key) => {
            let value = this.state.client[key]
            if (value && key == "adresses") {
                value = JSON.stringify(value);
            }
            if (key == "id") {
                value = this.app.client.id;
            }
            if(value){
                form[key] = value;
            }
        });

        const edit_fullname = this.state
        if(edit_fullname && edit_fullname.value){
            form.nom_complet = edit_fullname.value
        }
        form["from_website"] = true;
        form["langue_id"] = this.app.getCurrentLang().id;
        this.ajaxPost("client", form, (response) => {
            cb(response);
        });
    }

    changeVisitorName(name){
        const edit_fullname = this.state
        edit_fullname.value = name
        const {client} = this.state
        client.nom_complet = name
        this.updateSafelyState({edit_fullname,client})
    }

    renameVisitor(){
        const edit_fullname = this.state
        if(edit_fullname && edit_fullname.value){
            this.updateDonneesClient(
                data=>{
                    this.setupClientData(client=>{
                        console.warn('setup data ', client)
                        this.updateSafelyState({client})
                    },true)
                }
            )
        }
    }

    updateCurrentZone(e,id){
        const search_result = this.localSearch('zonelivraisons','id',e.target.value,this.state.data)
       let zone = null
       if(search_result && search_result.length){
           zone =  search_result[0]
       }
       if(zone){
           this.app.gestion_panier.setCurrentZone(zone,id)
       }else{
           console.debug(e.target.value )
       }
   }

   updateZone(zone){
    this.app.gestion_panier.state?.panier?.panier?.map(
        commande=>{
            this.app.gestion_panier.setCurrentZone(zone,commande.id)
        }
    )
   }
    render(){
        console.warn(this.state.client,' as  client', this.state.client?.adresses)
        return (
            <div className="section-commande" id="adresse-panier">
                <div className='donnees-adresse'>
                    <div className="zone-donnees-adresse">
                        <h3>
                            {this.app.getCurrentLangText("site_text_informations_generales")}
                        </h3>
                        <div className="sepa faded"></div>
                        <div className="donnees-zone-donnees-adresse">
                            <div className="list-infos-client-commande">
                                <div className="info-client-commande">
                                    <div className="Label_text">
                                    {this.app.getCurrentLangText("site_text_nom_complet")}
                                    </div>
                                    {
                                        
                                        // this.state.client && this.state.client.visitor 
                                        //     ? 
                                        //         <>
                                        //             <input placeholder="Nom Complet" onChange={e=>{this.changeVisitorName(e.target.value)}}className="valeur" value={this.state.edit_fullname?this.state.edit_fullname.value:""} />
                                        //             <span className="editer faded" onClick={e=>{if(this.state.client && this.state.client.visitor){this.renameVisitor()}}}>
                                        //                 modifier
                                        //             </span>
                                        //         </>
                                        //     : 
                                        //         <span className="valeur">
                                        //             {this.state.client?.nom_complet}
                                        //         </span>
                                        <input placeholder={this.app.getCurrentLangText("site_text_nom_complet")} className="valeur" defaultValue={(this.state.client&&this.state.client.visitor==false)?this.state.client.nom_complet:''} id='nom_complet_commande' />

                                    }
                                </div>
                                <div className="info-client-commande">
                                    <div className="Label_text">
                                    {this.app.getCurrentLangText("site_text_adresse_email")}
                                    </div>
                                    <input placeholder={this.app.getCurrentLangText("site_text_adresse_email")} className="valeur" defaultValue={(this.state.client&&this.state.client.visitor==false)?this.state.client.email:''}  id='email_commande' />
                                </div>

                                <div className="info-client-commande">
                                    <div className="Label_text">
                                    {this.app.getCurrentLangText("site_text_pays")}
                                    </div>
                                    <select id='pays_commande' className='select2' 
                                        onChange={
                                            e=>{
                                                let match = this.localSearch('pays','code',e.target.value)
                                                if(match && match.length)
                                                {
                                                    match = match[0] 
                                                    console.info(match)
                                                    this.updateZone(match.zone_livraison)
                                                }
                                            
                                            }
                                        } 
                                            defaultValue={(this.state.client&&this.state.client.visitor==false)?this.state.client.pays_id:''}
                                        >
                                        <option value="">{this.app.getCurrentLangText("site_text_pays")}</option>
                                        {
                                            this.state.pays && this.state.pays.map(pays=>{
                                                return <option selected={(this.state.client&&this.state.client.pays&&this.state.client.pays.id==pays.id)} value={pays.code}>{pays.display_commande}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="info-client-commande">
                                    <div className="Label_text">
                                    {this.app.getCurrentLangText("site_text_ville")}
                                    </div>
                                    <input placeholder={this.app.getCurrentLangText("site_text_ville")} className="valeur"  defaultValue={(this.state.client&&this.state.client.visitor==false)?(this.state.client.adresses ? this.state.client?.adresses[0]?.ville : ''):''}  id='ville_commande' />
                                </div>
                                <div className="info-client-commande">
                                    <div className="Label_text">
                                    {this.app.getCurrentLangText("site_text_adresse")}(s)
                                    </div>
                                    <input placeholder={this.app.getCurrentLangText("site_text_adresse")} className="valeur" defaultValue={(this.state.client&&this.state.client.visitor==false)?this.state.client?.adresse:''}   id='adresse_commande' />
                                        

                                    {/* <select id='adresse_commande' className='select2' style={{width:'90%',padding:12}}
                                        onChange={
                                            e=>{
                                                console.log("ici form adresse => ", e.target.value)
                                                // let match = this.localSearch('pays','code',e.target.value)
                                                // if(match && match.length)
                                                // {
                                                //     match = match[0] 
                                                //     console.info(match)
                                                //     this.updateZone(match.zone_livraison)
                                                // }

                                            
                                            }
                                        } 
                                            defaultValue={(this.state.client&&this.state.client.visitor==false)?this.state.client.adresse:''}
                                        >
                                        <option value="">{this.app.getCurrentLangText("site_text_adresse")}</option>
                                        {
                                            this.state.client && this.state.client?.adresses.map(adresse=>{
                                                return <option selected={(this.state.client&&this.state.client.adresses&&this.state.client.adresses.designation==adresse.designation)} value={adresse.designation}>{adresse.designation}</option>
                                            })
                                        }
                                    </select>
                                    <button className="btn ml-1">{this.app.getCurrentLangText("site_text_ajouter") ? this.app.getCurrentLangText("site_text_ajouter") : "Ajouter"}</button> */}
                                </div>
                                <div className="info-client-commande">
                                    <div className="Label_text">
                                    {this.app.getCurrentLangText("site_text_code_postal")}
                                    </div>
                                    <input placeholder={this.app.getCurrentLangText("site_text_code_postal")} className="valeur" defaultValue={(this.state.client&&this.state.client.visitor==false)?(this.state.client.adresses ? this.state.client?.adresses[0]?.code_postal : ''):''} id='code_postal_commande' />
                                </div>
                                <div className="info-client-commande">
                                    <div className="Label_text">
                                    {this.app.getCurrentLangText("site_text_telephone")}
                                    </div>
                                    {/* <span className="valeur">
                                    {(this.state.client && this.state.client.telephone)? this.state.client.telephone : (this.state.client ? 'aucun téléphone renseigné' : 'impossible de charger automatiquement le numéro de téléphone')}
                                    </span>
                                    <span className="editer faded">
                                        modifier
                                    </span> */}
                                    <input placeholder={this.app.getCurrentLangText("site_text_telephone")} type='number' className="valeur" defaultValue={(this.state.client&&this.state.client.visitor==false)?this.state.client.telephone:''} id='telephone_commande' />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    {
                        this.app.gestion_panier.state.panier?.panier?.map(
                            commande=>{
                                const id  = "adresse-commande-active"+commande.id
                                const label = "adresse-commande-active-tab"+commande.id
                                return <div className="tab-pane" id={id} role="tabpanel" aria-labelledby={label}>
                                    <h2>
                                    {this.app.getCurrentLangText("site_text_commande_chez")}  {commande.createur.designation}
                                    </h2>
                                    <div className="zone-donnees-adresse">
                                        <h3 className="text-capitalize">
                                        {this.app.getCurrentLangText("site_text_mode_paiement")} 
                                        </h3>
                                        <div className="sepa faded"></div>
                                        <div className="Label_text">
                                        {this.app.getCurrentLangText("site_text_quel_moyen_de_paiement_voulez")}
                                        </div>
                                        <div className="donnees-zone-donnees-adresse">
                                            
                                            <div className="selection-mode-paiement">
                                                {
                                                    (this.state.data && this.state.data.modepaiements) 
                                                        ?
                                                            this.state.data.modepaiements.map(
                                                                mode=>{
                                                                    return <div className="option-selection-mode-paiement">
                                                                        <div className="optionline">
                                                                            <input type="radio"  checked={commande.mode_paiement&&commande.mode_paiement.id==mode.id}  onChange={e=>this.app.gestion_panier.setModePaiement(mode,commande.id)} name={"mode-paiement-commande-"+commande.id} value={mode.id}/>
                                                                            <span className="icon">
                                                                                <img loading="lazy" src={this.apiUri+"/"+mode.image} alt=""/>
                                                                            </span>
                                                                            {mode.designation}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            )
                                                        :
                                                        this.app.getCurrentLangText("site_text_aucun_mode_de_paiement_nest_actuellement_defini") 
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        )
                    }
                </div>

                <div className="recap-commande wp">
                    <div className="recap-commande">
                        <h3>
                            {this.app.getCurrentLangText("site_text_votre_commande")}
                        </h3>
                        <div className="sepa faded"></div>
                        <div className="recap-produits-commande">
                            <RecapProduitsCommande/>
                        </div>
                    </div>
                </div>
            </div>

        )
    }


}

export default AdressePanier