import AjaxComp from "../../../data/AjaxComp"
import './liste-icones.css'
class ListeIcones extends AjaxComp {


    render(){

        return (
            <ul className="liste_icones">
                <li>
                    <a href={this.suffixe+"/"}>
                        <img loading="lazy" src="/assets/pictures/instagram.svg" alt=""/>
                    </a>
                </li>
                <li>
                    <a href={this.suffixe+"/"}>
                        <img loading="lazy" src="/assets/pictures/facebook.svg" alt=""/>
                    </a>
                </li>
                <li>
                    <a href={this.suffixe+"/"}>
                        <img loading="lazy" src="/assets/pictures/twitter.svg" alt=""/>
                    </a>
                </li>
                <li>
                    <a href={this.suffixe+"/"}>
                        <img loading="lazy" src="/assets/pictures/whatsapp.svg" alt=""/>
                    </a>
                </li>
            </ul>
        )


    }
}

export default ListeIcones