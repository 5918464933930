import AjaxComp from "../data/AjaxComp";
import Base from "./base";
import Decouvertes from "../layout/zones/decouvertes";
import {
  accroche_produits,
  collections,
  decouvertes,
  images_promo,
} from "../myseed";

import AccrocheProduits from "../layout/zones/accroche-produits";
import PromoCreateurs from "../layout/zones/promo-createurs";
import AccrocheSuggestions from "../layout/zones/accroche-suggestions";
import Selection from "../layout/zones/selection";
import HorizontalScrollCategories from "../layout/zones/scroll-categories";
import seed from '../myseed'
import Galerie from "../layout/sections/galerie";
import MiseEnAvant from "../layout/zones/mise-avant";
import Slideshow from "../layout/containers/sliders/SliderShow";
import LabelZone from "../layout/extra/labelZone";


class Home extends AjaxComp {
  query_attrs = [
    "id",
    "image",
    "designation",
    "sur_commande",
    "images{id,src}",
    "createur{id,designation,photo},prix,prix_devises{prix},prix_promo,pourcentage_promo,is_promo,is_nouveaute,stock_global",
  ];

  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  render() {
    return (
      <Base
        // title="Collection du mois"
        // subtitle="À ne pas rater !!! Occasion à saisir..."
      >
        <HorizontalScrollCategories/> 
        <Decouvertes></Decouvertes>
        <AccrocheProduits></AccrocheProduits>
        <PromoCreateurs slides={images_promo}></PromoCreateurs>
        {/* <MiseEnAvant slides={images_promo}></MiseEnAvant> */}
        <Slideshow></Slideshow>
        <AccrocheSuggestions></AccrocheSuggestions>
        <Selection collections={collections} />
        <Galerie/>
      </Base>
    );
  }
}

export default Home;
