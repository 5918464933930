import AjaxComp from "../../data/AjaxComp";
import Loader from "../../Loader";

class Livraison extends AjaxComp {

  path_params         = this.suffixe+'/livraison/:track_number'
  track_number      = null
  default_conditions = null
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.setRouteParams()
    if(this.getRouteParams()){
        this.track_number = this.getRouteParams().params.track_number
        this.state={...this.state,track_number:this.track_number}
        this.getDetails()
    }
  }
  getDetails(){

    const {track_number} = this.state
    if(track_number){
        this.ajaxPost('livraison/track',{track_number,langue_id: this.app.getCurrentLang().id},livraison=>{
            this.updateSafelyState({livraison})
        })
    }
  }
  render() {
    const {erreur,data} = this.state.livraison ?? {}
    const shipment = data 
    console.info(data)
    // if(shipment)
    // {
    //   shipment.events = [
    //     {
    //       location:'dakar',
    //       timestamp:shipment.shipmentTimestamp,
    //       status:'done'
    //     },
    //     {
    //       location:'this',
    //       timestamp:shipment.shipmentTimestamp,
    //       status:'done'
    //     }
    //   ]
    // }
    return <>
          {
            this.state.livraison  ? 
              <div className='zone'>
                {
                    erreur 
                  ? 
                    <span class='badge badge-danger'> {erreur} </span>
                  : 
                    <>
                      <div className="card mb-3" key={this.track_number}>
                        <div className="card-header material text-white">
                        {this.app.getCurrentLangText("site_text_numero_de_suivi")} : {shipment.shipmentTrackingNumber}
                        </div>
                        <div className="card-body row">
                          <div  className="col-md-4">
                            <h5 className="card-title">{this.app.getCurrentLangText("site_text_statut_de_l_envoi")} : {shipment.status}</h5>
                            <p className="card-text">{this.app.getCurrentLangText("site_text_description")} : {shipment.description}</p>
                            <p className="card-text">{this.app.getCurrentLangText("site_text_date_et_heure_de_l_envoi")} : {shipment.shipmentTimestamp}</p>
                            <p className="card-text">{this.app.getCurrentLangText("site_text_code_du_produit")} : {shipment.productCode}</p>
                            <p className="card-text">{this.app.getCurrentLangText("site_text_poids_total")} : {shipment.totalWeight} {shipment.unitOfMeasurements}</p>
                            <p className="card-text">{this.app.getCurrentLangText("site_text_nombre_de_pieces")} : {shipment.numberOfPieces}</p>
                            <h5 className="card-title">{this.app.getCurrentLangText("site_text_details_de_l_expeditaire")}</h5>
                            <p className="card-text">{this.app.getCurrentLangText("site_text_nom")} : {shipment.shipperDetails.name}</p>
                            <p className="card-text">{this.app.getCurrentLangText("site_text_ville")} : {shipment.shipperDetails.postalAddress.cityName}</p>
                            {/* Add more shipper details as needed */}
                            <h5 className="card-title">{this.app.getCurrentLangText("site_text_details_du_destinataire")}</h5>
                            <p className="card-text">{this.app.getCurrentLangText("site_text_nom")} : {shipment.receiverDetails.name}</p>
                            <p className="card-text">{this.app.getCurrentLangText("site_text_ville")} : {shipment.receiverDetails.postalAddress.cityName}</p>
                          </div>
                          <div className="progress mt-3 col-md-1  p-0" style={{display:'grid',gridTemplateRows:'repeat(4,25%)',height:'50vh'}}>
                            <div className="progress-bar bg-success" role="progressbar" style={{ display:'grid',alignItems:'center',height:'100%',width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{this.app.getCurrentLangText("site_text_commande_recue")}</div>
                            <div className="progress-bar bg-warning" role="progressbar" style={{ display:shipment.commande.commande_etat >= 1 ? 'grid' : 'none',alignItems:'center',height: shipment.commande.commande_etat>= 1 ? '100%' : '0%',width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{this.app.getCurrentLangText("site_text_en_cours_de_traitement")}</div>
                            <div className="progress-bar bg-info" role="progressbar" style={{ display:shipment.commande.commande_etat >= 2 ? 'grid' : 'none',alignItems:'center',height: shipment.commande.commande_etat>= 2 ? '100%' : '0%',width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{this.app.getCurrentLangText("site_text_en_cours_de_livraison")}</div>
                            <div className="progress-bar bg-primary" role="progressbar" style={{ display:shipment.commande.commande_etat >= 4 ? 'grid' : 'none',alignItems:'center',height: shipment.commande.commande_etat>= 4 ? '100%' : '0%',width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{this.app.getCurrentLangText("site_text_livre")}</div>
                          </div>
                          {/* Add more receiver details as needed */}
                          {/* Display tracking events */}
                          {shipment.events && (
                            <div className="col-md-7">
                              <h5 className="card-title">{this.app.getCurrentLangText("site_text_evenements_de_suivi")}</h5>
                              <table  className="table">
                                <tr>
                                  <td className="text-center align-center">
                                     {this.app.getCurrentLangText("site_text_evenement")}
                                  </td>
                                  <td className="text-center align-center">
                                  {this.app.getCurrentLangText("site_text_date")}
                                  </td>
                                  <td className="text-center align-center">
                                  {this.app.getCurrentLangText("site_text_lieu")}
                                  </td>
                                  <td className="text-center align-center">
                                  {this.app.getCurrentLangText("site_text_statut")}                                    
                                  </td>
                                </tr>
                                {shipment.events.map((event, index) => (
                                  <tr key={index}>
                                    <td className="text-center align-center">
                                      <span className="badge bg-primary me-2">{index + 1}</span>
                                    </td>
                                    <td className="text-center align-center">
                                      <span> {event.timestamp}</span>
                                    </td>
                                    <td className="text-center align-center">
                                      <span className="ms-2">{event.location}</span>
                                    </td>
                                    <td className="text-center align-center">
                                      <span className="ms-2"><span className="badge badge-info">{event.status}</span></span>
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                }
              </div>
              : <div  style={{paddingTop:'calc(var(--block-spacing) * 2)'}}>
                <Loader loading={true}/>
              </div>
            
          }
    </>
  }
}

export default Livraison;
