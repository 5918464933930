import AjaxComp from "../data/AjaxComp";
import Base from "./base";
import ZoneValiderCommande from "../layout/zones/validercommande";

class ValiderCommande extends AjaxComp {


    constructor(props){
        super(props)
        this.state = {...props}
    }

    componentDidMount(){
        if(!this.isLogged()){
            this.goTo(this.suffixe+"/");
        }
    }
    render(){
        return (
            <>
                <Base noHeader={false}>
                    <ZoneValiderCommande/>
                </Base>
            </>



        )
    }


}

export default ValiderCommande