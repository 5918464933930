import AjaxComp from "../data/AjaxComp";
import LabelZone from "../layout/extra/labelZone";

class ContactForm extends AjaxComp {
  contactdata = {};
  setContactData(name, value) {
    this.contactdata[name] = value;
  }
  contact(e) {
    e.preventDefault();

    this.setState({ display_error: null });
    this.contactdata.nom_complet = this.contactdata.prenom + " " + this.contactdata.nom;
    this.contactdata.langue_id = this.app.getCurrentLang().id; 
    this.ajaxPost("contact", this.contactdata, (response) => {
      try {
        if (response.errors) {
          this.app.showDisplayError(response.errors.toString());
          // this.setState({ display_error: response.errors.toString() });
        }
        if (response.message) {
          this.switchPopupElement(null, "#section-contact");
          this.setState({ display_message: response.errors.toString() });
        }
      } catch (e) {}
    });
  }
  render() {
    return (
      <div id="contactform">
        <div
          class="w-100 d-flex justify-content-end"
          onClick={(e) => this.switchPopupElement(e, "#section-contact")}
        >
          <svg
            class="svg-inline--fa fa-xmark text-danger"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="xmark"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            ></path>
          </svg>
        </div>
        <form action="" className="zone">
          <LabelZone
            label={this.app.getCurrentLangText("site_text_formulaire_de_contact")}
            whithlogo={true}
          />

          <div className="formsection">
            <label htmlFor="nom_complet" className="label_name">
            {this.app.getCurrentLangText("site_text_nom_complet")} *
            </label>
            <div className="double-fields" name="nom_complet">
              <div className="formfield input_container">
                <div className="icon">
                  <img
                    loading="lazy"
                    src="/assets/pictures/user-client-color.svg"
                    alt=""
                  />
                </div>
                <div className="input">
                  <input
                    type="text"
                    placeholder={this.app.getCurrentLangText("site_text_prenom")}
                    onChange={(e) =>
                      this.setContactData("prenom", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="formfield input_container">
                <div className="icon">
                  <img
                    loading="lazy"
                    src="/assets/pictures/user-client-color.svg"
                    alt=""
                  />
                </div>
                <div className="input">
                  <input
                    type="text"
                    placeholder={this.app.getCurrentLangText("site_text_nom_de_famille")}
                    onChange={(e) => this.setContactData("nom", e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="double-label">
              <label htmlFor="nom_complet" className="label_name">
              {this.app.getCurrentLangText("site_text_email")}
              </label>
              <label htmlFor="nom_complet" className="label_name">
              {this.app.getCurrentLangText("site_text_telephone")}
              </label>
            </div>
            <div className="double-fields">
              <div className="formfield input_container" name="email">
                <div className="icon">
                  <img
                    loading="lazy"
                    src="/assets/pictures/icon-mail-color.svg"
                    alt=""
                  />
                </div>
                <div className="input">
                  <input
                    type="text"
                    placeholder={this.app.getCurrentLangText("site_text_email")}
                    onChange={(e) =>
                      this.setContactData("email", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="formfield input_container" name="telephone">
                <div className="icon">
                  <img
                    loading="lazy"
                    src="/assets/pictures/icon-phone-color.svg"
                    alt=""
                  />
                </div>
                <div className="input">
                  <input
                    type="text"
                    placeholder={this.app.getCurrentLangText("site_text_telephone")}
                    onChange={(e) =>
                      this.setContactData("telephone", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>

            <label htmlFor="place" className="label_name">
            {this.app.getCurrentLangText("site_text_votre_message")}
            </label>
            <div className="formfield input_container" name="place">
              <div className="input">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  onChange={(e) =>
                    this.setContactData("message", e.target.value)
                  }
                ></textarea>
              </div>
            </div>
          </div>
          <div className="buttonbox">
            <button onClick={(e) => this.contact(e)}>
              {this.app.getCurrentLangText("site_text_soumettre")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
