import AjaxComp from "../../data/AjaxComp";
import AProposComponent from "./apropos";


class SectionAPropos extends AjaxComp {


    render(){

        return (

                <div id="section-apropos">
                    <AProposComponent/>
                </div>
    
        )
    }
}
export default SectionAPropos