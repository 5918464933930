import AjaxComp from "../../data/AjaxComp";
import Footer from "./footer";
import FootingHook from "./hook";
import GoToTop from "../extra/gototop";
import Conditions from "../extra/conditions";
import "./bottom.css";

class Bottom extends AjaxComp {
  render() {
    return (
      <div className="bottom">
        {/* <GoToTop /> */}
        <div className="footing page">
          <Footer />
          <FootingHook></FootingHook>
        </div>
        <Conditions />
      </div>
    );
  }
}
export default Bottom;
