import AjaxComp from "../../data/AjaxComp";
import Panier from "../../pages/panier";
import AdressePanier from "../../pages/adresse_panier";
import Loader from "../../Loader";

class ZoneValiderCommande extends AjaxComp {

    constructor(props){
        super(props)
        this.state = {...props,nodata:true}
        this.app.zone_validercommande = this
        
    }
    gotdata(){
        this.updateSafelyState({nodata:false})
    }
    componentDidUpdate(){
        if(!this.state.nodata){
            window.setupOptionsSectionCommande()
        }
    }

    
    render(){
        const {nodata} = this.state
        const {panier,client} = this.app.gestion_panier.state
        if(nodata && panier && client){
            this.gotdata()
        }
        return (


            <div id="validercommande" className="zone">
                {
                    (this.state.nodata == false) 
                        ?
                            <>
                                <div style={{display:"grid",gridTemplateColumns:"60% 40%"}}>
                                    <div  style={{display:"grid"}}>
                                        {
                                            this.app.gestion_panier.state.panier.articles.map(
                                                article=>{
                                                    return  <>
                                                        <div style={{display:"flex",justifyContent:"space-between",background:'gold',margin:"var(--base-spacing)",padding:"var(--base-spacing)",paddingBlock:"var(--block-spacing)"}}>
                                                            <h3>
                                                                {article.produit.nom_langue}
                                                            </h3>
                                                            <div style={{display:"flex",justifyContent:"space-around"}}>
                                                                <span>
                                                                    {article.montant}
                                                                </span>
                                                                <span style={{marginInline:"var(--base-spacing)"}}>-</span>
                                                                <span>
                                                                    x{article.quantite}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </> 
                                                }
                                            )
                                        }
                                    </div>
                                    <div>
                                        
                                    </div>
                                </div>
                                <button>
                                    {this.app.getCurrentLangText("site_text_proceder_au_paiement")}
                                </button>
                            </> 
                        :   <Loader loading={true}/>
                }
            </div>

        )
    }


}

export default ZoneValiderCommande