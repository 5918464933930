import React, { Component } from "react";

export default function LabelZone(props) {
  return (
    <>
      {props.whithlogo && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1em",
          }}
        >
          <img src="/assets/pictures/logo-min.svg" />
        </div>
      )}
      {
        props.label &&
        <div className="label-zone-action">
          <div className="ligne_grey"></div>
          <div className="title">
            {props.label}
            {props.number_of_article && (
              <span className="nofont "> ({props.number_of_article})</span>
            )}
          </div>
          <div className="ligne_grey"></div>
          {props.voirPlus && (
            <a href={props.voirPlus??"#"} className="action">
              {window.App.getCurrentLangText("site_text_voir")} +
            </a>
          )}
        </div>
      }
    </>
  );
}
