import { Save } from "react-feather";
import AjaxComp from "./AjaxComp";

class SetDeviseDefaut extends AjaxComp{
    hasChanged = false
    constructor(props){
        super(props)
        this.state = {...props}
        this.state.default_devise = null
        this.customGraphql('devises',{website:true},['id,designation,signe'],results=>{
            console.error('nos devises ',results)
            if(results.devises){
                const {devises} = results
                this.setState({devises})
            }
        })
    }
    setDefaut(e){
        this.state.default_devise = e.target.value
    }
    saveDefaut(){
        const id = this.state.default_devise
        const {deviseChanged} = this.state
        if(id){
            this.ajaxPost("devise_client/"+id+"", {langue_id: this.app.getCurrentLang().id}, (response) => {
                if(!response.errors){
                    if(deviseChanged && ((typeof deviseChanged) == 'function')){
                        deviseChanged()
                    }
                }
              });

            // this.ajaxPost(
            //     "devise_client/"+id,response=>{
            //         if(!response.errors){
            //             if(deviseChanged && ((typeof deviseChanged) == 'function')){
            //                 deviseChanged()
            //             }
            //         }
            //     }
            // )
        }
    }
    render(){
        const devises = this.state.devises ? this.state.devises : []
        console.error("these are devises ",devises)
        return <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                                        
                <select style={{background:'unset',border:'unset',borderBottom:'2px solid #3331',width:'90%',padding:12}} onChange={e=>this.setDefaut(e)}>
                    {
                        devises.map(
                            (devise,key)=>{
                                return <option selected={this.app.client?.devise?.id} value={devise.id} key={key}>
                                    {devise.designation}
                                </option>                                
                            }
                        )
                    }
                </select>
                <span className="icon" onClick={e=>this.saveDefaut()}>
                    <Save size={30}/>
                </span>
        </div>
    }
}

export default SetDeviseDefaut