import { Link } from "react-router-dom";
import AjaxComp from "../../data/AjaxComp";

class SearchBar extends AjaxComp {
  queryname = "createurs";
  attrs_query = [
    "id",
    "point_vente_id",
    "photo",
    "couverture",
    "mis_en_avant",
    "is_createur",
    "categories{id,designation}",
    "telephone",
    "adresse",
    "createur_id",
    "from_website",
    "pays{id,name,code,dial_code}",
    "active",
    "activite{id,designation}",
    "civilite{id,designation}",
    "type_partenariat{id,designation}",
    "designation",
    "email",
    "photo",
    "couverture",
    "created_at_fr",
    "active_text",
  ];
  constructor(props) {
    super(props);
    this.default_conditions = {active:true};
    this.customGraphql("createurs",this.default_conditions,this.attrs_query,data=>{
      if(!data.errors){
        this.state.data.createurs = data
        this.updateSafelyState({data})
      }
    })
    this.ajaxGet(
      'searchhistory',data=>{
        if(!data.errors){
          this.updateSafelyState({history:data?data:[]})
        }
      }
    )
    this.data = {}
  }

  search()  {
    let searchtext = this.suffixe+"/categories?"
    if(this.data.createur_id){
      searchtext += `createur_id=${this.data.createur_id}`
      if(this.data.search){
        searchtext += `&search=${this.data.search}`
      }
    }
    else if(this.data.search){
      searchtext += `search=${this.data.search}`
    }

    this.goTo(searchtext)
    
  }

  render() {
    const createurs = this.state.data.createurs;
    const searchplaceholder = this.app.getCurrentLangText("site_text_bienvenue_que_recherchez_vous")
    let {history}  = this.state
    history = history ? history : []
    console.log(history)
    return (
      <div className="searchbar">
        <input
          onChange={e=>this.data.search = e.target.value}
          type="search"
          name="search"
          // placeholder={searchplaceholder}
           placeholder={this.app.getCurrentLangText("site_text_rechercher_un_createur")}
        />
        {/* <div className="custom-select">
          <select name="createur" onChange={e=>this.data.createur_id = e.target.value}>
            <option value="">CHOISIR UN CRÉATEUR</option>
            {this.state.data.createurs?.map((createur, idx) => {
              return <option value={createur.id} key={idx}>{createur.designation}</option>;
            })}
          </select>
        </div> */}
        <button className="search" onClick={e=>this.search()}>
          {/* <img src="/assets/pictures/search.svg" alt="" /> */}
          {/* <img src="/assets/pictures/searchwhite.svg" alt="" /> */}
          <span className="fa fa-search"></span>
        </button>
        <div className="search-results">
          <div className="resultats"></div>
          <div className="types-recherche">
            <div className="type-recherche">
              <div className="title_text">{this.app.getCurrentLangText("site_text_recherche_populaire")}</div>
              <div className="options-type-recherche">
                {history?.map(
                  ({search})=><a href={this.suffixe+"/categories/?search="+search} className="option-type-recherche-btn">{search}</a>
                )}
              </div>
            </div>
            <div className="type-recherche">
              <div className="title_text">
                {this.app.getCurrentLangText("site_text_recherches_rapides_par_createurs")}
              </div>
              <div className="options-type-recherche">
                {createurs&&createurs.length? createurs.map((createur, idx) => {
                  return (
                    <a
                      href={this.suffixe+"/home-createur/"+createur.id}
                      key={idx}
                      style={{cursor:'pointer'}}
                      className="option-type-recherche option-createur">
                        <span class='mr-2'  onClick={e=>this.data.createur_id = createur.id}>
                          {createur.designation}
                        </span>
                    </a>
                  );
                }):""}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchBar;
