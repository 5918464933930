import AjaxComp from "../AjaxComp";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
class Gestion_Panier extends AjaxComp{
    getTries    =   5
    name = 'gestion_panier'
    getdatatries = 0
    participants = ['recap_produits_commande','details_panier','resume_raccourcis_panier','adresse_panier','raccourcis_panier','zone_commande'];
    queryname = 'ventes'
    default_conditions = {
        is_panier:true
    }
    attrs_query = ["id,total_livraison,date_fr,montant_tva,total_ttc,id,created_at,created_at_fr,total_paiement,total,net_a_payer,total_amount,reliquat,total_remise,remise,remisepourcent,left_to_pay,client_id,client{id,nom_complet,email,telephone,adresse{id,designation}},vente_produits{id,is_promo,pourcentage,declinaison_id,vente_id,montant,remise_fournisseur,quantite,total,remise_total,total_sans_remise,valeur_tva,montant_ttc,declinaison_id,declinaison{id,produit_id,nom_declinaison,produit{code,id,image,designation,prix,declinaison_produits{id}},couleur_id,taille_id,display_text},offert,produit_id,produit{id,createur{designation,photo,id},code,image,designation,prix,prix_devises{prix},prix_vente_final,famille_produit{designation}}},etat,mode_paiement{id,designation,online},createur{id,designation,couverture,photo},createur_id,mode_paiement_id,zone_livraison{id,designation,montant},zone_livraison_id"]
    data = {
        produits:[],
        total:0
    }
    constructor(props){
        super(props)
        this.state.gotData = false
        this.state.montant_current_zone = 0
        this.app.gestion_panier = this
        this.customGraphql(
            'pays',null,['id,code,name,zone_livraison_id,zone_livraison{designation,id},display_commande'],data=>{
                if(data.errors){
                    console.log('erreur pays data ,',data.errors)
                }else{
                    this.updateSafelyState({pays:data.pays})
                }
            }
        )
    }

    getProduitsPanier(items) {
        let tabProduit = [];
        
        items.forEach(oneItem => {
            tabProduit.push({
                produit_id: oneItem.produit.id,
                declinaison_id: oneItem.declinaison_id,
                total: oneItem.total,
                total_sans_remise: oneItem.total_sans_remise,
                total_sans_remise: oneItem.total_sans_remise,
                vente_id: oneItem.vente_id,
                quantite: oneItem.quantite,
            });
        });
        
        console.log("ici le produit panier tabProduit", JSON.stringify(tabProduit));
        return JSON.stringify(tabProduit);
    }

    article_name(article){
        let name = article.declinaison_id && article.declinaison ? article.declinaison.nom_declinaison.split('-').join(' ') : article.produit.nom_langue
        name = name.split(' ').map(
            string =>{
                return (string[0].toUpperCase() +
                string.slice(1));
            }
        )
        name = name.join(' ')
        return name
    }
    article_image(article){
        return this.checkImage(article.produit.image) ? article.produit.image : article.produit.createur.photo
        // return this.checkImage(article.produit.image) ? article.produit.image : this.apiUri+"/"+article.produit.createur.photo
    }
    article_price(article,is_promo=false){
        return this.app.getCurrentPrice(article.produit,is_promo)
    }
    article_total(article){
        return this.article_quantity(article) * this.article_price(article,article.is_promo)
    }
    total_commande(articles)
    {
        let total = 0
        articles.forEach(
            article=>{
                total += this.article_total(article)
            }
        )
        return total
    }
    article_quantity(article){
        return article.quantite
    }
    gotData(){
        return this.state.gotData
    }
    articles(){
        let articles = []
        if(this.state.gotData){
            if(this.state.panier){
                articles = this.state.panier.articles
            }
        }
        return articles
    }
    nb_articles(){
        let articles = []
        if(this.state.gotData){
            if(this.state.panier && this.state.panier.articles){
                articles = this.state.panier.articles
            }
        }
        return articles.length
    }
    total(){
        let total = 0
        if(this.state.gotData){
            if(this.state.panier){
                total = this.state.panier.total
            }
        }
        return total
    }
    remisepourcent(){
        let total_ttc = 0
        if(this.state.gotData){
            if(this.state.panier){
                total_ttc = this.state.panier.total_ttc
            }
        }
        return total_ttc
    }
    remise(){
        let remise = 0
        if(this.state.gotData){
            if(this.state.panier){
                remise = this.state.panier.remise
            }
        }
        return remise
    }
    total_remise(){
        let total_remise = 0
        if(this.state.gotData){
            if(this.state.panier){
                total_remise = this.state.panier.total_remise
            }
        }
        return total_remise
    }
    total_ttc(){
        let total_ttc = 0
        if(this.state.gotData){
            if(this.state.panier){
                total_ttc = this.state.panier.total_ttc
            }
        }
        return total_ttc
    }
    tva(){
        let tva = 0
        if(this.state.gotData){
            if(this.state.panier){
                tva = this.state.panier.montant_tva
            }
        }
        return tva
    }
    addToCart(produit,quantite,cb=()=>{}){
        console.log('panier ici ajouter',produit,quantite)
        this.ajaxPost(
            'panier',
            {produit,client:this.app.state.client_id,quantite,langue_id: this.app.getCurrentLang().id },
            response=>{
                if((typeof cb) == 'function'){
                    cb(response)
                }
                this.getIt()
            }
        )
    }
    isInCart(id){
        let match = false
        const articles = this.articles()
        if(articles){
            articles.forEach(
                article=>{
                    if(article.declinaison_id == id){
                        match = true
                    } 
                
                }
            )
        }
        return match
    }
    delFromCart(produit,cb=()=>{}){
        this.ajaxPost(
            'remove-panier',
            {produit,client:this.app.state.client.id,langue_id: this.app.getCurrentLang().id},
            response=>{
                this.getIt()
                return cb(response)
            }
        )
    }
    setModePaiement(modepaiement,id){
        if(this.app.gestion_panier.state.panier){
            this.ajaxPost(
                'panier/modepaiement',{id,mode_paiement_id:modepaiement.id,langue_id: this.app.getCurrentLang().id},data=>{
                    this.app.gestion_panier.getIt()
                }
            )
        }
        this.updateSafelyState({current_modepaiement:modepaiement})
    }
    setCurrentZone(zone,id){

        if(zone && id)
        {
            if(this.app.gestion_panier.state.panier){
                this.ajaxPost(
                    'panier/zonelivraison',{id,zone_livraison_id:zone.id,langue_id: this.app.getCurrentLang().id},data=>{
                        this.app.gestion_panier.getIt()
                    }
                )
            }
            let data = {current_zone:zone.id,montant_current_zone:zone.montant,zone_livraison_id:zone.id}
            this.setState({panier:{...this.state.panier,...data}})
        }
        
    }
    dispatchCart(data){
        if(this.app.zone_validercommande)
        {
            if(this.app.zone_validercommande.state.mounted)
            {
                this.app.zone_validercommande.forceUpdate()
            } 
        }
        if(this.app.resume_raccourcis_panier)
        {
            if(this.app.resume_raccourcis_panier.state.mounted)
            {
                this.app.resume_raccourcis_panier.forceUpdate()
            } 
        }
        if(this.app.raccourcis_panier)
        {
            this.app.raccourcis_panier.forceUpdate()  
        }
        if(this.app.details_panier)
        {
            this.app.details_panier.forceUpdate()  
        }
        if(this.app.recap_produits_commande)
        {
            this.app.recap_produits_commande.forceUpdate()     
        }
        if(this.app.zone_commande)
        {
            this.app.zone_commande.forceUpdate()
        }       
    }
    getIt(cb=()=>{}){
        console.error('retry get cart in 3s');
        if(!this.app.state.client){
            this.getTries-- 
            setTimeout(
                (_self)=>{
                    if(this.getTries)
                    {
                        _self.getIt(cb)
                    }
                },3000,this
            );
        }else{
            this.default_conditions.client_id = this.app.state.client.id
            this.customGraphql(
                'ventes',this.default_conditions,this.attrs_query
                ,data=>{
                    let panier = []
                    if(data.hasOwnProperty('ventes') && Array.isArray(data.ventes)){
                        // panier =  data.ventes.length ? data.ventes[0]  : {}
                        panier = data.ventes.length ? data.ventes : []
                        // panier =  data.ventes.length ? data.ventes  : [{}]
                    }
                    panier = this.processPanierABit(panier)
                    this.updateSafelyState({panier,gotData:true})
                    this.dispatchCart(panier)
                    cb(data,panier)
                }       
            )
        }
    }
    processPanierABit(panier){
        const data = {}
        if(panier.zone_livraison_id && panier.zone_livraison){
            data.current_zone                   =   panier.zone_livraison_id
            data.montant_current_zone           =   panier.zone_livraison.montant
        }
        if(panier.mode_paiement_id && panier.mode_paiement){
            data.current_modepaiement           =   panier.mode_paiement
        }
        if(panier.length){
            const articles       = []
            const vente_produits = []
            let   total    = 0

            panier.map(
                vente=>{
                    total+=vente.total
                    if(vente.vente_produits){
                        articles        .push(...vente.vente_produits)
                        vente_produits  .push(...vente.vente_produits)
                    }
                }
            )
            panier = {articles,vente_produits,total,panier}
        }

        this.updateSafelyState({panier})
        this.updateSafelyState({...data})
        return panier
    }
    onlinePay(commande,modepaiement,cb=console.log){
        if(modepaiement && modepaiement.id && commande && commande.id){
            this.ajaxPost('panier/paiement',{mode_paiement_id:modepaiement.id,id:commande.id,langue_id: this.app.getCurrentLang().id},response=>{
                if(response.errors){
                    this.app.setDisplayError(response.errors)
                }
                if(response.message){
                    this.app.setDisplayMessage(response.message);
                   // this.app.gestion_panier.getIt();
                }
                if(response.hasOwnProperty('data')){
                    //this.app.gestion_panier.getIt();
                    console.log('response apres online pay',response)
                    if(response.data.url){
                        let payPopup = window.open(response.data.url, this.app.getCurrentLangText("site_text_proceder_au_paiement"), "width=800,height=600");
                        // payPopup.addEventListener('close', (event) => {
                        //     console.info('closed')
                        // })
                        if (payPopup) {
                            let popupCheckInterval = setInterval(() => {
                                try {
                                    if (payPopup.closed) {
                                        clearInterval(popupCheckInterval);
                                        console.info('closed');
                                        // Faites d'autres actions si nécessaire après la fermeture de la fenêtre
                                    }
                                } catch (e) {
                                    // Ignorez les erreurs de même origine
                                }
                            }, 500); // Vérifiez toutes les 500 ms
                        }
                        else 
                        {
                            console.error('Popup failed to open');
                        }
                    }
                    else
                    {
                      // this.goTo(this.suffixe+'/commande');

                    }
                }
                // console.clear()
                console.warn("reponse online pay ....",response)
            })
        }
    }
    confirmValidate(commande,cb=()=>{}){
        if(commande)
        {
            console.log("commande ici",commande, this.getProduitsPanier(commande.vente_produits));
            
            const nom_complet_livraison =  document.querySelector("#nom_complet_commande")      ?   document.querySelector("#nom_complet_commande").value     :   "" 
            const pays_livraison        =  document.querySelector("#pays_commande")             ?   document.querySelector("#pays_commande").value            :   "" 
            const adresse_livraison     =  document.querySelector("#adresse_commande")          ?   document.querySelector("#adresse_commande").value         :   "" 
            const email_livraison       =  document.querySelector("#email_commande")            ?   document.querySelector("#email_commande").value         :   "" 
            const ville_livraison       =  document.querySelector("#ville_commande")            ?   document.querySelector("#ville_commande").value           :   "" 
            const code_postal_livraison =  document.querySelector("#code_postal_commande")      ?   document.querySelector("#code_postal_commande").value     :   "" 
            const telephone_livraison   =  document.querySelector("#telephone_commande")        ?   document.querySelector("#telephone_commande").value       :   "" 
            
            const pays_livraison_id     =  (pays_livraison && this.localSearch('pays','code',pays_livraison.toUpperCase())) ?  this.localSearch('pays','code',pays_livraison.toUpperCase())[0].id : null
            // if(!nom_complet_livraison || !pays_livraison || !adresse_livraison || !email_livraison || !ville_livraison || !code_postal_livraison || !telephone_livraison)
            // {
            //     this.app.setDisplayError(this.app.getCurrentLangText("site_text_veuillez_rensigner_tous_les_champs_de_la_section_generale"))
            //     return
            // }
            const execute               =  ()=>
            {
                const modepaiement = commande.mode_paiement
                if(modepaiement){
                    if(modepaiement.online)
                    {
                        // console.log("commande") // c'est apres validation commande
                        // this.onlinePay(commande,modepaiement,(response)=>{
                        //     console.info(response)
                        //     // alert('i got online pay response')
                        // })
                        this.ajaxPost(
                            'commande', {produits:this.getProduitsPanier(commande.vente_produits),total:commande.total,id:commande.id,client:commande.client?.id,nom_complet_livraison,email_livraison,pays_livraison,adresse_livraison,ville_livraison,code_postal_livraison,telephone_livraison,langue_id: this.app.getCurrentLang().id},response=>{
                                console.log(response, "ici apres envoie")
                                if(response.errors)
                                {
                                    this.app.setDisplayError(response.errors)
                                }
                                else 
                                {
                                    this.onlinePay(commande,modepaiement,(response)=>{
                                        console.log(response)
                                        // alert('i got online pay response')
                                    })
                                }
                                this.getIt()
                                cb(response)
                            }
                        ) 
                    }
                    // a revoir si la commande m;est pas en ligne
                    // else
                    // {
                    //     this.goTo(this.suffixe+'/commande');
                    // }
                }
                else
                {
                    this.app.setDisplayMessage(this.app.getCurrentLangText("site_text_veuillez_definir_un_mode_de_paiement"))
                }
            }

            if(this.app.state.client.visitor)
            {
                const options = {

                    title: this.app.getCurrentLangText("site_text_creer_un_compte"),                        // Title dialog
                    message: this.app.getCurrentLangText("site_text_vous_devez_avoir_un_compte_pour_prendre_un_commande"),               // Message dialog
                    childrenElement: () => <div>{this.app.getCurrentLangText("site_text_continuer")} </div>,       // Custom UI or Component
                    buttons: [
                        {
                          label: 'Continuer',
                          onClick: () => 
                          {
                            this.setupClientData(
                                client=>
                                {
                                    delete(client.password)
                                    client = {
                                            ...client
                                            ,...{
                                                nom_complet:nom_complet_livraison,
                                                pays_id:pays_livraison_id,
                                                adresse:adresse_livraison,
                                                email:email_livraison,
                                                ville:ville_livraison,
                                                code_postal:code_postal_livraison,
                                                telephone:telephone_livraison,
                                                typeclient:client.type_client.id
                                            }
                                        }
                                    client.langue_id = this.app.getCurrentLang().id
                                    console.info(client)
                                    this.ajaxPost(
                                        'client',client,response=>{
                                            if(response.data)
                                            {
                                                this.app.requestClientData()
                                                execute()
                                            }
                                        }
                                    )
                                }
                            )
                          }
                        },
                        {
                          label: this.app.getCurrentLangText("site_text_annuler"),
                          onClick: () => console.info('Action after annuler')
                        }
                      ],             // Action after Cancel
                    overlayClassName: "overlay-custom-class-name"      // Custom overlay class name
                }
                confirmAlert(options)
            }
            else
            {
                execute()
            }
        }
    }

    validate(commande,cb=()=>{}){
        this.confirmValidate(commande,cb)
        
        console.info(commande)
        
    }
}
export default Gestion_Panier