import AjaxComp from "../../data/AjaxComp";
import Star from "./star";

class StarList extends AjaxComp {
  constructor(props) {
    super(props);
  }
  render() {
    const { note } = this.props;
    console.error(note);
    const count = [1, 2, 3];
    return count.map((elem, idx) => {
      return "dkdkfk"
    });
  }
}

export default StarList;
