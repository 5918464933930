import AjaxComp from "../data/AjaxComp"
import LabelZone from "../layout/extra/labelZone"
import MultiPages from "../layout/extra/multipages"
import PolicyComponent from "../layout/sections/politiquequalite"
import FormPicture from "../layout/zones/formpicture"
import './formulaire_partenaire.css'

import Select2 from 'react-select2-wrapper';
import 'select2/dist/css/select2.min.css';

class PartenaireForm extends AjaxComp {
    constructor(props){
        super(props)
        this.state = {type_createur:'particulier'}
        this.app = window.App
        this.app.formulaire_partenaire = this;

        this.state = {
            categories: [],  // Les catégories seront chargées ici
            selectedCategories: []  // Les catégories sélectionnées
          };

        this.customGraphql('categories',{website:true,langue_id:this.app.getCurrentLang().id},["id","designation",'nom_langue'],data=>{
            if(data.errors){
                console.log('erreur categories data ,',data.errors)
            }else{
                if(this.state.mounted)
                {
                    //this.setState({categories:data.categories});
                    const formattedCategories = data?.categories?.map(cat => ({ id: cat.id, text: cat.nom_langue }));
                    this.setState({ categories: formattedCategories });
                }
                else
                {
                    //this.state = {...this.state,...{categories:data.categories}} 
                    const formattedCategories = data?.categories?.map(cat => ({ id: cat.id, text: cat.nom_langue }));
                    this.setState({ categories: formattedCategories });
                }
                //this.updateSafelyState({categories:data.categories});

            }
        })
        this.customGraphql('pays',null,["id","name","code,zone_livraison_id,zone_livraison{designation,id},display_commande"],data=>{
            if(data.errors){
            console.log('erreur pays data ,',data.errors)
            }else{
            this.updateSafelyState({pays:data.pays})
            }
        })
        this.customGraphql('activites',null,["id","designation"],data=>{
            if(data.errors){
                console.log('erreur activites data ,',data.errors)
            }else{
                this.updateSafelyState({activites:data.activites})
            }
        })
        this.customGraphql('civilites',null,["id","designation"],data=>{
            if(data.errors){
                console.log('erreur civilites data ,',data.errors)
            }else{
                this.updateSafelyState({civilites:data.civilites})
            }
        })
        this.customGraphql('devises',{website:true},["id","designation","signe"],data=>{
            if(data.errors){
                console.log('erreur devises data ,',data.errors)
            }else{
                this.updateSafelyState({devises:data.devises})
            }
        })
  
    }

    handleSelectChange = (e) => {
        const values = Array.from(e.target.selectedOptions, option => option.value);
        this.setState({ selectedCategories: values });
    };
    
    setTypeCreateur(typecreateur,parent){
        if(['particulier','entreprise'].includes(typecreateur)){
            this.updateSafelyState({type_createur:typecreateur})
            parent.forceUpdate()
        }else{
            this.setDisplayError(this.app.getCurrentLangText("site_text_type_de_createur_required"))
        }
    }

    
    clearFileInput(id) {
        const input = document.getElementById(id)
        if(input){
            input.value = ''; // Set the value to an empty string
        }
    }

    previewImageCreateur(e){
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onloadend = ()=>{
            const img = document.querySelector('#apercu_'+e.target.id+' img')
            if(img){
                img.src= reader.result
            } 
        }
        reader.readAsDataURL(file)
    }

    render(){

        
        const pages = ()=>[
            (parent)=>{ 
                    return <div className="zone" style={{display:'grid',alignItems:'center'}}>
                        <h1>
                            {this.app.getCurrentLangText("site_text_creer_ma_boutique") ? this.app.getCurrentLangText("site_text_creer_ma_boutique") : 'Créer ma boutique'}
                        </h1>
                        <p>
                            {this.app.getCurrentLangText("site_text_vous_etes_un_createur") ? this.app.getCurrentLangText("site_text_vous_etes_un_createur") : 'Vous êtes un(e) créateur(trice)'} ? {this.app.getCurrentLangText("site_text_inspirez_vous_de_la_culture") ? this.app.getCurrentLangText("site_text_inspirez_vous_de_la_culture") : 'Vous vous inspirez de la culture africaine ou êtes simplement un(e) passionné(e) d’artisanat'} ? {this.app.getCurrentLangText("site_text_vendez_et_faites_connaitre_vos_creations") ? this.app.getCurrentLangText("site_text_vendez_et_faites_connaitre_vos_creations") : 'Vendez et faites connaitre vos créations sur nahyelconceptstore.com'}.
                        </p>
                    </div>
                
            },
             (parent)=>{
                return <PolicyComponent parent={parent}/>
            },
             (parent)=>{
                const validate = (e)=>{e.preventDefault();this.formPost('demandecreateur',document.querySelector('#section-partenaire form'),null,null,'#section-partenaire')}
                if(!parent.state.custombutton)
                {
                    parent.setState({custombutton:true,custombuttontext:this.app.getCurrentLangText("site_text_soumettre"),custombuttonaction:validate})
                }
                console.info(this,parent)
                return <>
                    <form action="" className="zone" encType="multipart/form-data" onSubmit={validate}>
                        <input type='hidden' name='from_website' value='true'/>
                        <input type='hidden' name='langue_id' value={this.app.getCurrentLang().id} />
                        <h1>
                            {this.app.getCurrentLangText("site_text_devenir_partenaire")}
                        </h1>

                        <fieldset class="kt_content accordion accordion-solid accordion-panel accordion-svg-toggle py-2">
                            <div class="">
                                <div class="d-flex  flex-row justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#general_addcreateur" aria-expanded="false" aria-controls="generalDropdown" style={{cursor:"pointer"}}>
                                    <legend>
                                        {this.app.getCurrentLangText("site_text_general")}
                                    </legend>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16">
                                        <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                    </svg>
                                </div>
                                <div id="general_addcreateur" class=" collapse" aria-labelledby="generalDropdown ">
                                    <hr/>
                                    <div className="formsection">
                                        <label htmlFor="nom_complet" className="label_name">
                                            {this.app.getCurrentLangText("site_text_nom_complet")}*
                                        </label>
                                        <div className="double-fields" name="nom_complet">
                                            <div className="formfield input_container">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/user-client-color.svg" alt="" />
                                                </div>
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_prenom")} name='prenom' />
                                                </div>
                                            </div>
                                            <div className="formfield input_container">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/user-client-color.svg" alt="" />
                                                </div>
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_nom_de_famille")} name='nom'/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="double-label">
                                            <label htmlFor="nom_complet" className="label_name">
                                                {this.app.getCurrentLangText("site_text_email")}
                                            </label>
                                            <label htmlFor="nom_complet" className="label_name">
                                                {this.app.getCurrentLangText("site_text_telephone")}
                                            </label>
                                        </div>
                                        <div className="double-fields">
                                            <div className="formfield input_container" name="email">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/icon-mail-color.svg" alt="" />
                                                </div>
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_email")} name='email'/>
                                                </div>
                                            </div>
                                            <div className="formfield input_container" name="telephone">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/icon-phone-color.svg" alt="" />
                                                </div>
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_telephone")} name='telephone'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="double-label">
                                            <label htmlFor='nom_marque'>
                                                {this.app.getCurrentLangText("site_text_nom_de_la_marque")}*
                                            </label>

                                            <label htmlFor="adresse" className="label_name">
                                                {this.app.getCurrentLangText("site_text_specialisation")}*
                                            </label>
                                        </div>
                                        <div class='double-fields'>
                                            <div className="formfield input_container" name="nom_marque">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/user-client-color.svg" alt="" />
                                                </div>
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_nom_de_la_marque")} name='designation'/>
                                                </div>
                                            </div>
                                            <div className="formfield input_container">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/user-client-color.svg" alt="" />
                                                </div>
                                                <div className="input">
                                                    <select style={{width:"100%"}} name='activite_id'>
                                                        <option>
                                                            {this.app.getCurrentLangText("site_text_selectionner")}
                                                        </option>
                                                        {this.state.activites?.map((c,idx)=><option key={idx} value={c.id}>{c.designation}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="double-label">
                                            <label htmlFor="pays_residence" className="label_name">
                                                {this.app.getCurrentLangText("site_text_pays_de_residence")}*
                                            </label>
                                            <label htmlFor="adresse" className="label_name">
                                                {this.app.getCurrentLangText("site_text_adresse")}
                                            </label>
                                        </div>
                                        <div className="double-fields">
                                            <div className="formfield input_container" name="pays_id">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/icon-mail-color.svg" alt="" />
                                                </div>
                                                <div className="input">
                                                    <select name='pays_id'>
                                                        <option value="">
                                                            {this.app.getCurrentLangText("site_text_selectionner")}
                                                        </option>
                                                        {
                                                            this.state.pays?.map(
                                                                p=><option value={p.id}>
                                                                    {p.name}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="formfield input_container" name="email">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/icon-adresse-color.svg" alt="" style={{width:"100%"}}/>
                                                </div>
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_adresse")} name='adresse'/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="double-label">
                                            <label htmlFor="ville" className="label_name">
                                                {this.app.getCurrentLangText("site_text_ville")}*
                                            </label>
                                            <label htmlFor="code_postal" className="label_name">
                                                {this.app.getCurrentLangText("site_text_code_postal")}*
                                            </label>
                                        </div>
                                        <div className="double-fields">
                                            <div className="formfield input_container" name="email">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/icon-adresse-color.svg" alt="" style={{width:"100%"}}/>
                                                </div>
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_ville")} name='ville'/>
                                                </div>
                                            </div>
                                            <div className="formfield input_container" name="email">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/icon-code-postal-color.svg" alt="" style={{width:"100%"}}/>
                                                </div>
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_code_postal")} name='code_postal'/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="double-label">
                                            <label htmlFor="civilite" className="label_name">
                                                {this.app.getCurrentLangText("site_text_civilite")}*
                                            </label>
                                            <label htmlFor="devise" className="label_name">
                                                {this.app.getCurrentLangText("site_text_devise_principale")}
                                            </label>
                                        </div>
                                        <div className="double-fields">
                                            
                                            <div className="formfield input_container">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/user-client-color.svg" alt="" />
                                                </div>
                                                <div className="input">
                                                    <select style={{width:"100%"}} name='civilite_id'>
                                                        <option>
                                                            {this.app.getCurrentLangText("Selectionner")}
                                                        </option>
                                                        {this.state.civilites?.map((c,idx)=><option key={idx} value={c.id}>{c.designation}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="formfield input_container">
                                                <div className="icon">
                                                    <img loading="lazy" src="/assets/pictures/user-client-color.svg" alt="" />
                                                </div>
                                                <div className="input">
                                                    <select style={{width:"100%"}} name='devise_id'>
                                                        <option>
                                                            {this.app.getCurrentLangText("site_text_selectionner")}
                                                        </option>
                                                        {this.state.devises?.map((c,idx)=><option key={idx} value={c.id}>{c.designation}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sepa noline"></div>
                                        <label htmlFor="type_createur" className='label_name'>
                                            {this.app.getCurrentLangText("site_text_type_de_createur")}*
                                        </label>
                                        <div className="formfield input_container">
                                            <div className="icon">
                                                <img loading="lazy" src="/assets/pictures/user-client-color.svg" alt="" />
                                            </div>
                                            <div className="input">
                                                <select style={{width:"100%"}} name='type_createur' onChange={e => this.setTypeCreateur(e.target.value,parent)}>
                                                    <option>
                                                        {this.app.getCurrentLangText("site_text_selectionner")}
                                                    </option>
                                                    <option value='particulier'>
                                                        {this.app.getCurrentLangText("site_text_particulier")}
                                                    </option>
                                                    <option value='entreprise'>
                                                        {this.app.getCurrentLangText("site_text_entreprise")}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </fieldset>
                        {
                            this.state.type_createur == 'entreprise'  ? <div className="formsection">
                                <fieldset class="accordion accordion-solid accordion-panel accordion-svg-toggle">
                                    <div class="">
                                        <div class="d-flex  flex-row justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#marque_addcreateur" aria-expanded="false" aria-controls="filtersDropdown" style={{cursor:'pointer'}}>
                                            <legend>
                                                {this.app.getCurrentLangText("site_text_a_propos_de_la_marque")}
                                            </legend>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16">
                                                <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                            </svg>
                                        </div>
                                        <div id="marque_addcreateur" class=" collapse" aria-labelledby="filtersDropdown ">
                                            <hr/>
                                            <label htmlFor='nom_legal_marque'>
                                                {this.app.getCurrentLangText("site_text_nom_legal")}*
                                            </label>
                                            <div className="formfield input_container" name="nom_legal_marque">
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_nom_legal_de_la_marque")} name='nom_legal'/>
                                                </div>
                                            </div>
                                            <div className="sepa noline"></div>
                                            <label htmlFor='annee_debut_activite'>
                                                {this.app.getCurrentLangText("Année de début de votre activité")}*
                                            </label>
                                            <div className="formfield input_container" name="annee_debut_activite">
                                                <div className="input">
                                                    <input type="number" min='1900' max='2023' placeholder={this.app.getCurrentLangText("site_text_annee_debut_dactivite")} name='date_debut_activite'/>
                                                </div>
                                            </div>
                                            <div className="sepa noline"></div>
                                            <label htmlFor='adresse_siege'>
                                                {this.app.getCurrentLangText("site_text_adresse_siege")}*
                                            </label>
                                            <div className="formfield input_container" name="adresse_siege">
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_adresse_siege")} name='adresse_siege'/>
                                                </div>
                                            </div>
                                            <div className="sepa noline"></div>
                                            <label htmlFor='id_fiscal'>
                                                {this.app.getCurrentLangText("site_text_numero_didentification_fiscale")}*
                                            </label>
                                            <div className="formfield input_container" name="id_fiscal">
                                                <div className="input">
                                                    <input type="text" placeholder={this.app.getCurrentLangText("site_text_numero_didentification_fiscale")} name='id_fiscal'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                </fieldset>
                            </div> : ''
                        }
                        <fieldset class="kt_content accordion accordion-solid accordion-panel accordion-svg-toggle py-2">
                            <div class="">
                                <div class="d-flex  flex-row justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#networks_addcreateur" aria-expanded="false" aria-controls="filtersDropdown" style={{cursor:"pointer"}}>
                                    <legend>
                                        {this.app.getCurrentLangText("site_text_reseaux_sociaux")}
                                    </legend>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16">
                                        <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                    </svg>
                                </div>
                                <div id="networks_addcreateur" class=" collapse" aria-labelledby="filtersDropdown ">
                                    <hr/>
                                    <div class="fomsection">
                                        <label htmlFor='lien_facebook'>
                                            {this.app.getCurrentLangText("site_text_lien_facebook")}
                                        </label>
                                        <div className="formfield input_container">
                                            <div className="input">
                                                <input  name="lien_facebook" type="text" placeholder={this.app.getCurrentLangText("site_text_lien_facebook")}/>
                                            </div>
                                        </div>
                                        <div className="sepa noline"></div>
                                        <label htmlFor='lien_instagram'>
                                            {this.app.getCurrentLangText("site_text_lien_instagram")}
                                        </label>
                                        <div className="formfield input_container">
                                            <div className="input">
                                                <input  name="lien_instagram" type="text" placeholder={this.app.getCurrentLangText("site_text_lien_instagram")}/>
                                            </div>
                                        </div>
                                        <div className="sepa noline"></div>
                                        <label htmlFor='lien_tiktok'>
                                            {this.app.getCurrentLangText("site_text_lien_tiktok")}
                                        </label>
                                        <div className="formfield input_container">
                                            <div className="input">
                                                <input  name="lien_tiktok" type="text" placeholder={this.app.getCurrentLangText("site_text_lien_tiktok")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </fieldset>



                        <fieldset class="kt_content accordion accordion-solid accordion-panel accordion-svg-toggle py-2">
                            <div class="">
                                <div class="d-flex  flex-row justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#creations_addcreateur" aria-expanded="false" aria-controls="creationsDropdown" style={{cursor:"pointer"}}>
                                    <legend>
                                        {this.app.getCurrentLangText("site_text_apercu_des_creations")}
                                    </legend>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16">
                                        <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                    </svg>
                                </div>
                                <div id="creations_addcreateur" class=" collapse" aria-labelledby="creationsDropdown ">
                                    <hr/>
                                    <label htmlFor="place" className="label_name">
                                        {this.app.getCurrentLangText("site_text_veuillez_choisir_une_image")} 6 {this.app.getCurrentLangText("site_text_images_representant_vos_creations")}
                                    </label>
                                    <div className="row">
                                        <input type="file" style={{display:"none"}} name='images_createur_1' id='images_createur_1' onChange={e=>this.previewImageCreateur(e)}/>
                                        <input type="file" style={{display:"none"}} name='images_createur_2' id='images_createur_2' onChange={e=>this.previewImageCreateur(e)}/>
                                        <input type="file" style={{display:"none"}} name='images_createur_3' id='images_createur_3' onChange={e=>this.previewImageCreateur(e)}/>
                                        <input type="file" style={{display:"none"}} name='images_createur_4' id='images_createur_4' onChange={e=>this.previewImageCreateur(e)}/>
                                        <input type="file" style={{display:"none"}} name='images_createur_5' id='images_createur_5' onChange={e=>this.previewImageCreateur(e)}/>
                                        <input type="file" style={{display:"none"}} name='images_createur_6' id='images_createur_6' onChange={e=>this.previewImageCreateur(e)}/>
                                        <div className='col-4' id='apercu_images_createur_1' style={{display:'grid',width:"130px",height:"130px"}} onClick={e=>document.querySelector('#images_createur_1').click()}>
                                            <img loading="lazy" src="/assets/pictures/icon-upload-image.svg" style={{width:"80%",height:"80%",margin:"auto"}}/>
                                        </div>
                                        <div className='col-4' id='apercu_images_createur_2' style={{display:'grid',width:"130px",height:"130px"}} onClick={e=>document.querySelector('#images_createur_2').click()}>
                                            <img loading="lazy" src="/assets/pictures/icon-upload-image.svg" style={{width:"80%",height:"80%",margin:"auto"}}/>
                                        </div>
                                        <div className='col-4' id='apercu_images_createur_3' style={{display:'grid',width:"130px",height:"130px"}} onClick={e=>document.querySelector('#images_createur_3').click()}>
                                            <img loading="lazy" src="/assets/pictures/icon-upload-image.svg" style={{width:"80%",height:"80%",margin:"auto"}}/>
                                        </div>
                                        <div className='col-4' id='apercu_images_createur_4' style={{display:'grid',width:"130px",height:"130px"}} onClick={e=>document.querySelector('#images_createur_4').click()}>
                                            <img loading="lazy" src="/assets/pictures/icon-upload-image.svg" style={{width:"80%",height:"80%",margin:"auto"}}/>
                                        </div>
                                        <div className='col-4' id='apercu_images_createur_5' style={{display:'grid',width:"130px",height:"130px"}} onClick={e=>document.querySelector('#images_createur_5').click()}>
                                            <img loading="lazy" src="/assets/pictures/icon-upload-image.svg" style={{width:"80%",height:"80%",margin:"auto"}}/>
                                        </div>
                                        <div className='col-4' id='apercu_images_createur_6' style={{display:'grid',width:"130px",height:"130px"}} onClick={e=>document.querySelector('#images_createur_6').click()}>
                                            <img loading="lazy" src="/assets/pictures/icon-upload-image.svg" style={{width:"80%",height:"80%",margin:"auto"}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr/>
                        </fieldset>

                        <fieldset class="kt_content accordion accordion-solid accordion-panel accordion-svg-toggle py-2">
                            <div class="">
                                <div class="d-flex  flex-row justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#categories_addcreateur" aria-expanded="false" aria-controls="creationsDropdown" style={{cursor:"pointer"}}>
                                    <legend>
                                        {this.app.getCurrentLangText("site_text_categories")}
                                    </legend>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16">
                                        <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                    </svg>
                                </div>
                                <div id="categories_addcreateur" class=" collapse" aria-labelledby="categoriesDropdown ">
                                    <hr/>

                                    
                                        <label htmlFor="categories" className="label_name">
                                            {this.app.getCurrentLangText("site_text_veuillez_selectionner_les_categories")}
                                        </label>
                                        <div className="formfield input_container">
                                            <div className="input">
                                                {/* <select style={{width:"100%"}} name='categories[]' multiple> 
                                                    {
                                                        this.state.categories?this.state.categories.map(
                                                            cat=>{
                                                                return <option value={cat.id}>
                                                                    {cat.nom_langue}
                                                                </option>
                                                            }
                                                        ):''
                                                    }
                                                </select> */}

                                                <Select2
                                                        multiple
                                                        style={{ width: '100%' }}
                                                        data={this.state.categories}
                                                        value={this.state.selectedCategories}
                                                        onChange={this.handleSelectChange}
                                                        options={{
                                                            placeholder: 'Select categories',
                                                        }}
                                                        name='categories[]'
                                                        />
                                                    
                                            </div>
                                        
                                        {/* {
                                            this.state.categories?this.state.categories.map(
                                                cat=>{
                                                    return <span className="col-md-3 col-sm-12 d-flex">
                                                        <input type='checkbox' name='categories[]' className="mr-2" value={cat.id}/> <span onClick={e=>e.target.previousElementSibling.click()}>{cat.nom_langue}</span>
                                                    </span>
                                                }
                                            ):''
                                        } */}
                                    </div>
                                    <label>
                                        {this.app.getCurrentLangText("site_text_autre")} ?
                                    </label>
                                    <div className="formfield input_container" name="place">
                                        <div className="input">
                                            <input name="autre_categorie" type="text" placeholder={this.app.getCurrentLangText("site_text_saisir")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </fieldset>


                        <div className='formsection'>
                            <label htmlFor="place" className="label_name">
                                {this.app.getCurrentLangText("site_text_votre_message")}
                            </label>
                            <div className="formfield input_container" name="place">
                                <div className="input">
                                    <textarea name="message" id="" cols="30" rows="10"></textarea>
                                </div>
                            </div>
                        </div>
                        {/* <div className="buttonbox">
                            <button>
                                SOUMETTRE
                            </button>
                        </div> */}
                    </form>
                </>
            }
        ]
        return (
            <div id="partenaireform">
                <FormPicture>
                    <MultiPages pages={pages()}>
                    </MultiPages>
                </FormPicture>
            </div>
        )
    }

}

export default PartenaireForm