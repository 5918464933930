import React from 'react';
import AjaxComp from '../../data/AjaxComp';

class RemboursementComponent extends  AjaxComp {
    constructor(props){
        super(props)
        this.state = {...props}
        this.state.validate = false
    }
    valider(value=true){
        this.setState({validate : value})
        if(this.state.parent){
            this.state.parent[(value?'show':'hide')+'Next']()
        }
    }
  render() {
    return (
      <div style={{display:'grid',width:'75%',margin:'auto',alignItems:'center',justifyItems:'center',padding:'1rem',background:'white'}}>
        <h1>{this.app.getCurrentLangText("site_text_politique_de_retour")}</h1>
      
        <div className="texte" style={{padding:"4rem",height:"50vh",overflowY:'scroll'}}>
          <p>
            {this.app.getCurrentLangText("site_text_en_cas_de_non_satisfaction")}
          </p>
          <p>
            {this.app.getCurrentLangText("site_text_il_est_a_preciser_que_le_droit_a_la_retractation_est_valable")}
          </p>
          <p>
            {this.app.getCurrentLangText("site_text_l_acheteur_devra_adresser_directement_les_demandes")}
          </p>
          <p>
            {this.app.getCurrentLangText("site_text_l_acheteur_dispose_d_un_delai_de_14_jours")}
          </p>
          <p>
            {this.app.getCurrentLangText("site_text_l_acheteur_avait_paye_un_produit")}
          </p>
          <p>
            {this.app.getCurrentLangText("site_text_dans_le_cas_dune_reclamation_partielle_apres")}
          </p>
          <p>
            {this.app.getCurrentLangText("site_text_nahyel_se_reserve_le_droit_de_bloquer_temporairement")}
          </p>
        </div>
        <div className="buttonbox">
          <button onClick={e=>this.switchPopupElement(e,'#section-remboursement')} style={{padding:".5rem"}} className="mt-4" id="toggle-section-remboursement">
            {this.app.getCurrentLangText("site_text_fermer")}
          </button>
        </div>
      </div>
    );
  }
}

export default RemboursementComponent;