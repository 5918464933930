import AjaxComp from "../../data/AjaxComp";
class DonneesPanierDetailsProduit extends AjaxComp {
  handleChange = (prop, value) => {
    this.state.data[prop] = value;
    this.setState({ ...this.state });
  };

  data = {
    quantite: 1,
    couleur: null,
    taille: null,
  };
  constructor(props) {
    super(props);
    const { produit } = props;
    this.data.produit = produit;
    this.data.test = null;
    this.data.couleur = produit.declinaisons ? produit.declinaisons[0] : null;
    this.data.taille = this.data.couleur?.declinaisons
      ? this.data.couleur.declinaisons[0]
      : null;
    this.state = { ...props, data: this.data, errors: [], messages: [] };
    this.app.donnees_panier_details_produit = this;
  }

  setDonneesPanier(produit) {
    let data = {};
    data.quantite = 1;
    data.couleur = null;
    data.taille = null;
    // this.app.gestion_panier.
    // gestion_panier.localSearch('vente_produits','id',2,gestion_panier.data.ventes[1])
  }
  isInCart() {
    if (this.state.produit) {
      return this.app.gestion_panier.isInCart(this.state.produit.id);
    }
    return false;
  }
  setCurrentTaille(taille) {
    if (this.data.taille === taille) {
      this.data.taille = null;
    } else {
      this.data.taille = taille;
    }
    this.updateSafelyState({ data: this.data });
  }
  setCurrentCouleur(couleur) {
    console.log("ici couleur check ", couleur);
    this.data.couleur = couleur;
    this.data.test = couleur?.nom_declinaison;
    console.warn(couleur);
    this.updateSafelyState({ data: this.data });
  }
  doAddToCart(e) {
    this.app.gestion_panier.addToCart(
      this.state.data.taille?.id,
      this.state.data?.quantite,
      (response) => {
        this.app.gestion_panier.getIt();
        if (response.errors) {
          this.setState({ errors: [...this.state.errors, response.errors] });
        }
        if (response.message) {
          this.setState({
            messages: [...this.state.messages, response.messages],
          });
        }
      }
    );
  }
  render() {
    const { produit, couleur, taille } = this.state.data;
    const pictures = produit.images;
    return (
      <div className="donnees-panier">
        <div className="to_disabled_on_mobile">
          <div className="info-produit">
            <div className="nom-article-like">
              <div className="nom-article">{produit.nom_langue} {this.data?.couleur?.couleur?.nom ? '(' + this.data?.couleur?.couleur?.nom + ')' : ''}</div>
              <label
                className="like-wrapper cursor-pointer mt-3"
                htmlFor={`favori_product+${produit.id}`}
              >
                <input
                  type="hidden"
                  name=""
                  id={`favori_product+${produit.id}`}
                />
                <svg
                  className={`beatonhover ${
                    this.app.isFavoriClient(produit.id) ? "active" : ""
                  }`}
                  onClick={(e) =>
                    this.app.isFavoriClient(produit.id)
                      ? this.app.removeFavoriClient(produit.id)
                      : this.app.addFavoriClient(produit.id)
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="27"
                  viewBox="0 0 30 27"
                  fill="#1113"
                >
                  <path
                    d="M15 4.90001C15.6 3.90001 16.3 3.10001 17.2 2.40001C18.8 1.30001 20.5 0.900005 22.4 1.20001C25.3 1.70001 27.6 3.90001 28.1 6.70001C28.5 8.50001 28.2 10.3 27.4 12C26.9 13.2 26.1 14.2 25.2 15.2C23.8 16.8 22.1 18.1 20.6 19.5C18.8 21.1 17.1 22.8 15.7 24.7C15.5 25 15.3 25.2 15.1 25.5C15 25.7 14.9 25.7 14.8 25.5C13.1 23 10.9 21 8.7 19C7.3 17.8 5.8 16.5 4.6 15.1C3.3 13.6 2.3 12 1.9 10.1C1.6 8.50001 1.6 7.00001 2.2 5.50001C2.8 4.10001 3.9 2.80001 5.4 2.00001C7.2 1.00001 9.2 0.900005 11.1 1.60001C12.6 2.10001 13.8 3.10001 14.6 4.50001C14.8 4.60001 14.9 4.80001 15 4.90001Z"
                    stroke={
                      this.app.isFavoriClient(produit.id) ? "silver" : "white"
                    }
                    stroke-width="1"
                    stroke-miterlimit="10"
                  />
                </svg>
              </label>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="27"
                viewBox="0 0 30 27"
                fill="none"
              >
                <path
                  d="M15 4.90001C15.6 3.90001 16.3 3.10001 17.2 2.40001C18.8 1.30001 20.5 0.900005 22.4 1.20001C25.3 1.70001 27.6 3.90001 28.1 6.70001C28.5 8.50001 28.2 10.3 27.4 12C26.9 13.2 26.1 14.2 25.2 15.2C23.8 16.8 22.1 18.1 20.6 19.5C18.8 21.1 17.1 22.8 15.7 24.7C15.5 25 15.3 25.2 15.1 25.5C15 25.7 14.9 25.7 14.8 25.5C13.1 23 10.9 21 8.7 19C7.3 17.8 5.8 16.5 4.6 15.1C3.3 13.6 2.3 12 1.9 10.1C1.6 8.50001 1.6 7.00001 2.2 5.50001C2.8 4.10001 3.9 2.80001 5.4 2.00001C7.2 1.00001 9.2 0.900005 11.1 1.60001C12.6 2.10001 13.8 3.10001 14.6 4.50001C14.8 4.60001 14.9 4.80001 15 4.90001Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
              </svg> */}
            </div>
            <div className="prix-produit">
              <span
                style={
                  produit.is_promo
                    ? { textDecoration: "line-through", color: "red" }
                    : {}
                }
              >
                {this.formatNumber(this.app.getCurrentPrice(produit)) + " "}
                {produit.is_promo && this.app.getCurrentDevise()
                  ? this.app.getCurrentDevise().signe
                  : ""}
              </span>
              {produit.is_promo ? (
                <span style={{ marginInline: "var(--base-spacing)" }}>
                  {this.formatNumber(this.app.getCurrentPrice(produit, true))}
                </span>
              ) : (
                ""
              )}
              {this.app.getCurrentDevise()
                ? this.app.getCurrentDevise().signe
                : ""}
            </div>
          </div>
          <div className="sepa"></div>
        </div>
        <div className="quantite donnees">
          <div className="donnees-label">
            {this.app.getCurrentLangText("site_text_quantite")}
          </div>

          <div className="number-input-and-btn">
            <div className="number-input">
              <button
                onClick={(e) => {
                  let input =
                    e.target.parentNode.querySelector("input[type=number]");
                  if (input) {
                    input.stepDown();
                    this.handleChange("quantite", input.value);
                  }
                }}
              >
                -
              </button>
              <input
                className="quantity"
                min="1"
                name="quantity"
                value={this.state.data.quantite}
                type="number"
                onChange={(e) => this.handleChange("quantite", e.target.value)}
                placeholder="quantité"
              />
              {/* <div className="quantity-number">{this.state.data.quantite}</div> */}
              <button
                onClick={(e) => {
                  let input =
                    e.target.parentNode.querySelector("input[type=number]");
                  if (input) {
                    input.stepUp();
                    this.handleChange("quantite", input.value);
                  }
                }}
              >
                +
              </button>
            </div>
            <div className="btn_add_panier">
              {this.state.produit ? (
                this.isInCart() ? (
                  <button
                    onClick={(e) => this.app.gestion_panier.delFromCart()}
                  >
                    {this.app.getCurrentLangText("site_text_retirer_du_panier")}
                  </button>
                ) : (
                  <button onClick={(e) => this.doAddToCart(e)}>
                    {this.app.getCurrentLangText("site_text_ajouter_panier")}
                  </button>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="sepa"></div>
        <div className="sur_commande donnees d-flex justify-content-between">
          <div className="donnees-label">
            {this.app.getCurrentLangText("site_text_produit_sur_commande")}
          </div>
          <div className="donnees-valeur">
            <span
              className={
                "p-2 badge badge-" +
                (produit.sur_commande ? "success" : "danger")
              }
            >
              {produit.sur_commande
                ? this.app.getCurrentLangText("site_text_oui")
                : this.app.getCurrentLangText("site_text_non")}
            </span>
          </div>
        </div>
        <div className="sepa"></div>
        <div className="couleur donnees">
          <div className="donnees-label">
            {this.app.getCurrentLangText("site_text_couleur")}
          </div>
          <div className="liste-couleurs">
            {produit.declinaisons.map((declinaison_couleur) => {
              const { couleur, nom_declinaison } = declinaison_couleur;
              const { code_couleur, nom } = couleur;
              const border = code_couleur ? "none" : "2px solid black";
              return (
                <div
                  className="couleur"
                  style={{ background: code_couleur, border: border }}
                  onClick={(e) => this.setCurrentCouleur(declinaison_couleur)}
                  title={nom}
                ></div>
              );
            })}
          </div>
        </div>
        <div className="sepa"></div>
        <div className="wrap-dropdown expanded">
          <div className="wrap-dropdown-head">
            <div className="dropdown-label">
              {this.app.getCurrentLangText("site_text_choisir_une_taille")}
            </div>
            <br />
            <br />
            {/* <div className="dropdown-icon">
              <img loading="lazy" src="/assets/pictures/caret.svg" alt="" />
            </div> */}
          </div>
          <div className="wrap-dropdown-body">
            <div className="choicelist">
              {/* {this.data.couleur.nom_declinaison} */}
              {couleur?.declinaisons.map((declinaison_taille, index) => {
                return (
                  <div
                    className="donnees choice"
                    onClick={(e) =>
                      this.handleChange("taille", declinaison_taille)
                    }
                  >
                    <div className="donnees-label">
                      {declinaison_taille?.produit.nom_langue} 
                      <div className="text-capitalize">{declinaison_taille?.taille?.nom} {" "}</div>
                      {/* <div class="checkbox-wrapper-33">
                        <label
                          class="checkbox"
                          onClick={() =>
                            this.setCurrentTaille(declinaison_taille?.taille)
                          }
                        >
                          <input
                            class="checkbox__trigger visuallyhidden"
                            type="checkbox"
                            name={`taile_${declinaison_taille?.produit.designation
                              .split(" ")
                              .join("")}`}
                          />
                          <span class="checkbox__symbol">
                            <svg
                              aria-hidden="true"
                              class="icon-checkbox"
                              width="28px"
                              height="28px"
                              viewBox="0 0 28 28"
                              version="1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M4 14l8 7L24 7"></path>
                            </svg>
                          </span>
                        </label>
                      </div> */}
                    </div>
                    <div className="prix" style={{display:"none"}}>
                      <span
                        style={
                          produit.is_promo
                            ? { textDecoration: "line-through", color: "red" }
                            : {}
                        }
                      >
                        {this.formatNumber(this.app.getCurrentPrice(produit))}
                        {produit.is_promo && this.app.getCurrentDevise()
                          ? this.app.getCurrentDevise().signe
                          : ""}
                      </span>
                      {produit.is_promo ? (
                        <span style={{ marginInline: "var(--base-spacing)" }}>
                          {this.formatNumber(
                            this.app.getCurrentPrice(produit, true)
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.app.getCurrentDevise()
                        ? " " + this.app.getCurrentDevise().signe
                        : ""}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="sepa"></div>
        {this.state.messages
          ? this.state.messages.map((message, key) => {
              return (
                <div key={key}>
                  {this.toaster.create(message, null, null, null, () => {})}
                </div>
              );
            })
          : "" && this.state.errors
          ? this.state.errors.map((error, key) => {
              return (
                <div key={key}>
                  {this.toaster.create(error, null, null, null, () => {})}
                </div>
              );
            })
          : ""}
      </div>
    );
  }
}

export default DonneesPanierDetailsProduit;
