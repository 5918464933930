
const accroche_produits = []
let p = 0
let ppics = 1
let pc = 1
let pcc = 1
let prix = [13000,230000,32000,23000]
while(p < 12){
  accroche_produits.push(
    {
      id:p,
      designation:'produit1',
      image:'/assets/pictures/accrocheproduit'+ppics+'.png',
      prix:prix[ppics],
      createur:{designation:'Createur '+pc}
    }
  )
  pcc++
  ppics++
  if(ppics>4){
    ppics = 1
  }
  if(pcc>3){
    pcc=1
    pc++
  }
  p++
}
let pictures = [
  
  {
    source:"/assets/pictures/accrocheproduit1.png"
  },{
    source:"/assets/pictures/robe1.png"
  },
  {
    source:"/assets/pictures/robe2.png"
  },
  {
    source:"/assets/pictures/robe3.png"
  }

]

const produit = {
  titre:'Robe 1',
  description:'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. \nSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. '
}
const produits_categorie = [
  {
    image:'/assets/pictures/robe1.png',
    designation:'Titre',
    prix:'25 000',
    id:1
  },{
    image:'/assets/pictures/robe2.png',
    designation:'Titre',
    prix:'25 000',
    id:1
  },{
    image:'/assets/pictures/robe3.png',
    designation:'Titre',
    prix:'25 000',
    id:1
  }
]

const decouvertes = [
  {
    image:'/assets/pictures/decouverte1.png',
    titre:'MEILLEURES VENTES'
  },{
    image:'/assets/pictures/decouverte2.png',
    titre:'MEILLEURES VENTES'
  },{
    image:'/assets/pictures/decouverte3.png',
    titre:'MEILLEURES VENTES'
  },{
    image:'/assets/pictures/decouverte4.png',
    titre:'MEILLEURES VENTES'
  }
]
const collections = [
  {
    image:'/assets/pictures/collection1.png',
    titre:'Africa Design',
    description:'dolor, sit amet consecte '
  },{
    image:'/assets/pictures/collection2.png',
    titre:'Africa Design',
    description:'dolor, sit amet consecte '
  },{
    image:'/assets/pictures/collection3.png',
    titre:'Africa Design',
    description:'dolor, sit amet consecte '
  },{
    image:'/assets/pictures/collection4.png',
    titre:'Africa Design',
    description:'dolor, sit amet consecte '
  }
]
const categories = [
  {
    image:'/assets/pictures/collection1.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },{
    image:'/assets/pictures/collection2.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },{
    image:'/assets/pictures/collection3.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },{
    image:'/assets/pictures/collection4.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },
  {
    image:'/assets/pictures/collection1.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },{
    image:'/assets/pictures/collection2.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },{
    image:'/assets/pictures/collection3.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },{
    image:'/assets/pictures/collection4.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },
  {
    image:'/assets/pictures/collection1.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },
  {
    image:'/assets/pictures/collection2.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },{
    image:'/assets/pictures/collection3.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  },{
    image:'/assets/pictures/collection4.png',
    designation:'Africa Design',
    description:'dolor, sit amet consecte ',
    id:1
  }
]

const categories_createur = [
  {
    designation:'categorie une',
    produits:[
      {
        image:'/assets/pictures/accrocheproduit1.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit2.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit3.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit4.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      }
    ]
  },
  {
    designation:'categorie deux',
    produits:[
      {
        image:'/assets/pictures/accrocheproduit1.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit2.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit3.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit4.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      }
    ]
  },
  {
    designation:'categorie trois',
    produits:[
      {
        image:'/assets/pictures/accrocheproduit1.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit2.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit3.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit4.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      }
    ]
  },
  {
    designation:'categorie quatre',
    produits:[
      {
        image:'/assets/pictures/accrocheproduit1.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit2.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit3.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      },{
        image:'/assets/pictures/accrocheproduit4.png',
        designation:'Titre',
        prix:'25 000',
        id:1
      }
    ]
  }
]

let home_createur = {
  nom:"ABSA COLLECTION",
  nbr_produits:16,
  nbr_stat:88,
  texte:'Bienvenue dans l’univers Absa Collection ! des coutures inspiréss de partout en Afrique...'
}
let createur = {
  nom:"ABSA COLLECTION",
  nbr_produits:16,
  nbr_stat:88
}
const images_promo = [
  {image:"/assets/pictures/Promotion 1.png"},
  {image:"/assets/pictures/Promotion 2.png"},
  {image:"/assets/pictures/Promotion 3.png"}
]

module.exports = {
    accroche_produits,pictures,categories,produit,produits_categorie,categories_createur,home_createur,createur,collections,decouvertes,images_promo
}