import AjaxComp from "../data/AjaxComp";
import ZoneLostPassword from "../layout/zones/lostpassword";
import BaseMinimale from "./base_minimale";

class LostPassword extends AjaxComp {
    
    
    render(){
        return (

            <BaseMinimale>
                <ZoneLostPassword/>
            </BaseMinimale>

        )
    }


}

export default LostPassword