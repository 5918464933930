import AjaxComp from "../../../data/AjaxComp"
import './ListSlider.css'
class ListSlider extends AjaxComp {

    constructor(props){
        super(props)
        this.state = {...props}
        this.state.currentPicture = props.pictures && props.pictures.length ? (this.apiUri+"/"+props.pictures[0].src) : '';
        this.state.pictureCount = props.pictures && props.pictures.length ? props.pictures.length : 0
        this.setPicture(this.state.currentPicture)
    }

    currentCount(){
        let pictureCount = this.props.pictures.length
        this.setState({pictureCount})
    }

    setPicture(idx){
        let currentPicture = idx
        this.updateSafelyState({currentPicture : currentPicture})
    }

    nextPicture(){
        let currentPicture = (this.state.currentPicture + 1) < this.currentCount() ? this.currentCount()+1 : 0
        this.setState({currentPicture})
    }
    
    previousPicture(){
        let currentPicture = (this.state.currentPicture - 1) >= 0 ? this.currentCount()-1 : this.state.currentPicture-1 
        this.setState({currentPicture})
    }

    listSliderPictures(className="list-slider-legende"){
        return this.props.pictures?.map(
            (picture,key)=>{
                const isSelected = this.apiUri+"/"+picture.src == this.state.currentPicture
                return key<4 ? (
                        <img loading="lazy" key={key} src={this.apiUri+"/"+picture.src} className={"img_showed_suggested "+(isSelected?'selected':'')} onClick={()=> this.setPicture(this.apiUri+"/"+picture.src)}></img>
                ) :''
            }
        )
    }
    render(){
        return (
            <div className="list-slider">
                <div className="list-slider-legendes">
                    {
                        this.listSliderPictures()
                    }
                </div>
                <div className="list-slider-apercu">
                    <img loading="lazy" src={this.state.currentPicture?this.state.currentPicture:((this.props.pictures && this.props.pictures .length)?(this.apiUri+"/"+this.props.pictures[0].src):"")} className="img_showed"></img>
                </div>
            </div>
        )
    }
}

export default ListSlider