import { Save } from "react-feather";
import AjaxComp from "./AjaxComp";

class SetAdresseDefaut extends AjaxComp{
    hasChanged = false
    constructor(props){
        super(props)
        this.state = {...props}
        if(this.state.adresses)
        {
            let defaut = this.state.adresses.filter(e=>e.par_defaut===true)
            if(defaut.length == 0)
            {
                this.updateSafelyState({default_adresse:this.state.adresses[0].id})
                this.saveDefaut()
            }
        }
    }
    setDefaut(id){
        this.updateSafelyState({default_adresse:id})
        this.saveDefaut()
    }
    saveDefaut(){
        const id = this.state.default_adresse
        const {adresseChanged} = this.state
        if(id){
            this.ajaxPost(
                "adresse_client/par_defaut/"+id,{langue_id: this.app.getCurrentLang().id},response=>{
                    if(!response.errors){
                        if(adresseChanged && ((typeof adresseChanged) == 'function')){
                            adresseChanged()
                        }
                    }
                }
            )
        }
    }
    render(){
        return <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                                        
                <select style={{background:'unset',border:'unset',borderBottom:'2px solid #3331',width:'90%',padding:12}} onChange={e=>this.setDefaut(e.target.value)}>
                    {
                        this.state.adresses?.map(
                            (adresse,key)=>{
                                return <option selected={adresse.par_defaut} value={adresse.id} key={key}>
                                    {adresse.designation}
                                </option>                                
                            }
                        )
                    }
                </select>
                <span className="icon" onClick={e=>{this.saveDefaut()}}>
                    <Save size={30}/>
                </span>
        </div>
    }
}

export default SetAdresseDefaut