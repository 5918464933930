import AjaxComp from "../data/AjaxComp";
import LabelZone from "../layout/extra/labelZone";
import Star from "../layout/extra/star";

class NoteForm extends AjaxComp {
  note = {
    avis: null,
    note: 0,
  };
  queryname   = 'produits'
    attrs_query = ['id','image','sur_commande','description','description_langue','nom_langue','is_nouveaute','prix','prix_promo','is_promo','pourcentage_promo','stock_global','prix_devises{prix}','designation','details{designation,nom_langue,valeur}','notes_avis{note,avis,created_at,client{id,nom_complet,email}}','createur{id,designation,photo,couverture,nbr_produits},images{id,src},declinaisons{id,produit_id,nom_declinaison,display_text,quantity,couleur_id,couleur{id,nom,description,code_couleur},declinaison_id,declinaisons{id,produit_id,nom_declinaison,display_text,quantity,declinaison_id,taille_id,taille{id,nom,description},produit{id,designation,prix}}}']
    default_conditions = {
        website:true,for_website:true
    }
  constructor(props) {
    super(props);
    console.log(props,' my props')
    this.state = { ...props ,note:this.note};
  }

  componentDidMount() {
    this.getProduits();
  }

  getProduits = async () =>
  {
    console.log('this.state.produit_id papa',this.state);
    if(this.state && this.state.produit_id)
    {
      this.customGraphql
      (
          'produits',
          {
            website:true,for_website:true,id:this.state.produit_id,langue_id:this.app.getCurrentLang().id
          },
          this.attrs_query,
          (produits)=>{
              if(produits.produits && produits.produits.length>0){
                  console.info(produits,'as produits note')
                  this.setState({produit:produits.produits[0]})
                  this.forceUpdate()
              }
              else
              {
                  console.info('probleme detail produit')
              }
          }
      )
    }
  }

  saveNote(e) {
    e.preventDefault();
    this.setState({ display_error: null });
    console.log("this.state.note ici", this.note);
    this.note = this.state.note
    this.note.client_id = this.app?.state?.client_id ;
    this.note.produit_id = this.state.produit_id ? this.state.produit_id : "" ;
    this.note.createur_id = this.state.createur_id ? this.state.createur_id : "" ;
    this.note.langue_id = this.app.getCurrentLang().id ;
    this.ajaxPost("notesavis/save", this.note, (response) => {
      try {
        console.log("response note", response);
        if (response.errors) {
          this.app.showDisplayError(response.errors.toString());
          //this.setState({ display_error: response.errors.toString() });
        }
        else
        {
          this.app.setDisplayMessage(response.message);
          this.getProduits();
          document.querySelector('textarea.formfield').value = "";
          this.state.note = {...this.state.note,note:0};
          this.updateSafelyState({note:this.state.note});
          console.log('this.state.note papa produit',this.state);
        }
      } catch (e) {}
    });
  }
  update(tag,value)
  {
    this.note[tag] = value;
    this.updateSafelyState({note:this.note})
  }
  render() {
    return !this.isLogged() ? (
      ""
    ) : (
      <div id="noteform">
        {this.state.display_error ? this.showDisplayError(this.app.getCurrentLangText("site_text_note")) : ""}
        <form action="" onSubmit={e=>this.saveNote(e)}>
          <div className="bloc-zone">
              <div className="note ">
                <textarea name='avis' className="formfield input" onChange={e=>{this.update('avis',e.target.value)}} placeholder={this.app.getCurrentLangText("site_text_donnez_votre_avis") ? this.app.getCurrentLangText("site_text_donnez_votre_avis")+" (*)" : "Donnez votre avis (*)"}></textarea>
              </div>
              <div className="boutons">
                <span className="stars">
                  {
                    [1,2,3,4,5].map(
                      (i)=>{
                        return <span onClick={e=>{this.update('note',i+1)}}>
                          <Star starClass={(i < this.state.note.note) ?'active':''}  />
                        </span>
                      }
                    )              
                  }
                </span>
                <button className="button" onClick={(e) => this.saveNote(e)}>{this.app.getCurrentLangText("site_text_ajouter_une_note") ? this.app.getCurrentLangText("site_text_ajouter_une_note") : "Ajouter une note"}</button>
              </div>
            </div>
        </form>
      </div>
    );
  }
}

export default NoteForm;
