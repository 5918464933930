import AjaxComp from "../../data/AjaxComp";
import LabelZone from "../extra/labelZone";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "./article.css";
import CommentArticle from "../../forms/commenter_article";
import Loader from "../../Loader";

class Blog extends AjaxComp {
  path_params = this.suffixe+'/article/:id'
  queryname   = 'articles'
  attrs_query = ["id,image,date_publication,titre,contenu,tags{id},commentaires{id,created_at_fr,commentaire,client{nom_complet,email}},statusarticle{id},author{id},created_at_fr,updated_at_fr,created_at_fr"]
  default_conditions = {}
  constructor(props) {
    super(props);
    this.state = { ...props };
    this.setRouteParams()
    if(this.getRouteParams()){
        this.produit_id = this.getRouteParams().params.id
        this.default_conditions.id = this.produit_id
        this._update(
          data=>{
            const articles  = data['articles']
            this.updateSafelyState({rawdata:data,data:{articles}})
          }
        )
    }
  }

  render() {
    let articles = this.state.data?.articles
    let article     = (articles && articles.length) ? articles[0] : null
    
    return <>
          {
            (articles?.length ) ? 
              <div className="zone">
                <article className="article-blog" style={{  }}>
                  <div className="contenu-article-blog">
                    <h1 className="titre-article-blog"  style={{}}>
                      {article.titre}
                    </h1>
                    <div className="paragraphes">
                      {article.contenu.split('\n').map((paragraphe, index) => (
                        <p key={index} style={{ marginBottom: '15px', lineHeight: '1.5' }}>
                          {paragraphe}
                        </p>
                      ))}
                    </div>
                  </div>
                  <img loading="lazy" className="image-article-blog"
                    src={this.apiUri + "/" + article.image}
                    style={{  }}
                    alt={article.title}
                  />
                </article>
                <CommentArticle  article_id={article.id}>
                </CommentArticle>
                <div>
                    {
                      article.commentaires?.map(
                        commentaire=>{
                          return <div style={{background:'#eee5',padding:'12px 24px',borderRadius:"5%"}}>
                            <h3>
                              {commentaire.client.nom_complet}
                            </h3>
                            <div  style={{background:'#fff',padding:"12px",paddingBlock:'24px',marginBlock:'12px',borderRadius:"5%"}}>
                              <p>
                                {commentaire.commentaire}
                              </p>
                              <b>
                                {commentaire.created_at_fr}
                              </b>
                            </div>
                          </div>
                        }
                      )
                    }
                </div>
              </div>
              : <div  style={{paddingTop:'calc(var(--block-spacing) * 2)'}}>
                <Loader loading={true}/>
              </div>
            
          }
    </>
  }
}

export default Blog;
