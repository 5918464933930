import AjaxComp from "../../data/AjaxComp";
class Footer extends AjaxComp {
 
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
  
    return (
      <footer className="zone" style={{margin:"0 auto"}}>
        <div className="footer-accroche-wrapper">
          <a href={this.suffixe+"/"} className="accroche logobox">
            <img loading="lazy" src="/assets/pictures/nahyel-blanc.svg" alt="" />
            {/* <img loading="lazy" src="/assets/pictures/nahyel_blanc.svg" alt="" /> */}
          </a>
        </div>
        {/* <div className="footer-accroche-wrapper"> */}
          {/* <div className="accroche"> */}
            {/* <img loading="lazy" src="/assets/pictures/logo.svg" alt="" /> */}
          {/* </div> */}
        {/* </div> */}
        <div className="footer-zone-wrapper">
          <div className="footer-zone">
            <label className="footer-zone-top" htmlFor="1_footer">
              <h3>{this.app.getCurrentLangText("site_text_aide_contact")}</h3>
            </label>
            <input
              className="collspane_footer"
              type="radio"
              name="collspane_footer"
              id="1_footer"
            />
            <ul className="liste_liens">
              {
                this.app.getPreferencesData("contactphone") ?
                  <li>
                    <span>{this.app.getPreferencesData("contactphone")}</span>
                  </li> 
                : ''
              }
              {
                this.app.getPreferencesData("contactemail") ?
                  <li>
                    <span>{this.app.getPreferencesData("contactemail") }</span>
                  </li> 
                : ''
              }
              {/* <li>
                <a href={this.suffixe+"/"}>Livraison 24h en point retrait</a>
              </li>
              <li>
                <a href={this.suffixe+"/"}>Guides des tailles</a>
              </li> */}
              <li>
                <a href="#section-apropos" onClick={e=>this.switchPopupElement(e,'#section-apropos')} id="toggle-section-apropos" >{this.app.getCurrentLangText("site_text_a_propos_de_nous")}</a>
              </li>
              <li>
                <a href="#section-cpl" onClick={e=>this.switchPopupElement(e,'#section-cpl')} id="toggle-section-cpl" >{this.app.getCurrentLangText("site_text_commande")} - {this.app.getCurrentLangText("site_text_paiement")} - {this.app.getCurrentLangText("site_text_livraison")}</a>
              </li>
              <li>
                <a href="#section-remboursement" onClick={e=>this.switchPopupElement(e,'#section-remboursement')} id="toggle-section-remboursement" >{this.app.getCurrentLangText("site_text_retour_et_remboursement")}</a>
              </li>
            </ul>
          </div>

          <div className="footer-zone">
            <label className="footer-zone-top" htmlFor="2_footer">
              <h3>{this.app.getCurrentLangText("site_text_adresse")}</h3>
            </label>
            <input
              className="collspane_footer"
              type="radio"
              name="collspane_footer"
              id="2_footer"
            />
            <ul className="liste_liens">
              {
                this.app.getPreferencesData("adressecontact")  ?
                  <li>
                    <span>{this.app.getPreferencesData("adressecontact") }</span>
                  </li> 
                : ''
              }
              <li>
                <a className="footer-zone-bottom cursor-pointer"  id='toggle-section-contact' onClick={e=>this.switchPopupElement(e,'#section-contact')} >
                  {this.app.getCurrentLangText("site_text_nous_contacter")}
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-zone liens_reseau">
            <div className="footer-zone-top">
              <h3>{this.app.getCurrentLangText("site_text_suivez_nous")}</h3>
            </div>
            <ul className="liste_icones mt-2">
              <li>
                <a target='_blank' href={this.app.getPreferencesData("instagramlink") }>
                  <img loading="lazy" src="/assets/pictures/instagramblanc.svg" alt="" />
                </a>
              </li>
              <li>
                <a target='_blank' href={this.app.getPreferencesData("facebooklink") }>
                  <img loading="lazy" src="/assets/pictures/facebookblanc.svg" alt="" />
                </a>
              </li>
              <li>
                <a target='_blank' href={this.app.getPreferencesData("tiktoklink") } style={{width:'30px'}}>
                  <img loading="lazy" src="/assets/pictures/tiktokblanc.svg" alt="" />
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-zone">
            <label className="footer-zone-top" htmlFor="3_footer">
              <h3>{this.app.getCurrentLangText("site_text_modes_de_livraison")}</h3>
            </label>
            <input
              className="collspane_footer"
              type="radio"
              name="collspane_footer"
              id="3_footer"
            />
            <ul className="liste_marques">
              <li>
                <a href={this.suffixe+"/"}>
                  <img loading="lazy" src="/assets/pictures/dpd.png" alt="" />
                </a>
              </li>
              <li>
                <a href={this.suffixe+"/"}>
                  <img loading="lazy" src="/assets/pictures/colissimo.png" alt="" />
                </a>
              </li>
            </ul>
          </div>

          {/* <div className="footer-zone">
            <label className="footer-zone-top" htmlFor="4_footer">
              <h3>{this.app.getCurrentLangText("CHÈQUES CADEAUX")}</h3>
            </label>
            <input
              className="collspane_footer"
              type="radio"
              name="collspane_footer"
              id="4_footer"
            />
            <ul className="liste_liens">
              <li>
                <a href={this.suffixe+"/"}>Offrir une carte cadeau</a>
              </li>
              <li>
                <a href={this.suffixe+"/"}>Utiliser une carte cadeau</a>
              </li>
              <li>
                <a href={this.suffixe+"/"}>Chèques cadeaux d'entreprise</a>
              </li>
            </ul>
          </div> */}
          <div className="footer-zone">
            <label className="footer-zone-top" htmlFor="5_footer">
              <h3>{this.app.getCurrentLangText("site_text_shop_en_ligne_facile")}</h3>
            </label>
            <input
              className="collspane_footer"
              type="radio"
              name="collspane_footer"
              id="5_footer"
            />
            <ul className="liste_liens">
              <li>
                <span>{this.app.getCurrentLangText("site_text_ajouter_au_panier_payez_apres")}</span>
              </li>
              {/* <li>
                <span>Livraison gratuite*</span>
              </li>
              <li>
                <span>Délais de retour 30 jours</span>
              </li> */}
            </ul>
          </div>
          {
            this.app.state.data && this.app.state.data.modepaiements ?   
              <div className="footer-zone">
                <label className="footer-zone-top" htmlFor="6_footer">
                  <h3>{this.app.getCurrentLangText("site_text_mode_paiement")}</h3>
                </label>
                <input
                  className="collspane_footer"
                  type="radio"
                  name="collspane_footer"
                  id="6_footer"
                />
                <div className="liste_marques">
                  {
                    this.app.state.data.modepaiements.map(
                      mode=>{
                        
                        return <img loading="lazy" src={this.apiUri+"/"+mode.image} alt="" />
                        
                      }
                    )
                  }
                </div>
              </div>
            : ""
          }
          <ul className="onmobile liste_icones" >
              <li>
                <a target='_blank' href={this.app.getPreferencesData("instagramlink") } style={{width:'30px'}}>
                  <img loading="lazy" src="/assets/pictures/instagramblanc.svg" alt="" />
                </a>
              </li>
              <li>
                <a target='_blank' href={this.app.getPreferencesData("facebooklink") }  style={{width:'30px'}}>
                  <img loading="lazy" src="/assets/pictures/facebookblanc.svg" alt="" />
                </a>
              </li>
              <li>
                <a target='_blank' href={this.app.getPreferencesData("tiktoklink") } style={{width:'30px'}}>
                    <img loading="lazy" src="/assets/pictures/tiktokblanc.svg" alt="" />
                </a>  
              </li>
            </ul>
        </div>
      </footer>
    );
  }
}
export default Footer;
