import Loader from "../../Loader";
import AjaxComp from "../../data/AjaxComp";

class RaccourcisPanier extends AjaxComp {
  constructor(props) {
    super(props);
    this.app.raccourcis_panier = this;
  }

  render() {
    const { panier } = this.app.gestion_panier.state;
    const articles = panier ? panier.articles : [];
    return (
      <>
        <h3 className="useractionview-label">
          <div>
            {this.app.getCurrentLangText("site_text_panier_mon_panier")} ({" "}
            <span className="count-panier"> {articles?.length ?? 0}</span> ){" "}
          </div>
          <div className="" onClick={e=>this.switchPopupElement(e)}>
            <svg
              class="svg-inline--fa fa-xmark text-danger"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="xmark"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              ></path>
            </svg>
          </div>
        </h3>
        <div className="sepa"></div>
        <div className="articles-panier">
          {articles?.length > 0 ? articles?.map((article, key) => {
                return (
                  <div className="article-panier" key={key}>
                    <div className="illustration">
                      <img
                        loading="lazy"
                        src={this.app.gestion_panier.article_image(article)}
                        alt=""
                      />
                    </div>
                    <div className="details">
                      <div className="infos">
                        <div className="info-panier info-createur">
                          <span className="icon">
                            <img
                              loading="lazy"
                              src={
                                this.apiUri+"/"+article.produit.createur.photo
                              }
                              alt=""
                            />
                          </span>
                          <span>{article.produit.createur.designation}</span>
                        </div>
                        <div className="info-panier info-designation">
                          {this.app.gestion_panier.article_name(article)}
                        </div>
                        <div className="info-panier info-prix">
                          {this.formatNumber(
                            this.app.gestion_panier.article_price(article)
                          )}{" "}
                          {this.app.getCurrentDevise()
                            ? this.app.getCurrentDevise().signe
                            : ""}
                        </div>
                        <div className="info-panier info-quantite">
                          Qte :{" "}
                          {this.app.gestion_panier.article_quantity(article)}
                        </div>
                      </div>
                      <div
                        className="remove"
                        onClick={(e) =>
                          this.app.gestion_panier.delFromCart(
                            article.declinaison_id
                          )
                        }
                      >
                        <span className="fa fa-close text-danger"></span>
                        {/* <img loading="lazy" onClick={e=>this.app.gestion_panier.delFromCart(article.declinaison_id)} src="/assets/pictures/croix.png" alt=""/> */}
                      </div>
                    </div>
                  </div>
                );
              })
            : this.app.getCurrentLangText("site_text_panier_panier_vide")}
        </div>
        {articles?.length ? (
          <div className="d-grid bouton-raccourci-panier">
            <button
              style={{ padding: "var(--base-spacing)" }}
              onClick={(e) => this.goTo(this.suffixe + "/commande")}
            >
              {this.app.getCurrentLangText("site_text_proceder")}
            </button>
          </div>
        ) : (
          ""
        )}
      </>
    )
      
  }
}
export default RaccourcisPanier;
