import AjaxComp from "../data/AjaxComp";
import BaseMinimale from "./base_minimale";
import Livraison from "../layout/sections/livraison";

class PageLivraison extends AjaxComp {
  render() {
    return <>
      <BaseMinimale
            title={this.app.getCurrentLangText("site_text_le_blog_nahyel")}
            subtitle= {this.app.getCurrentLangText("site_text_opinions_analyses_et_conseils")}
            customHeader="/assets/pictures/bg-blog.jpg"
            noHeader={true}
          >
          <Livraison/> 
      </BaseMinimale>
    </>
  }
}

export default PageLivraison;
