import AjaxComp from "../../data/AjaxComp";
import ListeAccroches from "../containers/listes/liste-accroches";
import ListeDecouvertes from "../containers/listes/liste-decouvertes";

class Decouvertes extends AjaxComp {

    constructor(props){
        super(props)
        this.state = {...props}
    }
    render(){
        return (
            // <ListeDecouvertes></ListeDecouvertes>
            <ListeAccroches/>
            
        )
    }


}

export default Decouvertes