import axios from 'axios';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const {REACT_APP_apiUri} = process.env



function LangueChange() {

  const [langues, setLangues] = useState([]);
  const [loading, setLoading] = useState(true);


  const { i18n } = useTranslation();


  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

const apiToken = localStorage.getItem('apiToken')
  
  const apiUri = REACT_APP_apiUri;
  useEffect(() => {
    console.log("My URI REK",apiUri)
    fetchLanguages();
  }, []);



  const fetchLanguages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(apiUri+"/api/langue/all");
      console.log(response);
      setLangues(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching currencies:', error);
    }
    console.log("MES LANGUES PREFERER",langues);
  };


  

  return (
    <>
     <div>
      <button onClick={() => i18next.changeLanguage('en')}>Anglais</button>
      <button onClick={() => i18next.changeLanguage('fr')}>Français</button>
    </div>
    </>
  );
}

export default LangueChange;