import { Loader } from "react-feather";
import AjaxComp from "../../../data/AjaxComp";
import { Link } from "react-router-dom";

class ProduitAccroche extends AjaxComp {
  data = {
    couleur:null,
    taille:null,
    quantite:1
  } 
  handleChange = (prop, value) => {
    this.state.data[prop] = value;
    this.setState({ ...this.state });
  };
  constructor(props) {
    super(props);
    const {produit} = props
    this.data.produit = produit
    this.data.couleur = produit.declinaisons?produit.declinaisons[0]:{}
    this.data.taille  = this.data.couleur?.declinaisons?this.data.couleur.declinaisons[0]:{}
    this.state = { ...props, data: this.data, errors: [], messages: [] };
  }
  updateSelf(){
    if(this.state.data.produit && this.state.data.produit.id)
    {
      this.customGraphql(
        'produits',{id:this.state.data.produit.id,langue_id:this.app.getCurrentLang().id},
        [
          "id,image,designation,nom_langue,images{id,src},is_promo,prix_promo,pourcentage_promo,stock_global,is_nouveaute"
          ,"createur{id,designation,photo}"
          ,"declinaisons{id,produit_id,nom_declinaison,display_text,quantity,couleur_id,couleur{id,nom,description,code_couleur},declinaison_id,declinaisons{id,produit_id,nom_declinaison,display_text,quantity,declinaison_id,taille_id,taille{id,nom,description},produit{id,designation}}}"
        ],results=>{
          
          if(results && results.produits && results.produits.length){
            const produit     = results.produits[0]
            this.data.produit = produit
            this.data.couleur = produit.declinaisons?produit.declinaisons[0]:{}
            this.data.taille  = this.data.couleur?.declinaisons?this.data.couleur.declinaisons[0]:{}
            this.app.gestion_panier.getIt(()=>{
              this.updateSafelyState(this.data)
              this.forceUpdate()
            })
          }
          this.forceUpdate()
        }
      )
    }
  }
  setCurrentTaille(taille){
    this.data.taille = taille
    this.updateSafelyState({data:this.data})
  }
  setCurrentCouleur(couleur){
    this.data.couleur = couleur
    console.warn(couleur)
    this.updateSafelyState({data:this.data})
  }

  doAddToCart(e) {
    if(this.state.data.taille?.id == null)
    {
      console.info('no declinaison')
    }
    this.app.gestion_panier.addToCart(
      this.state.data.taille.id,
      this.state.data.quantite,
      (response) => {
        this.app.gestion_panier.getIt()
        if (response.errors) {
          this.setState({ errors: [...this.state.errors, response.errors] });
        }
        if (response.message) {
          this.setState({
            messages: [...this.state.messages, response.messages],
          });
        }
        this.updateSelf()
        this.forceUpdate()
      }
    );
  }

  doDelFromCart(e) {
    this.app.gestion_panier.delFromCart(
      this.state.data.taille.id,
      (response) => {
        this.app.gestion_panier.getIt()
        if (response.errors) {
          this.setState({ errors: [...this.state.errors, response.errors] });
        }
        if (response.message) {
          this.setState({
            messages: [...this.state.messages, response.messages],
          });
        }
        this.updateSelf()
        this.forceUpdate()
      }
    );
  }
  render() {
    const {produit,couleur,taille} = this.state.data
    console.info('is produit ',produit)
    let is_in_cart = false
    if(taille){
      is_in_cart = this.app.gestion_panier.isInCart(taille.id)
    }
    if(this.props.produit.image){
      this.props.produit.image = !this.props.produit.image.match(this.apiUri) ? this.apiUri+"/"+this.props.produit.image : this.props.produit.image
    }
    return (
      <div className="produit-accroche" key={produit.id}>
        <div>
          <div className="img_produit_container">
            <a href={this.suffixe+"/details-produit/" + (produit.id)}>
              <img
                // src="/assets/pictures/test_prduit.jpg"
                src={(produit.image && (!produit.image.match('upload.jpg'))) ? produit.image : "/assets/pictures/logo.svg"}
                alt=""
                className="img_produit"
              />
            </a>
            <div className="stickers_container">
              {produit.is_nouveaute ? <div className="new-wrapper">New</div> : ''} 
              {/* {produit.is_promo ? <img style={{width:'32px',height:'32px'}} src='/assets/pictures/promo.png' className="promo-wrapper"/> : ''}  */}
              {produit.is_promo ? <div className="promo-wrapper badge-promo">{produit.pourcentage_promo}%</div> : ''} 
              <label
                className="like-wrapper badge-favoris"
                htmlFor={`favori_product+${produit.id}`}
              >
                <input
                  type="checkbox"
                  name=""
                  id={`favori_product+${produit.id}`}
                />
                <svg
                  className="beatonhover"
                  onClick={e=>this.app.isFavoriClient(produit.id)?this.app.removeFavoriClient(produit.id):this.app.addFavoriClient(produit.id)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="27"
                  viewBox="0 0 30 27"
                  // fill={this.app.isFavoriClient(produit.id)?'#E1C564':"#1113"}
                  fill={"#1113"}
                >
                  <path
                    d="M15 4.90001C15.6 3.90001 16.3 3.10001 17.2 2.40001C18.8 1.30001 20.5 0.900005 22.4 1.20001C25.3 1.70001 27.6 3.90001 28.1 6.70001C28.5 8.50001 28.2 10.3 27.4 12C26.9 13.2 26.1 14.2 25.2 15.2C23.8 16.8 22.1 18.1 20.6 19.5C18.8 21.1 17.1 22.8 15.7 24.7C15.5 25 15.3 25.2 15.1 25.5C15 25.7 14.9 25.7 14.8 25.5C13.1 23 10.9 21 8.7 19C7.3 17.8 5.8 16.5 4.6 15.1C3.3 13.6 2.3 12 1.9 10.1C1.6 8.50001 1.6 7.00001 2.2 5.50001C2.8 4.10001 3.9 2.80001 5.4 2.00001C7.2 1.00001 9.2 0.900005 11.1 1.60001C12.6 2.10001 13.8 3.10001 14.6 4.50001C14.8 4.60001 14.9 4.80001 15 4.90001Z"
                    stroke={this.app.isFavoriClient(produit.id)?'silver':"white"}
                    stroke-width="1"
                    stroke-miterlimit="10"
                  />
                </svg>
              </label>
            </div>
            <div className="stickers_container">
              {produit.stock_global == 0 ? <div className="badge-stock">{this.app.getCurrentLangText("site_text_en_stock") ? this.app.getCurrentLangText("site_text_en_stock") : "En rupture" }</div> : ''}
            </div>
          </div>
        </div>
        <div className="infos">
          <div className="user">
            <span className="icon">
              <img src="/assets/pictures/user-client-color.svg" alt="" />
            </span>
            <h3 className="texte">
              <a href={this.suffixe+"/home-createur/"+produit.createur.id}>
                {produit.createur.designation}
              </a>
            </h3>
          </div>
          <div className="designation d-flex justify-content-between">
            <a href={(this.suffixe+"/details-produit/"+(produit.id))}>
              {produit.nom_langue}
            </a>
          </div>
          {/* <div className="sepa"></div>
          <div className="choix-declinaison" style={{display:"flex",alignItems:"center"}}>
            <div className="">
              {
                produit.declinaisons.map(
                  declinaison_couleur=>{
                    const {couleur,nom_declinaison} = declinaison_couleur 
                    const {code_couleur,nom} = couleur 
                    const border = code_couleur ? "none" : '2px solid black'
                    return <div
                      className="couleur"
                      style={{ background: code_couleur,border:border }}
                      onClick={(e) => this.setCurrentCouleur(declinaison_couleur)}
                      title={nom}
                    ></div>
                  }
                )
              }
            </div>
            <div className="choicelist" style={{display:"flex",alignItems:"center"}}>
              {
                couleur?.declinaisons.map(
                  declinaison_taille=>{
                    return <div
                      className="donnees choice"
                      style={{border:`${this.state.data.taille.id == declinaison_taille.id?'2':'0'}px solid black`,cursor:'pointer',display:"flex",alignItems:"center"}}
                      onClick={(e) => this.handleChange("taille", declinaison_taille)}
                    >
                      <div className="donnees-label" style={{padding:"4px"}}>
                        {declinaison_taille.taille.nom}
                      </div>
                    </div>
                  }
                )
              }
            </div>
          </div>
          <div className="sepa"></div> */}
          {/* <div className="prix">{this.props.produit.prix_vente_final_devise} FCFA</div> */}
          <div className="prix w-100 d-flex justify-content-between">
            <span className="data">
              <span style={this.props.produit.is_promo ? {textDecoration:'line-through',color:'red'}:{}}>
                {this.formatNumber(this.app.getCurrentPrice(this.props.produit))+" "}
                {this.props.produit.is_promo && this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''}
              </span>
              {
                this.props.produit.is_promo ? 
                  <span style={{marginInline:'var(--base-spacing)'}}> 
                    {this.formatNumber(this.app.getCurrentPrice(this.props.produit,true))}
                  </span> 
                :
                  ''
              }
              {this.app.getCurrentDevise()?this.app.getCurrentDevise().signe :''}
            </span>
              {
                this.app.gestion_panier.state.gotData
                ?
                  taille ?  
                  is_in_cart ? (
                      <span className="action red align-self-center" onClick={(e) =>
                          this.doDelFromCart(e)
                        }>
                        {(()=><i className="cursor-pointer fas fa-cart-arrow-down"></i>)()}
                        {/* RETIRER DU PANIER */}
                      </span>
                    ) : (
                      <span className="action align-self-center" onClick={(e) =>
                          this.doAddToCart(e)
                        }>
                        {(()=><i className="cursor-pointer fas fa-cart-plus"></i>)()}
                      </span>
                    )
                  : ""
                : 
                  <Loader loading={'true'}/>
              }
            

          </div>
        </div>
        {/* <div className="action">
          {
            this.app.gestion_panier.state.gotData
              ?
                taille ?  
                  is_in_cart ? (
                    <button
                      onClick={(e) =>
                        // this.app.gestion_panier.delFromCart(taille?.id)
                        this.doDelFromCart(e)
                      }
                    >
                      RETIRER DU PANIER
                    </button>
                  ) : (
                    <button
                      onClick={(e) =>
                        this.doAddToCart(e)
                      }
                    >
                      {this.app.getCurrentLangText('AJOUTER AU PANIER')}
                    </button>
                  )
                : ""
              : 
                <Loader loading={'true'}/>
          
          }
        </div> */}
      </div>
    );
  }
}

export default ProduitAccroche;
