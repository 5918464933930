import AjaxComp from "../../data/AjaxComp";
import Panier from "../../pages/panier";
import AdressePanier from "../../pages/adresse_panier";
import Loader from "../../Loader";

class ZoneCommande extends AjaxComp {

    querynames = 'commandes'
    attrs_query = [
        'paniers{produits{id,nom_langue,designation,image,prix,prix_devises{prix},quantite,total,createurs{id,nom}},tva,total}',
        'livraison',
        'adresse_livraison',
        'mode_de_paiement{id,designation}',
        'client_id'
    ]
    constructor(props){
        super(props)
        this.state = {...props,nodata:true}
        this.app.zone_commande = this
        
    }
    gotdata(){
        this.setState({nodata:false})
    }
    componentDidUpdate(){
        if(!this.state.nodata){
            window.setupOptionsSectionCommande()
        }
    }
    render(){
        if(this.state.nodata && this.app.gestion_panier.state.panier && this.app.gestion_panier.state.client){
            this.gotdata()
        }
        return (


            <div id="commande" className="zone">
                {
                    this.state.nodata ? 
                        <div className="zone" style={{position:'relative',alignItems:'center',display:'grid',justifyItems:'center',padding:'12px',minHeight:'30vh',overflow:"hidden"}}>
                            <Loader loading={true} />
                            {/* <h1 style={{background:'#fffA',padding:'5rem'}}>
                                Veuillez patienter 
                            </h1> */}
                        </div>
                    : <>
                        <div className="select-section-commande">
                            <div className="option-section-commande current" id="option-panier">
                            {this.app.getCurrentLangText("site_text_panier_mon_panier")}
                            </div>
                            <div className="option-section-commande"  id="option-adresse-panier">
                            {this.app.getCurrentLangText("site_text_addresse_et_paiement")} 
                            </div>
                        </div>
                        <Panier panier={this.app.gestion_panier.state.panier}></Panier>
                        <AdressePanier client={this.app.gestion_panier.state.client}></AdressePanier>
                    </>
                }
            </div>

        )
    }


}

export default ZoneCommande