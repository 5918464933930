import AjaxComp from "../data/AjaxComp";
import BaseMinimale from "./base_minimale";
import ZoneDetailsCreateur from "../layout/zones/details-createur";
import HorizontalScrollCategories from "../layout/zones/scroll-categories";
import ListeCategoriesCreateur from "../layout/containers/listes/liste-categories-createur";
import Stats from "../layout/zones/stats";
import FeedBacks from "../layout/zones/feedbacks";
import { categories_createur } from "../myseed";
import SectionNote from "../layout/sections/section_note";
import StarList from "../layout/extra/starlist";
import ZonePetitPrix from "../layout/sections/petitprix";

class PetitPrix extends AjaxComp {
  queryname = "createurs";
  default_conditions = {active:false} 
  attrs_query = ["id,designation,image,prix"];
  componentDidMount() {
    document.title = "NAHYEL CONCEPTSTORE - " + this.app.getCurrentLangText("site_text_petit_prix");
    this.setState({mounted:true})
  }
  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  render() {
  
    
    return (
        <BaseMinimale>
          <ZonePetitPrix></ZonePetitPrix>
          <Stats />
        </BaseMinimale>
      
    )
  }
}

export default PetitPrix;
